@import "@speed/common/src/components/core.scss";

@font-face {
  font-family: PT Mono;
  src: url(https://speed-production.s3.amazonaws.com/fonts/pt-mono-v13-latin-regular.woff);
  font-display: swap;
}

@mixin fadeJsonDisplay {
  background: linear-gradient(#8a636300, $white);
  position: absolute;
}

@mixin webhookAttemptDisplay {
  position: absolute;
  width: 230px !important;
}

@mixin webhookAttemptEvents($height) {
  padding-top: 50px;
  height: $height;
  overflow: hidden;
}

.webhhok-attempt-container {
  & .MuiGrid-item {
    max-height: calc(100vh - 242px);
    overflow: auto;
  }

  .open-in-new-box {
    @include contentCenter();
    width: 46px;
    height: 46px;
    border-radius: 30px;
    background: $paleGray;
    cursor: pointer;
  }
}

.log-container {
  & .MuiGrid-item {
    max-height: calc(100vh - 260px);
    overflow: auto;
  }
}

.logs-filter {
  border-radius: 2px;
  background: var(--secondry-f-7-fafc, $paleGray);

  .MuiButton-endIcon {
    color: $greyText;
  }

  .logs-filter-btn {
    font-size: $font14 !important;
    box-shadow: none !important;

    &:hover {
      background: none !important;
    }
  }

  .logs-filter-selected {
    color: var(--primary-2-a-67-ff, $primaryBlue);
  }

  .MuiPopper-root {
    z-index: 99;

    .input-element {
      box-shadow: none !important;
      padding: 5px !important;
    }
  }

  .MuiListItem-root {
    padding: 0 !important;

    .MuiButtonBase-root {
      background-color: $white;
    }

    .MuiListItemText-root {
      margin: 0 !important;
    }
  }

  .popper-arrow {
    display: none;
  }
}

.error-body-log {
  pre {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.logs-container {
  background-color: $white;
}

.log-clear-all-btn {
  padding: 11px 18px 11px 16px !important;
  height: 36px;
  font-size: $font14 !important;

  &:hover {
    background: none;
  }
}

.date-logs {
  font-family: $fontRegular;
  font-size: $font14 !important;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.log-filter-btn-container {
  box-shadow: none !important;
}

.logs-filter-bar {
  background-color: $white;
  height: 60px;
  border-bottom: 1px solid $lightCyanBlue;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 12px 36px;
}

.load-more-log-btn {
  padding: 12px 24px 12px 20px !important;
  margin: 16px 0px 10px 0px;
  left: 43%;

  .MuiButton-startIcon {
    color: $greyText;
  }
}

.logs-table {
  .MuiTableRow-root {
    &:nth-of-type(even) {
      background: $white;
    }

    &:nth-of-type(even):hover {
      background-color: $aliceBlue !important;
    }
  }

  & .MuiTableCell-root {
    white-space: nowrap;
  }
}

.log-details-wrapper {
  display: table-cell;
  width: 100vw;
  padding: 20px 36px 119px 38px;

  & .input-selection-box {
    & .MuiInputBase-input {
      background-color: $paleGray !important;
      border-color: transparent !important;
      opacity: unset !important;
    }
  }
}

.label-notes {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.default-text1 {
  color: $greyText;
  font-size: $font14;
  font-weight: normal;
  font-family: $fontMedium;
}

.clipboard-api {
  padding-top: 15px;

  .MuiInputBase-root {
    textarea {
      cursor: pointer;
    }

    width: 385px;
    margin: 15px 0px 15px 0px;
    padding-bottom: 60px;
  }
}

.api-popup {
  .MuiDialogContent-root {
    padding: 0 !important;
  }

  .secret-key-popup {
    .safe-text {
      padding: 24px 30px;
      border-bottom: solid 1px $lightCyanBlue;

      .default-text {
        margin-bottom: 15px;
      }
    }

    .notes-data {
      padding: 24px 30px;

      .notes-clipboard .MuiInputBase-root {
        padding-bottom: 0px !important;
        width: 290px;
      }
    }

    textarea {
      resize: none;

      &::placeholder {
        font-size: $font14;
      }
    }
  }
}

.create-secret-key {
  textarea {
    resize: none;

    &::placeholder {
      font-size: $font14;
    }
  }
}

.language-json {
  font-family: PT Mono;
}

.code-content {
  & div {
    font-family: PT Mono;
  }
}

.truncate-api {
  max-width: 1px;
  width: 100%;

  & div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.secret-hide-btn {
  padding: 0 0 !important;
  background-color: $white;
  height: 26px;
  font-size: $font12 !important;
  border-radius: 4px;
  color: $darkIndigo;

  &:hover {
    background-color: $white;
  }
}

.restricted-key-create {
  width: 600px;
  height: 62%;
  max-height: 642px;
  border-radius: 4px;
  border: solid 1px $lightCyanBlue;
  overflow: hidden;
  margin-bottom: 15px;
  display: inline-block;

  .table-header {
    height: 70px;
    background-color: $white;

    & .MuiTableRow-head {
      .table-header-module {
        padding-left: 20px;
        width: 345px;
      }

      & th {
        background-color: inherit;
      }
    }
  }

  .table-body {
    height: calc(100% - 71px);
    position: absolute;
    overflow: auto;
    width: 100%;
    padding-bottom: 0;

    .css-j204z7-MuiFormControlLabel-root {
      margin-left: 9px !important;
      margin-right: -2px !important;
    }
  }

  .table-subheader {
    height: 71px;
    background-color: $paleGray !important;

    & h4 {
      padding-left: 10px;
    }

    .table-subheader-module {
      padding-left: 20px;
    }
  }

  .table-cell {
    height: 71px;
    width: 100%;
    background-color: $white !important;

    & h4 {
      padding-left: 10px;
    }

    .table-cell-module {
      padding-left: 30px;
    }
  }

  @include addCustomScrollBar();
}

.webhook-select {
  @include webhookAttemptDisplay();
  height: 30px;
  left: 780px;
  z-index: 1;

  .MuiInputBase-root {
    cursor: pointer;
    top: -15px;
    box-shadow: none !important;
    outline: 0px !important;
    border-radius: 0px !important;
  }
}

.webhook-version {
  @include webhookAttemptDisplay();
  height: 20px;
  left: 810px;
}

.details-content {
  .MuiTabPanel-root {
    background-color: $white;
    padding: 0px !important;
  }

  .MuiTabs-root {
    .MuiButtonBase-root {
      background-color: $paleGray;
    }

    .MuiButtonBase-root.Mui-selected {
      background-color: $white;
    }
  }
}

.webhook-events-details {
  .Mui-expanded {
    margin: 0px !important;
  }

  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }
}

.NoDataAvailable {
  .not-found {
    padding: 20px 450px 25px !important;
  }

  .text-wrapper {
    width: 200px;
  }
}

.event-data-hide {
  height: 250px;
  overflow: hidden;
}

.event-data {
  padding: 15px 15px 80px 0;

  .MuiBox-root .text-wrapper {
    word-break: break-word;
  }
}

.request-endpoint {
  padding: 20px 20px;

  .MuiBox-root .text-wrapper {
    word-break: break-word;
  }
}

.request-endpoint-hide {
  height: 200px;
  overflow: hidden;
}

.request-endpoint-button {
  color: $primaryBlue;
  font-size: $font14;
  left: 55px;
  bottom: 12px;
  background-color: $white;
}

.request-endpoint-button:hover {
  background-color: #f0f3fa !important;
}

.webhook-events-columns {
  display: -webkit-inline-box;
  height: 28px;
  margin-top: 13px;
  padding: 0 16px;
}

.events-accordion {
  padding: 10px 10px;
  color: $greyText;
}

.events-webhook-endpoint {
  border: solid 1px $lightCyanBlue;
  border-radius: 5px;
  background-color: $paleGray;
  width: 750px;
  margin-left: 25px;
}

.view-all-webhook-attempts {
  color: $primaryBlue;
  margin-top: 22px;
  left: 890px;
  height: 34px;
}

.clear-log-filter-btn {
  box-shadow: none !important;
  color: $primaryBlue;
  font-family: $fontRegular !important;
  font-size: $font14 !important;
  font-style: normal;
  font-weight: 500;
  line-height: 140% !important;
  padding: 0 !important;

  &:hover {
    background: none;
  }
}

.clear-button-position {
  div {
    margin-left: 6px !important;
  }
}

.webhook-attempts-tab {
  .MuiBox-root {
    .MuiTabPanel-root {
      padding: 0px !important;
    }
  }
}

.no-availble-logs-block {
  display: block !important;
}

.jsonDisplay-showMoreFade {
  @include fadeJsonDisplay();
  width: 100%;
  transform: translateY(-100%);
  height: 100px;
}

.jsonDisplay-showMoreFade-endpoint {
  @include fadeJsonDisplay();
  width: 75%;
  transform: translateY(-65%);
  height: 150px;
  bottom: -85px;
}

.webhook-tabs-data {
  .MuiTabs-flexContainer {
    padding-left: 5px !important;
  }
}

.event-data-wrapper {
  padding-top: 50px;
}

.event-data-wrapper-hide {
  height: 360px;
}

.webhook-attempt-events {
  @include webhookAttemptEvents(unset);
}

.webhook-attempt-events-accordian {
  @include webhookAttemptEvents(750px);
}

.webhook-attempt-events-endpoint {
  padding-top: 50px;
}

.rest-key-copy {
  & .input-selection-box {
    & .MuiInputBase-input {
      background-color: $paleGray !important;
      border-color: transparent !important;
      opacity: unset !important;
    }
  }
}

.restricted-key-roll-api {
  .MuiDialogContent-dividers {
    padding: 0px !important;
  }
}

.restricted-key-clipboard {
  .MuiInputBase-root {
    width: 358px;
  }
}

.restricted-key-detail-page {
  padding-left: 30px;
  padding-right: 16px;
}

.alert-delete-icon {
  svg {
    height: 16px !important;
    width: 18px !important;
    margin-bottom: unset !important;
  }
}