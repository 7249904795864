@import "../core.scss";

.input-element {
  .MuiInputBase-root {
    border: 1px solid transparent;
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .MuiInputBase-input {
      -webkit-appearance: none;
      border-radius: 4px;
      position: relative;
      background-color: $white;
      font-size: $font16;
      font-family: $fontMedium;
      height: 20px;
      padding: 10px 12px;
      color: $darkIndigo;
      @include boxShadow();
      &.Mui-disabled {
        background-color: $lightAliceBlue;
        opacity: none;
      }
    }
    &.Mui-focused {
      @include applyFocusBorder();
      .MuiInputBase-input {
        box-shadow: none;
      }
    }
    &.Mui-focused.Mui-error {
      @include applyBorder($paleRed);
      border-radius: 4px;
    }
    @-moz-document url-prefix() {
      input[type="password"] {
        font-family: caption;
        &::placeholder {
          font-family: $fontMedium;
        }
      }
    }
  }

  &.password-input {
    .MuiInputBase-root {
      input {
        padding-right: 40px;
      }
    }
    .password-visibility-icon {
      cursor: pointer;
      position: absolute;
      right: 10px;
      svg {
        opacity: 0.5;
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &.setup-2fa-input {
    .Mui-disabled {
      opacity: unset;
      -webkit-text-fill-color: unset;
    }
    .MuiInputBase-input {
      height: 40px;
      margin: 58px 0 30px 0;
      border-radius: 4px;
      box-shadow: 0 0 0 1px rgba(11, 37, 75, 0.08);
      background-color: $paleGray;
      font-size: $font30;
      font-family: $fontLight;
      text-align: center;
      color: $darkIndigo;
    }
  }
  .MuiBox-root {
    .custom-popper {
      margin-bottom: 10px !important;
    }
  }
}

.label-with-icon {
  &.MuiSvgIcon-root {
    height: 14px;
    width: 14px;
    color: $greyText;
    margin-left: 7px;
    cursor: pointer;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .input-element {
    &.setup-2fa-input {
      .MuiInputBase-input {
        font-size: $font24;
      }
    }
  }
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .input-element {
    .MuiInputBase-root {
      .MuiInputBase-input {
        font-size: $font14;
      }
    }
    &.setup-2fa-input {
      .MuiInputBase-input {
        font-size: $font24;
      }
    }
  }

  .label-with-icon {
    &.MuiSvgIcon-root {
      height: 12px;
      width: 12px;
    }
  }
}
