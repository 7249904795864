@import '../core.scss';

@mixin linkVariant() {
    &.bold {
        font-family: $fontSemiBold;
    }
}

.MuiLink-root {
    cursor: pointer;

    &.small {
        font-size: $font14;
        @include linkVariant()
    }
    &.large {
        font-size: $font16;
        @include linkVariant()
    }

    &.Mui-focusVisible {
       @include applyFocusBorder()
    }
}

@media only screen 
  and (max-device-width: 1024px) {
    .MuiLink-root {
        &.large {
            font-size: $font14;
        }
    }
  }

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .MuiLink-root {
        cursor: pointer;
        &.large {
            font-size: $font14;
        }
    }
}