@import '../core.scss';

.button-toggle {
    &.MuiToggleButtonGroup-root {
        @include boxShadow()
    }

    .MuiToggleButton-root {
        padding: 7px;
        &.Mui-selected {
            color: $primaryBlue;
            background-color: #FFFFFF;
            border-left: 1px solid #d9dcdd;
        }
    }
  
}
