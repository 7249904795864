@import '../../assets/styles/default.scss';


.MuiTableContainer-root {
    .MuiCircularProgress-root {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .MuiTableRow-root {
        &:nth-of-type(even) {
            background: $paleGray;
        }

        &:nth-of-type(odd) {
            background: $white;
        }

        &.clickable {
            cursor: pointer;
        }

        &:hover {
            background-color: $aliceBlue;
        }

        .MuiTableCell-root {
            color: $darkIndigo;
            font-size: $font16;
            padding: 10px 15px;

            &.MuiTableCell-head {
                font-size: $font14;
            }

            .flex-inline {
                display: flex;
                align-items: center;

                .icon-wrapper {
                    padding-right: 5px;
                    padding-top: 5px;

                    .MuiSvgIcon-root {
                        color: $greyText;
                    }

                    &.active {
                        .MuiSvgIcon-root {
                            color: $greenishTeal;
                        }
                    }

                    &.small-icon {
                        .MuiSvgIcon-root {
                            font-size: $font14;
                        }
                    }
                }
            }

            .MuiSkeleton-root {
                height: 16px;
                border-radius: 6px;
            }
        }

        th {
            &.MuiTableCell-root {
                background-color: $paleGray;
                font-family: $fontSemiBold;
            }
        }
    }
}

.not-found {
    text-align: center !important;
    background-color: white;
    padding: 125px 0px 25px !important;
}

.not-found-icon {
    height: 43px;
    width: 43px;
    color: #848b9e;
}

.table-filter-main-wrapper {
    position: relative;
    display: inline-flex;
    left: 5px;
    top: 5px;

    svg {
        color: $greyText;
    }

    .filter-icon {
        cursor: pointer;
        font-size: $font20;
    }
}

.table-column-filter-popper {
    &.custom-popper {
        width: max-content;

        .MuiBox-root {
            max-width: inherit;
        }

        .MuiList-root {
            padding: 0;

            .MuiListItem-root {
                .MuiButtonBase-root {
                    padding: 5px;
                    border-radius: 4px;

                    .MuiListItemText-root {
                        padding-left: 5px;
                    }
                }
            }
        }

        .operator-filter-popper {

            &.MuiBox-root,
            .MuiBox-root {
                padding: 0;
                box-shadow: none;
            }

            .amount-filter-wrapper {
                .input-with-dropdown-label {
                    font-size: $font14;
                    margin-bottom: 14px;
                    font-family: $fontMedium;
                    line-height: 1;
                    letter-spacing: normal;
                }

                .filter-operators,
                .filter-value-input {
                    width: 170px;
                }

                .filter-value-input {
                    margin-left: 15px;

                    .mask-input-wrapper {
                        display: flex;

                        .mask-input {
                            height: 41px;
                            box-sizing: border-box;
                        }
                    }
                }
            }

            .MuiDivider-root {
                margin: 14px 0px;
            }
        }

        .operator-filter-footer {
            margin-bottom: 4px;
        }
    }
}