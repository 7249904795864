@import "@speed/common/src/components/core.scss";
@import "@speed/common/src/assets/styles/common.scss";

@mixin commonSelectMenuCss() {
  width: 270px;
  height: 42px;
  margin-left: 2px;
}

@mixin removeButtonOutlineCss() {
  box-shadow: none;
  border: none !important;
}

@mixin commonSaveSectionCss() {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid $lightCyanBlue;
  border-bottom: 1px solid $lightCyanBlue;
  padding: 20px 0px;
}

@mixin rightAlignedButtons() {
  display: flex;
  justify-content: flex-end;
}

.invoice-data-table {
  .invoice-action-table-cell {
    padding: 0px 15px !important;
  }
}

.invoice-list-action-popper {
  &.MuiList-root {
    .MuiListItemButton-root {
      padding: 3px 10px !important;
    }
  }
}

.pay-this-invoice-btn {
  &.MuiButtonBase-root {
    background-color: #2250A1;
  }
}

.disable-edit-section {
  pointer-events: none;
  opacity: 0.4;
}

.invoice-preview {
  background-color: $paleGray;
}

.invoice-detail-wrapper {
  @include detailWrapper();

  .section-wrapper {
    position: relative;

    .custom-popper {
      .MuiList-root {
        padding: 0;

        .MuiListItem-root {
          .MuiButtonBase-root {
            padding: 3px 10px;
            border-radius: 4px;

            &:hover {
              background-color: $lightAliceBlue;
            }

            .MuiListItemText-root {
              .MuiTypography-root {
                font-size: $font14;
              }
            }
          }
        }
      }
    }

    .action-btn-wrapper {
      top: 23px !important;
      right: 2px !important;
      display: flex;
      align-items: center;

      .horizontal-dots-icon {
        margin-left: 15px;
      }
    }

    .header-content {
      margin-top: 20px;

      .header-price-content {
        margin-top: 10px;

        .fiat-price {
          .MuiTypography-root {
            color: $primaryBlue;
            margin-right: 13px;
          }
        }
      }

      .invoice-copy-url {
        margin-top: 15px;
      }
    }

    .invoice-timeline-wrapper {
      .invoice-timeline-header {
        margin: 60px 0px 12px 0px;
        display: flex;
        justify-content: space-between;

        .add-note-icon {
          width: 16px;
          height: 16px;
        }

        .add-note-icon,
        .MuiTypography-root {
          color: $primaryBlue;
        }
      }

      .add-note-input-section {
        .save-notes {
          display: flex;
          justify-content: flex-end;
          margin: 25px 0px 20px 0px;
        }
      }

      .time-line-content {
        .invoice-timeline-graph {
          padding: 0;

          .MuiTimelineItem-root {
            &::before {
              flex: 0;
              padding: 0;
            }

            .MuiTimelineContent-root {
              display: grid;
              position: relative;
              bottom: 15px;
            }

            .MuiTimelineDot-root {
              margin: 0;

              .MuiSvgIcon-root {
                color: $primaryBlue;
              }
            }

            .MuiTimelineConnector-root {
              height: 20px;
            }

            .horizontal-dots-icon {
              font-size: 24px;
            }
          }
        }
      }
    }

    .invoice-details-wrapper {
      .invoice-details-header {
        margin: 60px 0px 20px 0px;
        display: flex;
        justify-content: space-between;

        .download-invoice-pdf-icon {
          width: 16px;
          height: 16px;
          color: $primaryBlue;
        }

        .download-txt {
          &.MuiTypography-root {
            color: $primaryBlue;
          }
        }
      }

      .detail-box {
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 24px;

        .MuiGrid-root {
          .MuiTypography-root {
            word-break: break-word;
          }
        }
      }
    }

    .invoice-meta-data-content,
    .invoice-event-data-content,
    .invoice-product-data-content,
    .invoice-history-data-content,
    .invoice-payments-data-content {
      margin-top: 60px;
    }

    .invoice-meta-data-content {
      .horizontal-table-wrapper {
        .MuiTable-root {
          .MuiTableBody-root {
            .MuiTableRow-root {
              .MuiTableCell-root {
                &:nth-child(2) {
                  width: 20%;
                  padding-right: 0px;
                }

                &:nth-child(3) {
                  width: 30%;
                }
              }
            }
          }
        }
      }
    }

    .invoice-product-data-content,
    .invoice-history-data-content {
      .horizontal-table-wrapper {
        .MuiTableContainer-root {
          .MuiTable-root {
            .MuiTableBody-root {
              .MuiTableRow-root {
                .MuiTableCell-root {
                  padding: 10px 15px;

                  &:nth-child(1) {
                    padding-left: 0;
                    min-width: 355px;
                  }
                }
              }
            }
          }
        }
      }

      .invoice-total-section {
        width: 50%;
        margin-left: auto;
      }
    }
  }
}

.invoice-delete-draft-modal-footer {
  @include rightAlignedButtons();

  .delete-btn {
    margin-left: 15px;
  }
}

.send-invoice-modal {
  .invoice-send-modal-body {
    .send-email-input-section {
      margin-top: 30px;

      .react-multi-email {
        margin-top: 10px;
      }
    }
  }

  .invoice-send-modal-footer {
    @include rightAlignedButtons();

    .save-btn {
      margin-left: 15px;
    }
  }
}

.change-invoice-status-modal {
  .MuiDialogContent-root {
    padding: 0 !important;

    .invoice-status-change-body {
      .invoice-status-box {
        padding: 24px 30px;

        .invoice-status-radio-element {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;

          .status-description {
            margin: 5px 0px 0px 33px;
          }
        }

        .learn-more-text {
          display: flex;
          align-items: center;
          margin-top: 15px;
          cursor: pointer;

          .MuiTypography-root,
          svg {
            color: $primaryBlue;
          }

          .MuiTypography-root {
            line-height: 160%;
          }

          svg {
            font-size: $font16;
            margin-left: 5px;
          }
        }
      }

      .invoice-internal-note {
        padding: 24px 30px;
      }
    }
  }

  .invoice-change-status-footer {
    @include rightAlignedButtons();

    .save-btn {
      margin-left: 15px;
    }
  }
}

.invoice-add-customor-modal {
  .MuiDialog-container {
    .add-more-details {
      margin-top: 15px;
      width: fit-content;

      .MuiPaper-root {
        .MuiButtonBase-root {
          width: fit-content;
        }

        .auto-complete-icon {
          .MuiButtonBase-root {
            top: 0;
            right: 0;
            position: relative;
          }
        }
      }

      .MuiAccordionSummary-root {
        .MuiAccordionSummary-content,
        .MuiAccordionSummary-expandIconWrapper svg {
          color: $primaryBlue;
        }
      }
    }

    .invoice-customer-modal-footer {
      @include rightAlignedButtons();

      .save-btn {
        margin-left: 15px;
      }
    }
  }
}

.invoice-item-currency-modal {
  .MuiDialog-container {
    .MuiDialogContent-root {
      .MuiAutocomplete-root {
        .MuiButtonBase-root {
          position: initial;
        }
      }
    }

    .invoice-item-currency-modal-footer {
      @include rightAlignedButtons();

      .update-btn {
        margin-left: 15px;
      }
    }
  }
}

.invoice-create-form {
  padding-left: 1px !important;

  .form-box-wrapper {
    width: 480px;
    margin-left: 2px;

    .invoice-label-text {
      margin-bottom: 15px;
      font-size: $font18 !important;
    }

    .invoice-checkbox {
      display: flex;
      align-items: center;
      margin-top: 15px;

      .checkbox-wrapper {
        position: relative;
        left: 5px;

        .MuiFormControlLabel-label {
          color: $darkIndigo;
        }
      }

      .checkbox-info-icon {
        height: 20px;
        width: 20px;
        color: $greyText;
        cursor: pointer;
      }
    }

    .invoice-checkbox-content {
      margin: 5px 0px 0px 38px;

      .invoice-custom-field-form {
        .horizontal-table-wrapper {
          .MuiTableContainer-root {
            .MuiTable-root {
              .MuiTableBody-root {
                border-bottom: none;

                .MuiTableRow-root {
                  align-items: center;
                  padding-right: 3px;

                  .MuiTableCell-root {
                    padding: 10px 0px 10px 10px;

                    &:nth-child(1) {
                      padding-left: 0px;
                    }

                    .MuiButtonBase-root {
                      padding: 0px;
                    }
                  }
                }
              }
            }
          }
        }

        .custom-field-save-section {
          @include commonSaveSectionCss();
        }

        .add-metadata-container {
          padding-left: 0px;

          .add-custom-field-icon {
            width: 20px;
            height: 20px;
            color: inherit;
          }
        }
      }
    }

    .create-new-element-box {
      align-items: center;
      padding: 5px 0px;
      display: flex;
      color: $primaryBlue;

      &:hover {
        color: $primaryBlueHover;
      }

      .add-icon {
        width: 20px;
        height: 20px;
        color: inherit;
      }

      .MuiTypography-root {
        color: inherit;
      }
    }

    .invoice-customer {
      .customer-detail-box {
        .customer-name {
          display: flex;
          justify-content: space-between;
          word-break: break-word;

          .custom-popper {
            .MuiList-root {
              padding: 0;

              .MuiButtonBase-root {
                padding: 0px 10px;
                border-radius: 4px;

                .MuiTypography-root {
                  display: flex;
                  align-items: center;

                  .change-customer-info-icon {
                    margin-left: 10px;
                    color: $greyText;
                    font-size: $font20;
                  }
                }
              }
            }
          }
        }

        .customer-email-text {
          color: #667085;
        }
      }

      .invoice-customer-selection-menu {
        .customer-menu-item-box {
          display: flex;
          padding: 5px 10px;
          border-radius: 4px;
          align-items: center;
          overflow: hidden;

          &:hover {
            background-color: $backgroundHover;
          }

          .customer-name {
            word-break: break-word;
          }

          .customer-email-text {
            margin-left: 5px;
          }
        }

        .MuiAutocomplete-popper {
          .MuiAutocomplete-noOptions {
            padding: 10px;
          }
        }
      }
    }

    .invoice-items {
      .invoice-items-header {
        display: flex;

        .select-item-currency {
          display: flex;
          padding-top: 5px;
          margin-left: 10px;
          cursor: pointer;

          .MuiTypography-root {
            color: $primaryBlue;
          }

          .select-currency-icon {
            font-size: $font20;
            color: $greyText;
          }
        }
      }

      .add-item-box {
        cursor: pointer;
        width: fit-content;
        padding: 20px 0px;

        .MuiTypography-root {
          color: $primaryBlue;
        }
      }

      .product-row {
        padding: 10px 0px;
        border-bottom: 1px solid $lightCyanBlue;

        .added-product-details {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .product-name-quantity {
            word-break: break-word;
          }

          .invoice-product-price {
            display: flex;
            align-items: center;
            height: 40px;

            .invoice-product-action-box {
              width: 40px;
              margin-left: 10px;

              .more-action-button-box {
                .btn-actions-wrapper {
                  width: 40px;
                }
              }
            }
          }
        }
      }

      .invoice-item-selection-menu {
        .invoice-items-menu-box {
          padding: 5px 10px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          overflow: hidden;

          &:hover {
            background-color: $backgroundHover;
          }

          .item-name {
            &.MuiTypography-root {
              word-break: break-word;
            }
          }
        }

        .MuiAutocomplete-popper {
          .MuiAutocomplete-noOptions {
            padding: 10px;
          }
        }
      }

      .item-details {
        .invoice-item-details-selection-menu {
          .MuiAutocomplete-root {
            .MuiInputBase-root,
            input {
              box-sizing: border-box;
            }
          }

          .invoice-details-items-menu-box {
            padding: 5px 10px;
            border-radius: 4px;
            display: flex;
            justify-content: space-between;

            &:hover {
              background-color: $backgroundHover;
            }
          }
        }

        .item-save-section {
          @include commonSaveSectionCss();

          &.border-bottom-none {
            border-bottom: none;
          }

          .cancel-btn {
            @include removeButtonOutlineCss();
          }

          .save-btn {
            margin-left: 25px;
          }
        }
      }
    }

    .invoice-due-date-section {
      display: flex;

      .invoice-due-date-menu {
        @include commonSelectMenuCss();
      }

      .invoice-date-picker {
        margin-left: 15px;
        width: 314px;

        .custom-date-picker-textfield {
          .MuiInputBase-root,
          input {
            cursor: pointer;
          }

          width: 100%;

          .MuiInputBase-root {
            height: 42px;
          }
        }
      }
    }

    .invoice-delivery-section {
      .invoice-delivery-menu {
        @include commonSelectMenuCss();
      }

      .do-not-email-instruction {
        margin: 15px 0px 0px 2px;
      }
    }
  }
}

.more-action-button-box {
  cursor: pointer;

  svg {
    color: $greyText;
    font-size: $font24;
  }

  &.single-btn-available {
    .btn-actions-wrapper {
      display: inline-flex;
      height: 24px;
      width: 24px;

      .main-action-btn {
        height: 100%;

        &:focus {
          @include applyFocusBorder();
        }
      }
    }
  }

  &.extra-btn-available {
    &:hover,
    &.open-options {
      .btn-actions-wrapper {
        position: relative;
        border: 1px solid $lightGrey;
        border-radius: 5px;
        background-color: $white;
        align-items: center;
        height: 40px;
        display: flex;

        .extra-action-icon,
        .main-action-btn {
          height: 40px;
          display: flex;
        }

        .extra-action-icon {
          &.first-action-btn {
            border-radius: 5px 0px 0px 5px;
          }

          &:hover {
            background-color: $paleGray;
            height: 100%;
          }

          border-right: 1px solid $lightGrey;
        }

        .main-action-btn {
          &.last-action-btn {
            border-radius: 0px 5px 5px 0px;
          }
        }
      }
    }

    svg {
      font-size: $font18;
    }

    .btn-actions-wrapper {
      .extra-action-icon,
      .main-action-btn {
        height: 40px;
        width: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;

        &:focus {
          @include applyFocusBorder();
        }
      }

      .extra-action-icon {
        display: none;
      }
    }
  }
}

#invoice-due-date-select {
  .MuiPaper-root {
    .MuiList-root {
      .blue-text {
        color: $primaryBlue;
      }
    }
  }
}

#invoice-due-date-picker {
  margin-left: 25px !important;
}

//invoice preview section
.pl-20 {
  padding-left: 20px;
}

.mt-40 {
  margin-top: 40px;
}

@mixin commonTableCss() {
  &.MuiTableContainer-root {
    border-radius: 0px;
    box-shadow: none;
  }

  .MuiTableCell-root {
    &.MuiTableCell-head {
      background-color: transparent;
      padding: 10px;
    }
  }

  .MuiTableRow-root {
    background-color: transparent;
  }
}

.invoice-preview-section-wrapper {
  .invoice-previews-btn {
    background-color: $white;
  }

  .invoice-previews-btn .button-toggle .MuiToggleButton-root.Mui-selected {
    background-color: $paleGray;
    @include applyBorder(#d9dcdd);
  }

  .invoice-preview-container {
    background: $white;
    box-shadow: 2px 4px 32px -2px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    min-height: 842px;
    max-width: 595px;

    .billing-info {
      word-wrap: break-word;
      margin-top: 10px;
    }
  }

  .invoice-top-border {
    height: 8px;
  }

  .invoice-info-text {
    padding-left: 20px;
    padding-top: 12px;
  }

  .invoice-info-section {
    padding-left: 20px;
    margin-bottom: 20px;
    @include tableRowCommonCss();
  }

  .logo-icon-img {
    height: 100%;
    width: 100%;
  }

  .account-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    max-width: 15%;
  }

  .payment-section {
    height: 60px;
    display: flex;
    align-items: center;
  }

  .payment {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    word-wrap: break-word;
    margin-right: 29px;
    width: 100px;
  }

  .pay-online {
    font-size: 10px;
  }

  .memo-section {
    display: flex;
    padding: 10px 20px;
    align-items: center;
    word-break: break-all;
  }

  .divider {
    margin: 0px 10px 0px 10px;
  }

  .product-table {
    padding: 0px 10px;
    margin-top: 20px;
    @include commonTableCss();
    .MuiTableCell-root {
      padding: 10px 10px;
    }
    .MuiTableHead-root {
      border-top: 1px solid #e0e0e0;
    }
  }

  .total-table {
    padding: 0px 10px 0px 0px;
    @include commonTableCss();
    .MuiTableCell-root {
      padding: 10px;
    }
  }

  .footer-desc {
    margin: 15px 0px;
    margin-top: 15px;
    width: 250px;
    word-break: break-all;
  }

  .invoice-pdf-footer-section {
    margin-top: auto;
  }

  .invoice-footer {
    display: flex;
    align-items: center;
    height: 50px;
  }

  .default-logo {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }

  .email-preview-container {
    box-shadow: 2px 4px 32px -2px rgba(0, 0, 0, 0.08);

    .email-preview-header {
      background-color: $white;
      padding: 20px 0px 20px 20px;
    }

    .account-name-section {
      padding: 20px;
    }

    .download-invoice {
      cursor: pointer;
    }

    .invoice-help-icon {
      height: 20px;
      width: 20px;
    }

    .mail-info-box {
      margin: 30px 40px 20px;
      border-radius: 5px;
      background-color: $white;
    }

    .email-info-tbl-row {
      @include tableRowCommonCss();
    }

    .email-preview-footer {
      display: flex;
      padding-bottom: 20px;
      margin: 0px 40px;

      .email-footer {
        display: flex;

        img {
          height: 15px;
        }
      }
    }

    .crypto-invoicing {
      font-size: 10px !important;
      text-decoration: underline;
    }

    .checkout-acc-currency,.checkout-acc-amount {
        text-align: center;
    }
  }
}

//invoice payment page preview
.invoice-payment-page {
  @include commonInvoicePageAccordion();
}

.product {
  @include commonItemsSection();
}

.invoice-info-tbl-row {
  display: block;
  padding: 4px 0px;
  border-bottom: 0;
  background-color: $white;
}

.customer-radio-label {
  color: $darkIndigo;
  margin-top: 15px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-60 {
  margin-left: 60px;
}

.invoice-pdf {
  .MuiSwitch-root {
    .Mui-checked + .MuiSwitch-track {
      background-color: $primaryBlue !important;
    }
  }
}

.terms {
  .MuiBox-root {
    width: 10%;
  }
}

#term-drop-down {
  .MuiPopover-paper {
    left: 112px !important;
  }
}

.reminder-drop-down {
  width: 50%;

  .MuiBox-root {
    color: $darkIndigo !important;
    opacity: 1 !important;
  }
}

.reminder-fields {
  margin-bottom: 14px;

  .Mui-disabled {
    background-color: $lightAliceBlue;
  }
}

.remove-icon {
  color: $paleRed;
}

.add-reminder {
  color: $primaryBlue;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.invoice-additional-details {
  .input-box {
    padding-left: 2px;
    padding-right: 2px;
    margin-top: 24px;
  }

  .customer-form-wrapper {
    width: 523px;

    .customer-form-box {
      .customer-form-info {
        padding-left: 2px;
        padding-right: 2px;
      }
    }
  }
}
