@import "@speed/common/src/components/core.scss";

.add-payment-custom-domain-wrapper {
  & span:first-child {
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid $lightCyanBlue;
    color: $primaryBlue;
    &:hover {
      color: $primaryBlueHover !important;
    }
  }
}
