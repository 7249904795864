@import "../core.scss";

.input-selection-box {
    &:focus-visible {
        @include applyFocusBorder();
    }
    
    .MuiInputBase-root {
        .MuiInputBase-input {
            width: inherit;
            height: inherit;
            font-size: $font14;
            box-sizing: border-box;
            padding: 7px 11px 8px 10px;
            border-radius: 4px;
            background-color: $white;
            color: $darkIndigo;
            letter-spacing: normal;
            @include applyBorder(#d7e1eb);
        }

        &.disabled {
            .MuiInputBase-input {
                color: $greyText;
                opacity: 0.52;
                background-color: #eaf1f5;
            }
        }

        &.round {
            &.disabled {
                .MuiInputBase-input {
                    @include boxShadow();
                    background-color: $paleGray;
                    color: $greyText;
                    border: none;
                    opacity: 1;
                }
            }

            .MuiInputBase-input {
                border-radius: 15px;
            }
        }

        &.copied-background {
            .MuiInputBase-input {
                background-color: #FFFDE9;
            }
        }
    }
}
