@import "../core.scss";

.dropdown-input-wrapper {
  display: flex;
  border-radius: 4px;
  background-color: $white;
  @include boxShadow();

  .MuiOutlinedInput-root {
    box-shadow: none !important;
    width: auto !important;
    position: relative;

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    .MuiOutlinedInput-input {
      width: auto !important;
      padding: 8px 30px 8px 14px !important;
    }

    &.Mui-focused {
      border: none !important;
    }
  }

  .phone-number-input {
    border-radius: 4px;
    position: relative;
    font-size: $font16;
    font-family: $fontMedium;
    padding: 10px 12px;
    color: $darkIndigo;
    width: 100%;
    border: none;
    margin-left: 3px;

    &:focus {
      @include applyFocusBorder();
    }

    &::placeholder {
      color: $greyText;
      opacity: 0.5;
      font-family: $fontRegular;
    }
  }

  .MuiInputBase-input {
    box-shadow: none !important;
  }
}

#calling-code-select {
  .MuiPaper-root {
    margin-left: 0;
  }
}

#select-calling-code {
  .MuiPaper-root {
    left: 115px !important;
    min-width: 396px !important;
  }
}

#mfa-select-calling-code,
#login-mfa-select-calling-code {
  .MuiPaper-root {
    position: sticky;
    left: 50% !important;
    transform: translate(-50%, 0%) !important;
  }
}

#contact-info-select-calling-code-preview {
  .MuiPaper-root {
    width: 358px !important;
    margin-left: 148px;
    margin-top: 4px;

    .MuiList-root {
      .MuiButtonBase-root {
        div:first-child {
          width: 250px !important;
          text-wrap: wrap;
        }
      }
    }
  }
}

#contact-info-mobile-select-calling-code-preview {
  .MuiPaper-root {
    width: 349px !important;
    margin-left: 142px !important;
    margin-top: 4px;

    .MuiList-root {
      .MuiButtonBase-root {
        div:first-child {
          width: 250px !important;
          text-wrap: wrap;
        }
      }
    }
  }
}

#select-contact-info-calling-code {
  left: 12px !important;
  .MuiPaper-root {
    width: 442px !important;
    position: absolute;
    margin-top: 4px;
    .MuiList-root {
        li {
          margin-right: 26px;
        }
      }
    }
}

#select-contact-info-calling-code-mobile {
  .MuiPaper-root {
    left: 50% !important;
    transform: translate(-50%, 0%) !important;
    margin-top: 4px;

    .MuiList-root {
        li {
            margin-right: 12px;
          }
        .MuiButtonBase-root {
          div:first-child {
            width: 250px !important;
            text-wrap: wrap;
          }
        }
      }
  }
}

#mfa-select-calling-code {
  .MuiPaper-root {
    width: 384px;
  }
}

#login-mfa-select-calling-code {
  .MuiPaper-root {
    width: 365px !important;
  }
}

.input-with-dropdown-label {
  display: block;
  margin-bottom: 14px;
  color: $greyText;
  font-family: $fontMedium;
  font-size: $font14;
}

@media only screen and (min-device-width: 280px) and (max-device-width: 767px) {
  #mfa-select-calling-code,
  #login-mfa-select-calling-code {
    .MuiPaper-root {
      position: absolute;
    }
  }
}

@media only screen and (min-width: 768px) {
  #select-contact-info-calling-code-mobile {
    &.MuiPopover-root {
      .MuiPopover-paper {
        margin-left: 0px !important;
      }
    }
  }
}