@import '../assets/styles/default.scss';

@mixin boxShadow {
  box-shadow: 0 4px 4px -2px rgba(11, 37, 75, 0.12), 0 0 0 1px rgba(11, 37, 75, 0.08);
}

@mixin applyBorder($theme: $primaryBlue) {
  border: 1px solid $theme;
}

@mixin detailWrapper {
  display: table-cell;
  width: 1080px;
  padding: 18px 30px 100px 40px;
}

@mixin contentCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin iconBox ($color, $width, $height) {
  width: $width;
  height: $height;
  border-radius: 70px;
  background-color: $color;
  @include contentCenter();
}

@mixin scrollbar {
  ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-thumb {
    height: 69px;
    border: 5px solid #f9f9f9;
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: $lightCyanBlue;
  }

  ::-webkit-scrollbar-track {
    background-color: #f9f9f9;
    box-shadow: 0px 0 0 1px $lightCyanBlue;
  }
}

@mixin applyFocusBorder {
  outline: 3px solid $outlineBlue;
  border-radius: 4px;
}

@mixin addCustomScrollBar {
  @include scrollbar();

  ::-webkit-scrollbar-track {
    box-shadow: none;
  }

  .text-area {
    &::-webkit-scrollbar-thumb {
      height: 25px;
    }
  }
}

@mixin twoSectionPreview() {
  .modal-container-grid {
    padding-left: 60px;
    height: 100%;
    position: relative;
    margin: 0 auto;

    .inner-grid {
      height: 100%;
      position: relative;
      z-index: unset;
      flex-grow: 1;

      .apply-auto-scroll {
        width: 100%;
        height: 100%;
        overflow: auto;
        padding: 30px 0;
        margin-left: 1px;
      }

      @include addCustomScrollBar();
    }
  }
}

@mixin uploadBrandingSectionMixin() {
  .upload-brand-details {
    align-items: center;
    margin-right: 50px;

    .upload-brand-icon {
      width: 40px;
      height: 40px;
      padding: 7px;
      border-radius: 25px;
      background-color: $white;
      @include applyBorder($lightCyanBlue);
      cursor: pointer;

      &:focus-visible {
        @include applyFocusBorder();
        border-radius: 25px;
      }

      &:hover {
        svg {
          opacity: 1;
        }
      }

      svg {
        color: $greyText;
        opacity: 0.4;
        font-size: $font24;
      }
    }

    .input-element {
      display: none;
    }

    .brand-icon-avatar,
    .MuiSkeleton-root,
    .branding-image {
      height: 40px;
      width: 40px;
    }

    .box-icon {
      display: flex;
      justify-content: center;
      margin-bottom: 7px;

      &.upload-logo {
        .MuiSkeleton-root,
        .upload-brand-icon,
        .MuiAvatar-root {
          border-radius: 5px;
        }

        .MuiAvatar-root {
          min-width: 40px;
          max-width: 85px;
          width: auto;

          img {
            object-fit: contain;
          }
        }

        .branding-image {
          width: auto;
        }
      }

      .branding-image {
        justify-content: right;
        display: flex;

        .remove-icon {
          display: none;
          position: absolute;
          margin-top: -5px;
          margin-right: -5px;
          width: 24px;
          height: 24px;
          padding: 2px;
          background: white;
          border-radius: 15px;
          cursor: pointer;
          @include applyBorder($lightCyanBlue);

          &:focus-visible {
            @include applyFocusBorder();
            border-radius: 25px;
          }

          svg {
            color: $greyText;
            font-size: $font18;
          }
        }

        &:focus-visible {
          @include applyFocusBorder();
          border-radius: 25px;
        }

        &:hover {
          .remove-icon {
            display: block;
          }
        }
      }
    }

    .branding-color-picker {
      display: inline-flex;

      .reset-icon {
        display: none;
        position: relative;
        top: -10px;
        right: 10px;
        width: 24px;
        height: 24px;
        padding: 2px;
        background: white;
        border-radius: 15px;
        cursor: pointer;
        @include applyBorder($lightCyanBlue);

        svg {
          color: $greyText;
          font-size: $font18;
        }
      }

      &.reset-color {
        &:hover {
          .reset-icon {
            display: block;
          }
        }
      }
    }

    .select-branding-fonts {
      min-width: 220px;
      height: 30px;
      font-size: $font14;
    }

    .select-branding-icon {
      min-width: 117px;
      height: 30px;
      font-size: $font14;
    }

    .select-pay-via {
      min-width: 220px;
      height: 30px;
      font-size: $font14;
    }
  }
}

@mixin commonCSS($height,$padding) {
  height: $height;
  display: flex;
  align-items: center;
  padding: 0 $padding;
}

@mixin wrapperContent($mt,$mb,$headerHeight,$headerPadding,$footerHeight,$headerFS, $contentFS, $bodyMargin, $bodyPadding,$borderRadius:0,$contentWidth:unset) {
  .content-wrapper-box {
      margin-top: $mt;
      margin-bottom: $mb;
      background-color: $white;
      box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.08);
      border-radius: $borderRadius;

      .wrapper-header {
          @include commonCSS($headerHeight,$headerPadding);
          border-bottom: 1px solid $lightCyanBlue;
          .wrapper-header-text {
              font-size: $headerFS;
          }
      }
      .body-content {
          padding: $bodyMargin $bodyPadding;
          display: flex;
          justify-content: center;
          min-height: 302px;
          height: auto;
          .wrapper-content {
              font-size: $contentFS;
              width: $contentWidth;
          }
          .wrapper-content-email {
              margin-top: 40px;
          }
      }
      .wrapper-footer {
          border-top: 1px solid $lightCyanBlue;
          @include commonCSS($footerHeight,$headerPadding);
      }
  }
}

.Mui-error {
  @include applyBorder($theme: $paleRed);
  border-radius: 4px;
}

// copied-clipboard tooltip

.copied-clipboard-text {
  padding-top: 1px;
}