@import "@speed/common/src/components/core.scss";

.request-statement-modal {
    @include scrollbar();

    .request-statement-modal-content {
        .filter-date {
            margin-top: 20px;

            .picker-wrapper {
                display: flex;

                .custom-date-picker-textfield {
                    .MuiOutlinedInput-root {
                        height: 42px;
                        margin: 0px;

                        .MuiInputAdornment-root {
                            .MuiButtonBase-root {
                                top: auto;
                            }
                        }
                    }
                }
            }
        }

        .amount-range-box {
            margin: 24px 0px;

            .input-wrapper {
                display: flex;
                justify-content: space-between;
                margin-top: 16px;
            }
        }

        .transaction-source-box {
            margin-top: 24px;

            .auto-complete {
                .MuiAutocomplete-root {
                    .MuiInputBase-root {
                        padding: 0px 35px 0px 5px;

                        .MuiChip-root {
                            border-radius: 4px;
                            height: 28px;
                        }

                        .MuiAutocomplete-endAdornment {
                            .MuiButtonBase-root {
                                position: unset;
                            }
                        }
                    }
                }
            }
        }

        .display-order {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .MuiFormControlLabel-root {
                margin: 0;
            }
        }

        .reconciliation-format {
            margin-right: 0px;

            .MuiButtonBase-root {
                svg {
                    height: 18px;
                    width: 18px;
                }
            }

            .MuiTypography-root {
                font-family: $fontMedium;
                color: $darkIndigo;
            }
        }
    }
}

.request-sent-modal {
    .MuiDialog-paper {
        max-width: 461px;
    }

    .request-sent-modal-content {
        .MuiSvgIcon-root {
            &.tick-icon {
                width: 60px;
                height: 60px;
                color: $greenishTeal;
            }
        }
    }
}