@import "../core.scss";

@mixin tooltipCustomStyle($bgColor, $opacity) {
  background-color: $bgColor;
  font-size: $font12;
  opacity: $opacity !important;
  padding: 10px;
  display: flex;

  .MuiSvgIcon-root {
    position: relative;
    margin-top: 2px;
    margin-right: 5px;
    font-size: $font14;
  }
}

.tooltip-wrapper {
  .MuiTooltip-tooltip {
    @include tooltipCustomStyle($darkIndigo, 0.9);
  }

  .MuiTooltip-arrow {
    color: $darkIndigo;
  }
}

.custom-tooltip {
  .MuiTooltip-tooltip {
    @include tooltipCustomStyle($white, 1);
    color: $darkIndigo;
    box-shadow: 0 4px 7px 3px rgba(11, 37, 75, 0.12),
      0 0 0 1px rgba(11, 37, 75, 0.08);
  }

  .MuiTooltip-arrow {
    color: $white;
    &::before {
      box-shadow: 24px 24px 16px 3px rgba(11, 37, 75, 0.12),
        0 0 0 1px rgba(11, 37, 75, 0.08);
    }
  }
}
