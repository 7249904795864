@import '../../assets/styles/default.scss';

.switch {
    &.MuiSwitch-root {
        width: 46px;
        height: 26px;
        border-radius: 21px;
        padding: 0;
        display: flex;

        &:focus-within {
            outline: 3px solid $outlineBlue;
        }

        &:active {
            &.MuiSwitch-thumb {
                width: 22
            }

            &.MuiSwitch-switchBase.Mui-checked {
                transform: translate(9px)
            }
        }

        .MuiSwitch-switchBase {
            padding: 0;
            top: 2px;
            right: 22px;
            left: unset;
        }

        .Mui-checked {
            transform: translate(12px);
            color: $white ;
            right: 14px;

            &+.MuiSwitch-track {
                opacity: 1;
                background-color: $sunFlowerYellow ;
            }

            &.Mui-disabled {
                &+.MuiSwitch-track {
                    opacity: 1;
                    background-color: $lightYellow ;
                }
            }
        }

        .Mui-disabled {
            &+.MuiSwitch-track {
                background-color: #f0f2f5;
            }
        }

        .MuiSwitch-thumb {
            width: 22px;
            height: 22px;
            border-radius: 15px;
            box-shadow: none
        }

        .MuiSwitch-track {
            border-radius: 26 / 2;
            opacity: 1;
            background-color: $lightCyanBlue ;
            box-sizing: border-box;
        }

        .Mui-disabled .MuiSwitch-thumb {
            color: #fafafa
        }
    }
}

.primary-blue-switch {
    &.MuiSwitch-root {
        width: 46px;
        height: 26px;
        border-radius: 21px;
        padding: 0;
        display: flex;

        &:focus-within {
            outline: 3px solid $outlineBlue;
        }

        &:active {
            &.MuiSwitch-thumb {
                width: 22
            }

            &.MuiSwitch-switchBase.Mui-checked {
                transform: translate(9px)
            }
        }

        .MuiSwitch-switchBase {
            padding: 0;
            top: 2px;
            right: 22px;
            left: unset;
        }

        .Mui-checked {
            transform: translate(12px);
            color: $white ;
            right: 14px;

            &+.MuiSwitch-track {
                opacity: 1;
                background-color: $primaryBlue ;
            }

            &.Mui-disabled {
                &+.MuiSwitch-track {
                    opacity: 1;
                    background-color: $lightYellow ;
                }
            }
        }

        .Mui-disabled {
            &+.MuiSwitch-track {
                background-color: #f0f2f5;
            }
        }

        .MuiSwitch-thumb {
            width: 22px;
            height: 22px;
            border-radius: 15px;
            box-shadow: none
        }

        .MuiSwitch-track {
            border-radius: 26 / 2;
            opacity: 1;
            background-color: $lightCyanBlue ;
            box-sizing: border-box;
        }

        .Mui-disabled .MuiSwitch-thumb {
            color: #fafafa
        }
    }
}