@import "@speed/common/src/components/core.scss";

.product-invalid-link {
  .warning-icon {
    color: $paleRed;
    height: 60px;
    width: 60px;
    margin-bottom: 16px;
  }
}

.product-info {
  .image-upload-box {
    background-color: $paleGray;
    height: 120px;
    width: 120px;
  }
}

.product-image-preview {
  &:hover {
    position: relative;

    .remove-icon-product {
      display: block;
    }
  }
}

.product-img-box {
  @include applyBorder($lightCyanBlue);
  padding: 14px;

  .input-element {
    display: none;
  }

  .product-img {
    height: 120px;
    width: 120px;
  }

  .remove-icon-product {
    display: none;
    position: absolute;
    width: 24px;
    height: 24px;
    padding: 2px;
    background: $white;
    border-radius: 15px;
    cursor: pointer;
    bottom: 110px;
    left: 106px;
    @include applyBorder($lightCyanBlue);

    svg {
      color: $greyText;
      font-size: 18px;
    }
  }
}

.mt-24 {
  margin-top: 24px;
}

.product-modal {
  overflow: auto !important;
}

.product-price {
  border-radius: 4px;
  box-shadow: 0 4px 4px -2px rgba(11, 37, 75, 0.12),
    0 0 0 1px rgba(11, 37, 75, 0.08);

  .MuiInputBase-root {
    &.MuiOutlinedInput-root {
      box-shadow: none !important;
      border-radius: 0px !important;

      &.Mui-focused {
        border: 3px solid $white !important;
      }
    }
  }
  .mask-input-wrapper {
    width: 86%;
    .mask-input {
      box-shadow: none !important;
      border-radius: 0px !important;
      border-left: 1px solid $lightCyanBlue;
      height: 30px;
      margin: 7px;
      width: 95%;
      outline: none;
    }
  }
}

.product-price-currency {
  width: 45%;
}

.add-currency {
  width: 55%;
  .MuiIconButton-root {
    top: 0px;
    right: 0px;
  }
}

.product-detail-wrapper {
  .header-price-content {
    display: flex;
    margin-top: 9px;
    justify-content: space-between;
  }

  .product-detail {
    background-color: $paleGray;
    padding: 4px 14px;
    border-radius: 3px;
    color: $greyText !important;
    border: none;
  }
  .MuiButtonBase-root {
    &.MuiListItemButton-root {
      padding: 2px 35px 2px 16px;
    }
  }
  .MuiList-root {
    padding: 0px;
  }

  .custom-popper {
    .MuiBox-root {
      .text-danger {
        color: $paleRed;
      }
      .MuiTypography-root {
        font-family: $fontMedium;
        font-size: 14px;
      }
    }
  }
}
.pricing-form-wrapper {
  .MuiButtonBase-root {
    &.MuiListItemButton-root {
      padding: 2px 35px 2px 16px;
    }
  }
}

.product-prices-header {
  padding: 20px 35px;
}
