@import '../../assets/styles/default.scss';

.status-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
        color: $greenishTeal;
    }
    .completed-text {
        padding-left: 6px;
        color: $greenishTeal;
    }
    .skipped-text {
        padding-left: 10px;
        color: $greyText;
    }
    .skip-completed-icon {
        color: $white;
        height: 18px;
        width: 18px;
        background: $greyText;
        border-radius: 16px;
        padding: 3px;
    }
    .skip-icon {
        color: $white;
        height: 18px;
        width: 18px;
        background: $greyText;
        border-radius: 16px;
        padding: 3px;
    }
    .complete-icon {
        color: $white; 
        height: 18px;
        width: 18px;
        background: $greenishTeal;
        border-radius: 16px;
        padding: 3px;
    }
}