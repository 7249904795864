@import "@speed/common/src/components/core.scss";

@mixin iconRound() {
  height: 50px;
  padding: 13px;
  width: 50px;
  border-radius: 100px;
  border: 1px solid $lightGrayishRed;
}

@mixin iconCss() {
  text-align: center;
  margin-bottom: 16px;
}

@mixin bgColorWithBorder($bgColor, $borderColor) {
  background-color: $bgColor;
  border: 1px solid $borderColor;
}

@mixin lightningOnchainTab() {
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  flex: 1;
  margin-bottom: 8px;
  padding: 9px 0px;
  background-color: $white;
}

.get-started-box {
  display: flex;
  justify-content: center;
  padding-top: 46px;
  background: $paleGray;
  width: 100%;
  .get-started-header-box {
    max-width: 1200px;
    background: $white;
    box-shadow: 0px 4px 8px rgba(11, 37, 75, 0.06);
    border-radius: 5px;
  }
  .get-started-header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 310px;
  }
  .wallet-ad-msg {
    color: $veryDarkGrey;
    line-height: 140%;
  }
  .get-started-use-cases-box {
    padding: 0 50px;
    .use-case-text {
      color: $greyText;
      padding: 60px 0 40px 0;
    }

    .use-case-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 1100px;
    }

    .use-case-list-heading {
      display: flex;
      align-items: center;
      font-size: 22px;
    }

    .use-case-list-desc {
      color: $veryDarkGrey;
      padding-top: 8px;
      line-height: 140%;
    }
  }
}
.pos-mobile-logo {
  position: relative;
  bottom: 129px;
}

.advertisement-section {
  height: 392px;
  background: $lightGrayishBlue;
  border-radius: 10px;
  margin: 58px 50px 0 50px;
  .qr-code-section {
    display: flex;
    padding: 47px 0 0 81px;
  }
  .get-started-loader {
    background-color: $veryLightGrey !important;
  }
  .refrences {
    display: flex;
    justify-content: space-between;
    padding: 20px 0 0 81px;
    width: 490px;
  }
  .default-text {
    font-family: $fontRegular !important;
  }
}

.assets-search-bar {
  border-radius: 4px;
  border: 1px solid $lightCyanBlue;
  background: $white;
  height: 36px;
  width: 343px;
  margin: 12px 0px 12px 34px;
}

.assets-container {
  .assets-right-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 40px 0px 36px;

    .pointer-cursor {
      text-wrap: nowrap;
    }
  }
  .ln-address-setup {
    border-radius: 8px;
    border: 1px solid $lightGrayishRed;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    width: 100%;
  }
  .ln-address-icon {
    @include contentCenter();
    border-radius: 8px;
    border: 1px solid $lightGrayishRed;
    flex-direction: row;
    height: 60px;
    margin-left: 8px;
    padding: 16px;

    img {
      margin-right: 8px;
      margin-left: 16px;
    }
  }
  .assets-amount-section-usdt {
    background: $aquaSpring !important;
  }
  .assets-amount-section-btc {
    background: $lightOrange !important;

    .receive-now-btn {
      width: 180px;
    }
  }

  .assets-amount-section {
    border-radius: 4px;
    border: 1px solid $lightGrayishRed;
    margin: 0px 40px;
  }

  .assets-amount-currency-icon {
    height: 60px;
    width: 60px;
    border-radius: 40px;
  }

  .unconfirmed-transaction {
    @include contentCenter();
    border-radius: 4px;
    width: max-content;
    margin: auto;
    padding-top: 24px;
    cursor: pointer;

    .unconfirmed-arrow {
      color: $primaryBlue;
      width: 18px;
      height: 18px;
    }
  }

  .assets-currency-dropdown {
    .select.MuiOutlinedInput-root {
      font-weight: 500 !important;
    }
    .MuiInputBase-root {
      svg {
        color: $primaryBlue !important;
      }
    }
  }

  .receive-btn-usdt {
    color: $greenyBlue !important;
    border: 1px solid $greenyBlue !important;
  }

  .receive-btn-btc {
    color: $darkOrange !important;
    border: 1px solid $darkOrange !important;
  }

  .send-receive-btn {
    @include contentCenter();
    margin-top: 40px;
    flex-direction: row;
    gap: 20px;
  }

  .receive-btn {
    background-color: $white;
    height: 54px !important;
    svg {
      height: 24px;
      width: 24px;
    }
    div {
      margin: unset !important;
    }
  }

  .send-btn-usdt {
    background-color: $greenyBlue !important;
    border: 1px solid $greenyBlue !important;
  }

  .send-btn-btc {
    background-color: $darkOrange !important;
    border: 1px solid $darkOrange !important;
  }

  .send-btn {
    height: 54px !important;
    svg {
      height: 24px;
      width: 24px;
      path {
        transform: rotate(180deg);
        transform-origin: 50% 50%;
      }
    }
    div {
      margin: unset !important;
    }
  }

  .push-pin-hover {
    color: $lavenderGrey;
  }
}

.lnaddress-modal-text {
  width: 100%;
  .input-element {
    margin-top: 40px;
    .MuiGrid-root {
      justify-content: center;
    }
    .MuiInputBase-root {
      .MuiInputBase-input {
        height: 34px;
        border: 1px solid $primaryBlue;
      }
      .MuiInputBase-input:focus {
        border: unset;
      }
    }
  }
}

.lnaddress-complete-setup {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lnaddress-modal {
  @include contentCenter();
  flex-direction: row;
  border: 1px solid $lightGrayishRed;
  padding: 11px 32px;
  border-radius: 57px;
  margin-top: 8px;
  background-color: $white;
  width: 260px;
}

.lnaddress-text {
  word-break: break-all;
  text-align: center;
}

.send-receive-icon {
  background: $lightGrayishOrange;
  padding: 6px;
  border-radius: 18px;
  margin-right: 8px;
  height: 30px;
  width: 30px;
}

.send-payment-request-section {
  margin: 14px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 520px;
}

.address-truncate {
  @include contentCenter();
  flex-direction: column;
  width: 380px;
  div {
    color: $greyText;
    font-weight: 500;
  }
}

.select-payment-mode {
  border: 1px solid $lightGrayishRed;
  border-radius: 8px;
  background-color: $paleGray;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 21px 16px 21px 21px;
  cursor: pointer;
}

.transaction-details-clipboard {
  .MuiInputBase-root {
    width: 285px;
  }
}

.all-assets-details-clipboard {
  .MuiInputBase-root {
    width: 264px;
  }
}

.transaction-table-head {
  background: $white !important;
}

.wallet-transaction-list {
  cursor: pointer;
}

.wallet-pending-tag {
  .MuiChip-label {
    padding: 4px !important;
  }
}

.all-assets-table {
  height: 100% !important;
  .MuiTableHead-root {
    .MuiTableCell-root {
      background: $white !important;
    }
  }
}

.assets-table {
  height: 100% !important;
  .MuiTableHead-root {
    .MuiTableCell-root {
      background: $white !important;
    }
  }
  .MuiTableBody-root {
    .MuiTableRow-root {
      .MuiTableCell-root {
        svg {
          display: none;
        }
      }
      &:hover {
        svg {
          display: block;
        }
      }
      .show-default-svg {
        svg {
          display: block;
        }
      }
    }
  }
  .asset-icon {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    border-radius: 20px;
  }
  .asset-amount {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  .asset-currency {
    font-size: 16px;
    padding-left: 8px;
    color: $greyText;
    margin-right: 16px;
  }
}

.receive-modal-dialog {
  .MuiPaper-root {
    background-color: $paleGray !important;
    header {
      background-color: $white !important;
    }
  }
}

.receive-modal-container {
  width: 600px;
  align-self: center;
}

.receive-container {
  margin: 50px 0px;
  border-radius: 2px;
  padding: 24px 40px;
  border: 1px solid $lightGrayishRed;
  background-color: $grayblue;
}

.payment-address-qr-code {
  background-color: $paleGray !important;
  border: 1px solid $lightGrayishRed !important;
  padding-top: 48px;
}

.payment-address-receive-box {
  background: $white;
  width: 520px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 1px solid $lightGrayishRed;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.02);
}

.payment-qr-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px 8px 0px 0px;

  .payment-address-name {
    z-index: 100;
  }

  .slick-slider {
    width: 520px;
  }

  .qr-loader {
    width: 520px;
    background-color: $white;
    display: flex;
    justify-content: center;
  }

  .qr-skeleton {
    border-radius: 8px;
    margin: 18px 0px 20px;
    width: 290px;
    height: 290px;
  }

  .qr-code {
    background-color: $white;
    border-radius: 8px;
    border: 1px solid $lightGrayishRed;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.02);
    svg {
      height: 290px !important;
      width: 290px !important;
      margin-top: 10px;
      margin-bottom: 8px;
    }
  }
}

.footer-section-receive {
  border: 1px solid $lightGrayishRed;
  border-radius: 0px 0px 8px 8px;
  border-top: unset;
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .MuiDivider-root {
    width: 100%;
    color: $lightGrayishRed !important;
  }
}

.add-amount-note {
  margin: 24px 0px;
  width: 540px;
  .button-wrapper {
    padding: 15px 0px;
  }
}

.tab-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  width: 100%;

  .tab {
    display: flex;
    justify-content: center;
    width: 50%;
    text-align: center;
    padding: 15px;
    cursor: pointer;
  }

  .not-selected-tab {
    border-radius: 8px;
    border: 1px solid $lightGrayishRed;
    background: $white;
    margin-bottom: 8px;
  }
}

.amount-container {
  margin: 60px 0px;
  border: 1px solid $lightGrayishRed;
  background-color: $white;
}

.enter-amount-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $lightCyanBlue;
  background-color: $white !important;
  padding: 30px 40px;
  box-sizing: border-box;
  width: 100%;
}

@mixin amountBoxCommon() {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.enter-amount-box {
  width: 520px;
  position: relative;
  border-radius: 8px;
  margin: auto;
  background-color: $white;
  @include amountBoxCommon();
}

.amount-box {
  @include amountBoxCommon();
  width: 100%;

  .amount-box-edit-icon {
    height: 24px;
    width: 24px;
    color: $grayishBlue;
    position: absolute;
    right: 31px;
    cursor: pointer;
  }
}

.amount-input {
  margin-top: 8px;
  input {
    box-shadow: none !important;
    text-align: center;
  }
  &.input-element {
    .MuiInputBase-input.Mui-disabled {
      background-color: transparent;
      -webkit-text-fill-color: $darkIndigo;
    }
  }
}

.exchange-icon {
  color: $primaryBlue;
  transform: rotate(270deg);
  cursor: pointer;
}

.add-notes-options {
  margin-top: 24px;
  width: 520px;

  .add-note-btn {
    background-color: $paleGray;
    border: 1px solid $lightGrayishRed;
    padding: 10px 14px;
    border-radius: 4px;
    word-break: break-all;
    cursor: pointer;
  }
  .input-element {
    .MuiInputBase-root {
      width: 520px;
    }
  }
}

.send-modal-dialog-section {
  .MuiPaper-root {
    background-color: $paleGray;
  }
}

.send-modal-dialog {
  .send-modal-container {
    display: flex;
    justify-content: center;

    .send-modal-box {
      display: flex;
      justify-content: center;
      margin: 60px 0px;
      width: 600px;
      background: $white;
      border: 1px solid $lightCyanBlue;
      border-radius: 2px;
    }

    .send-modal-icon-text {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .send-error-section {
      background-color: $lightPink;
      width: 520px;
      border-radius: 8px;
      border: 1px solid $mistyRose;
      padding: 14px 20px;
      margin-top: 24px;

      .text-wrapper {
        color: $error;
      }
    }

    .send-amt-btn {
      width: 520px !important;
      margin: 24px 0px;
    }
    .send-amt-btn-red {
      background-color: $error !important;
    }

    .send-total-amount-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 520px;
      padding: 0px 20px;
      border-radius: 8px;
      background-color: $white;
      border: 1px solid $lightGrayishRed;
      margin-bottom: 24px;
      .MuiBox-root {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 12px;
      }
      .MuiDivider-root {
        border-color: $lightGrayishRed !important;
        width: 478px;
      }
    }

    .payment-failed-container {
      width: 520px;
      background-color: $paleGray;
      padding: 25px 24px 30px 24px;
      margin-top: 146px;
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 8px;
    }

    .payment-failed {
      width: 250px;
      margin-top: 20px;
    }

    .send-input-address {
      display: flex;
      margin: auto;
      width: 520px;
      height: 54px;
    }

    .textarea-send-modal {
      width: 520px !important;
      padding-top: 24px;

      .text-area {
        height: 78px !important;
        width: 100% !important;
      }
    }

    .input-element .send-input-address .MuiInputBase-input {
      padding: 17px 12px !important;
    }

    .currency-exchange-icon {
      width: 24px;
      height: 24px;
      margin: 4px 0;
    }
  }

  .total-amount-section {
    width: 520px;
    background-color: $paleGray;
    padding: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
  }
}

.entered-amount {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 40px;
  width: 520px;
  margin-top: 30px;
  cursor: pointer;

  .payment-address-name {
    z-index: 100;
  }

  .slick-slider {
    padding: 24px 40px;
    width: 420px;
    background-color: $white;
  }

  .qr-code {
    svg {
      height: 322px !important;
      width: 322px !important;
    }
  }
}

.tab {
  display: flex;
  justify-content: center;
  width: 50%;
  text-align: center;
  padding: 20px;
  cursor: pointer;
  .MuiTypography-root {
    line-height: 1.2 !important;
  }
}

.action-section {
  margin-top: 30px;
  width: 520px;
  display: flex;
  justify-content: space-around;
}

.actions {
  @include iconCss();
  cursor: pointer;
}

.actions-icon {
  @include iconRound();
  background-color: $white;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
}

.slick-list {
  svg:focus {
    outline: none;
  }
}

.wallet-address-section {
  display: flex;
  flex-direction: column;
  align-items: center;

  .wallet-receive-clipboard {
    width: 310px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .wallet-receive-text {
      text-align: center;
      padding-right: 4px;
      width: 100%;
    }
  }
}

.note-section {
  padding: 5px 16px;
  border-radius: 8px;
  background-color: $white;
  border: 1px solid $lightGrayishRed;
}
.account-fees-box {
  @include contentCenter();
  margin-top: 16px;
}

.text-wrap {
  word-wrap: break-word;
}

.wallet-payment-successfull {
  @include contentCenter();
  flex-direction: column;
  position: absolute;

  .wallet-payment-success-desc {
    color: $darkIndigo;
    margin-top: 14px;
    text-align: center;
    width: 500px;
    height: 96px;
    word-wrap: break-word;
  }
}

.payment-success-modal {
  .MuiDialog-container {
    .MuiPaper-root {
      @include contentCenter();
      flex-direction: column;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;

      svg {
        width: 110px !important;
        height: 110px !important;
        margin-bottom: 230px;
      }
    }
  }
}
.wallet-transaction-section {
  overflow: hidden;
  .wallet-transaction-container {
    .wallet-table,
    .vertical-table-grid {
      height: calc(100vh - 200px) !important;
      overflow: auto;
    }
    .transaction-vertical-data {
      .MuiTable-root {
        .MuiTableRow-root {
          vertical-align: top !important;
          .MuiTableCell-root .invoice-clipboard {
            align-items: self-start;
          }
        }
      }
    }
  }
}

.accounts-filter-wrapper {
  @include scrollbar();

  .MuiBackdrop-root {
    background-color: $veryDarkBlue;
  }

  .MuiDrawer-paperAnchorDockedRight {
    box-shadow: -4px 2px 26px 0px rgba(11, 37, 75, 0.08);
  }

  .filter-box-wrapper {
    width: 480px;
    height: 100%;
    min-width: 450px;

    .filter-header {
      display: flex;
      padding: 18px 35px;
      background-color: $paleGray;
      justify-content: space-between;
      align-items: center;
    }

    .filter-body {
      padding: 24px 30px;
      height: calc(100% - 175px);
      overflow: auto;

      .filter-country {
        .select.MuiOutlinedInput-root {
          .MuiSelect-outlined {
            padding: 9px 30px 9px 15px;
            font-family: $fontRegular;
          }
        }
      }

      .filter-balance,
      .filter-country,
      .filter-transaction-types,
      .filter-source-types {
        .input-with-dropdown-label {
          color: $greyText;
          font-size: $font14;
          margin-bottom: 14px;
          line-height: 1;
          font-weight: 500;
          letter-spacing: normal;
          font-family: $fontRegular;
        }

        .filter-value-input {
          &.end-value {
            margin-left: 14px;
          }

          .MuiInputLabel-root {
            font-family: $fontRegular;
            font-weight: 500;
          }

          .mask-input {
            box-sizing: border-box;
          }
        }
      }

      .filter-balance {
        .balance-value-box {
          display: flex;
        }
      }

      .filter-date {
        .picker-wrapper {
          display: flex;

          .account-date-picker {
            .custom-date-picker-textfield {
              margin-top: 10px;

              .MuiOutlinedInput-root {
                height: 42px;
                margin: 0px;
              }
            }
          }
        }
      }
    }

    .filter-footer {
      margin: 34px;
    }
  }
}

.redumption-failed-error-box {
  margin-bottom: 30px;
  width: 540px;
  height: 382px;
  background-color: $paleGray;
  border-radius: 8px;

  .redumption-error-box-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.buy-btc-container {
  display: flex;
  flex-wrap: wrap;
  min-height: 83vh;
  gap: 24px;
  padding-left: 35px;
  & .MuiCard-root {
    width: 350px;
    height: fit-content;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
  }
}

.send-wallet-recaptch {
  @include contentCenter();
  border-radius: 2px;
  flex-direction: column;
  width: 600px;
  background-color: $white;
  margin: auto;
}

.lightning-onchain-tab {
  border: 1px solid $lightGrayishRed;
  @include lightningOnchainTab();
}

.selected-tab {
  border: 1px solid $primaryBlue;
  @include lightningOnchainTab();
}

.bg-color-SATS,
.bg-color-BTC {
  @include bgColorWithBorder($lightOrange, $warmShadePeach);
}

.bg-color-USD,
.bg-color-USDT {
  @include bgColorWithBorder($aquaSpring, $paleBlueLily);
}

.receive-tabs-box {
  display: flex;
  text-align: center;
  align-items: center;
  margin: auto;
  justify-content: space-between;
  width: 520px;

  .MuiTypography-root {
    padding: 5px 0px;
  }
}

.receive-tabs > div:first-child {
  margin-right: 8px;
}

.under-maintenance-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $paleGray;

  .under-maintenance-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 480px;
    margin-top: 80px;
  }
}

.new-speed-wallet-wrapper {
  @include contentCenter();
  flex-direction: column;
  border-radius: 8px;
  margin: 8px 36px 10px 36px;
  height: 350px;
}

.amount-section {
  min-height: 185px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin: 8px 36px 0px 36px;
}

.settings-drawer {
  &.smallWidth {
    .MuiPaper-root {
      margin: 0 35%;
      border-radius: 6px;
    }
  }
}

.modal-item {
  background-color: $paleGray;
  .MuiToggleButton-root {
    padding: 7px;
    border-radius: 6px;
    width: 52%;
    &.Mui-selected {
      background-color: $primaryBlue;
      color: $white;
      border-left: 1px solid $gainsboro;
      border-radius: 6px;
    }
  }
}

.pending-transaction {
  justify-content: space-between;
  cursor: pointer;
}

.tune-round-icon {
  width: 24px;
  height: 24px;
  color: $grayishBlue;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  right: 0;
}

.receive-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  background-color: $white;
  border-radius: 8px;
  border: 1px solid $lightGrayishRed;

  .receive-details-address {
    width: 100%;
    padding: 0px 20px;
    margin-top: 12px;
  }
}

.blue-text {
  .MuiTypography-root {
    color: $primaryBlue;
  }
}

.send-amount-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $grayblue;
  border-radius: 2px;
  border: 1px solid $lightGrayishRed;
}

.send-amount-container {
  margin: 8px 0px;
  background-color: $white;
  border-radius: 8px;
  border: 1px solid $lightGrayishRed;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.02);
}

.send-amount-btn {
  width: 100%;
  background-color: $white;
}

.send-note-section {
  border-radius: 60px;
  background-color: $paleGray;
  display: flex;
  padding: 8px 16px;
  @include contentCenter();
  margin-bottom: 14px;
  width: 200px;
}

.send-add-note-btn {
  padding: 10px 14px;
  word-break: break-all;
  cursor: pointer;
}

.card-bg-img {
  position: absolute;
  top: 0;
  right: 0;
  mix-blend-mode: multiply;
  filter: brightness(0.97);
  opacity: 0.4;
  object-fit: "cover";
}
