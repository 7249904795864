@import "@speed/common/src/components/core.scss";

.main-content-initial-setup {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $paleGray;
    width: 100%;
    .guide-header {
        max-width: 1200px;
        width: 1200px;
        background: $white;
        box-shadow: 0px 4px 8px rgba(11, 37, 75, 0.06);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        padding: 40px 50px 0 50px;

        .header-text {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
        .MuiAccordionSummary-root {
            flex-direction: row-reverse;
            padding-top: 40px;
            .MuiAccordionSummary-content {
                    p {
                        font-size: 22px;
                        color: $darkIndigo;
                        line-height: 140%;
                        padding-left: 23px;
                    }
                }
            
            .MuiAccordionSummary-expandIconWrapper {
                svg {
                    height: 30px;
                    width: 30px;
                    color: $greyText !important;
                }
            }            
        }
    }

    .success-icon {
        color: $greenishTeal;
        height: 25px;
        width: 25px;
        margin-top: 3px;
    }

    .empty-icon {
        color: $greyText;
        height: 25px;
        width: 25px;
        margin-top: 3px;
    }

    .help-info {
        max-width: 1200px;
        width: 1200px;
        background: $white;
        box-shadow: 0px 4px 8px rgba(11, 37, 75, 0.06);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: left;
        padding: 10px 0 0 66px;
    }

    .show-previous-task {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: $white;
        padding: 16px 24px 16px 20px;
        cursor: pointer;

        .show-previous-task-expand-icon {
            height: 30px;
            width: 30px; 
            padding-right: 8px;
        }
    }

    .accordian-details {
        line-height: 140%;
        color: #848B9E;
        width: 700px;
    }

    .step-completed-loading {
        margin-top:40px;
        display:flex;
        flex-direction:row;
        align-items:center;
        width:700px;
    }

    .bold-text {
        color: $darkIndigo;
    }

    .desc-text {
        line-height: 160%;
        font-size: 16px;
        font-weight: 400;
        color: $greyText;
    }

    .desc-bold-text {
        color: $greyText;
    }
    .footer-content {
        padding: 32px 0px 60px 54px;
        width: 700px;
    }
}

@media only screen and (max-device-width: 1440px) {
    .main-content-initial-setup {
        .guide-header {
            width: 1000px;
        }
        .MuiLinearProgress-root {
            width: 900px !important;
        }
        .steps-border {
            width: 900px !important;
        }
        .help-info {
            width: 1000px !important;
        }
        .raw-html-embed {
            img {
                width: 800px;
            }
        }
    }
}
