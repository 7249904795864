@import "../core.scss";

.tag-wrapper {
  &.MuiChip-root {
    padding: 4px 7px 4px 8px;
    border-radius: 2px;
    height: 21px;

    &.default {
      background-color: #c4cada;
    }

    &.success {
      background-color: $greenishTeal;
    }

    &.warning {
      background-color: $goldenRod;
    }

    &.primary {
      background-color: #6b90ff;
    }
    &.success-res {
      background-color: $lightCyanBlue;
      padding: 5px 6px 4px;
      & .MuiChip-label {
        color: $darkIndigo;
      }
    }

    &.error {
      background-color: #ffeeec;
      padding: 5px 6px 4px;
      & .MuiChip-label {
        color: $paleRed;
      }
    }

    .MuiChip-label {
      padding: 0;
      font-family: $fontSemiBold;
      font-size: $font12;
      line-height: 1;
      text-align: center;
      color: $white;
    }

    &.error-primary{
      background-color: $paleRed;
    }
  }
}
