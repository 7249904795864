@import "../core.scss";

.checkbox-wrapper {
    &.MuiFormControlLabel-root {
        .MuiCheckbox-root {
            padding: 2px 13px 3px 9px;

            &.Mui-focusVisible {
                svg {
                    @include applyFocusBorder();
                }
            }
        }

        &.indeterminate-checkbox {
            svg {
                height: 30px;
                width: 30px;
                color: $greyText;
            }

            &.Mui-disabled {
                svg {
                    opacity: 0.3;
                }
            }
        }
    }
}