@import "../core.scss";

.select {
  &.MuiOutlinedInput-root {
    width: 100%;
    background-color: $white;
    box-sizing: initial;
    color: #0a193e;
    font-family: $fontMedium;
    @include boxShadow();

    &:focus-within {
      @include applyFocusBorder();
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    .MuiSelect-outlined {
      padding: 9px 4px 9px 15px;

      .MuiBox-root {
        color: $greyText;
        opacity: 0.8;
        font-family: $fontRegular;
      }
    }
  }
}

.MuiModal-root {
  &.MuiPopover-root {
    .MuiPopover-paper {
      max-height: 40vh;

      &.MuiPaper-root {
        .MuiList-root {
          padding: 10px !important;

          .MuiMenuItem-root {
            padding: 8px;
            border-radius: 5px;
            font-size: $font16;
            color: #0a193e;
            margin-bottom: 5px;

            &:focus,
            &:hover {
              background-color: $backgroundHover;
            }

            &.Mui-selected {
              background-color: $lightBlue;

              &:focus {
                background-color: $lightBlue;
              }
            }
          }
        }
      }
    }

    @include scrollbar();
  }
}

#date-format,
#gender-select,
#currency-select,
#branding-font-select,
#pay-via-select,
#branding-icon-select,
#custom-filter-select,
#amount-options,
#payout-wallet-address,
#cashback-type-select,
#settings_options,
#price-modal-dropdown,
#invoice-due-date-select,
#invoice-delivery-select,
#invoice-item-price-select,
#reminder-drop-down,
#reminder-send-down,
#custom-field-arr-types,
#custom-field-options,
#account-type-select,
#tolerence-select,
#filter-operator-menu,
#filter-source-type-menu,
#filter-transaction-type-menu,
#instant-payout-type,
#settings-notification-menu,
#node-types-drop-down,
#account-fees-drop-down,
#module-restriction-reason,
#instant-payout-asset,
#request-statement-asset,
#promo-code-select {
  .MuiPaper-root {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) {
  .MuiModal-root {
    &.MuiPopover-root {
      .MuiPopover-paper {
        margin-left: 177px;
      }
    }
  }
}