@import '../core.scss';

@mixin btnCommon() {
    border-radius: 4px;
    color: $darkIndigo;
    font-size: $font12;
}

.revealkey-wrapper {
    height: 25px;
    .MuiTypography-root.desc-text {
        color: $darkIndigo;
        padding-right: 5px;
    }

    .revealKey-text-wrapper{
        .MuiButton-default{
            div{
                padding-top: 3px;
            }
        }
        display: flex;
    }
    
    .revealkey-clipboard{
        padding: 5px 10px 0px 0px;
    }
    .revealkey-btn {
        padding: 5px 10px 22px 10px;
        background-color: $white;
        height: 26px;
        right:235px;
        bottom: 4px;
        @include btnCommon();
        &:hover {
            background-color: $white;
        }
    }
}
