@import '@speed/common/src/components/core.scss';
@import "@speed/common/src/assets/styles/common.scss";


.one-qr-wrapper {
    .custom-popper {
        z-index: 3;

        .MuiList-root {
            padding: 0;

            .MuiListItemButton-root {
                padding: 2px 35px 2px 16px;
            }

            .MuiListItem-root {

                &:focus,
                &:hover {
                    background-color: $backgroundHover;
                    border-radius: 4px;
                }
            }

            .MuiListItemText-root {
                .MuiTypography-root {
                    font-family: $fontMedium;
                    font-size: $font14;

                }

                &.text-grey {
                    color: $greyText
                }

                &.text-danger {
                    color: $paleRed;
                }

                &.text-blue {
                    color: $primaryBlue;
                }
            }
        }
    }

    .MuiTabPanel-root {
        padding: 0px;
    }

    .link-table .MuiTable-root {
        background-color: $white;
    }
}

.one-qr-detail-wrapper {
    .header-content {
        margin-top: 20px;
    }

    @include detailWrapper();

    .header-detail-content {
        margin-top: 9px;
        word-break: break-word;
    }

    .oneQR-detail {
        background-color: $paleGray;
        padding: 4px 14px;
        border-radius: 3px;
        color: $greyText !important;
    }

    .header-download-btn {
        text-align: right;

        .download-icon {
            font-family: $fontMedium;
            font-size: $font14;
        }
    }

    .one-qr-details-content {
        margin-top: 54px;

        .text-capitalize {
            text-transform: capitalize;
        }

        .vertical-table-wrapper .MuiTable-root .MuiTableCell-head {
            width: 151px;
        }
    }

    .last-payment-section {
        margin-top: 40px;

        .border-none {
            border: none !important;
        }
    }
}

.one-qr-preview-wrapper,
.view-qr-modal {
    .mobile-view-branding {
        height: auto;
        min-height: 490px;
        border-radius: 0%;
    }

    .diagonal-div {
        height: 354px;
        clip-path: polygon(0 0,
                /* left top */
                100% 0,
                /* right top */
                100% 100%,
                /* right bottom */
                0 60%
                /* left bottom */
            );

        &::before {
            opacity: 0.4;
            content: "";
            position: absolute;
        }
    }

    .diagonal-sub-div {
        margin-top: 212px;
        height: 170px;
        clip-path: polygon(0 0,100% 75%,100% 100%,0 11%);

    }

    .qr-preview-section {
        padding: 0 22px !important;
        background-color: #fffbfb;
        width: 90%;
        border-radius: 10px;
        margin: 18px;
        @include applyBorder($lightCyanBlue);

        .one-qr-preview-header {
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            @include applyBorder($lightCyanBlue);
            border-top: none;
            border-left: none;
            border-right: none;
        }

        .bit-coin-logo {
            margin-right: 10px;
        }

        .pay-logo {
            width: -webkit-fill-available;
            margin-top: 17px;
        }

        .pay-logo-section {
            margin-top: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .one-qr-preview-body {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

.view-qr-modal {
    &.modal {
        &.MuiDialog-root {
            .MuiDialog-paper {
                max-height: calc(100% - 47px);
            }
        }
    }


    .view-qr {
        text-align: center;
    }

    .mobile-view-header {
        height: auto;
        min-height: 469px;
        box-shadow: 3px 4px 14px 2px rgba(11, 37, 75, 0.12), 0 0 0 1px rgba(11, 37, 75, 0.08);
    }

    .download-btn {
        margin-top: 19px;
    }

    .qr-preview-section {
        height: auto;
    }

    .pay-logo-section {
        margin-top: 10px !important;
    }

    .qr-code-img-small {
        height: 200px !important;
        width: 200px !important;
    }

    .qr-code-img-large {
        height: 220px !important;
        width: 220px !important;
    }

    @include scrollbar();

    ::-webkit-scrollbar-track {
        box-shadow: none;
    }
}

.other-section{
    @include commonOverlayBox();
}

.one-qr-all-payment {
    padding: 20px 35px;
}

.one-qr-checkbox {
    .MuiFormControlLabel-label {
        font-family: $fontBold !important;
        color: $darkIndigo !important;
    }
}

.remove-cursor-pointer {
    cursor: unset !important;
}