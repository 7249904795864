@import "@speed/common/src/components/core.scss";

.payout-header {
  .hellobar-wrapper {
    background-color: $paleRed !important;
  }
}

.custom-payout-clipboard-input {
  & .MuiInputBase-root {
    border-radius: 4px;
    width: 600px;
    height: 40px;
    font-size: $font16;
    font-family: $fontMedium;
    & .MuiInputBase-input {
      border: 1px solid transparent;
      background-color: $paleGray;
      box-shadow: 0 0 0 1px rgba(11, 37, 75, 0.08);
    }
  }
}

.instant-payout-clipboard {
  position: absolute;
  right: 14px;
  .MuiSvgIcon-root {
    width: 20px !important;
    height: 20px !important;
  }
}

.table-row-status {
  .custom-payouts-status-label {
    svg {
      margin-right: 2px !important;
    }
  }
}

.instant-payout-select {
  &.auto-complete {
    width: 120px;
  }
}
.est-fees-box {
  padding: 24px;
  display: flex;
  flex-direction: column;
  background: $paleGray;
  border-radius: 8px;
  margin-top: 30px;

  .est-fees-row {
    display: flex;
    justify-content: space-between;

    .title-text {
      word-break: break-word;
      max-width: 290px;
    }   

    &.last {
      .title-text {
        font-family: $fontSemiBold;
      }
    }
  }
}

.mempool-url-box {
  @include applyBorder($lightGrey);
  width: 315px;
  padding: 5px 10px;
  border-radius: 3px;
}