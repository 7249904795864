@import '../core.scss';

.text-area {
    border-radius: 4px;
    position: relative;
    background-color: $white;
    font-size: $font16;
    font-family: $fontMedium;
    height: 20px;
    padding: 10px 12px;
    @include boxShadow();
    border: 3px solid transparent;
    color: $darkIndigo;

    &::placeholder {
      color: $greyText;
      opacity: 0.5;
      font-family: $fontRegular;
    }

    &:focus.text-area-error {
      @include applyBorder($theme: $paleRed);
    }

    &:focus {
      @include applyFocusBorder();
    }
}

.text-area-error {
    @include applyBorder($theme: $paleRed);
}

.text-area-disabled {
  background-color: $lightAliceBlue;
  opacity: none;
}

.text-area-stop-resize {
  resize: none;
}

.full-width {
  width: 100% !important;
}