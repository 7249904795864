@import "../../assets/styles/default.scss";

.text-wrapper {
  &.MuiTypography-root {
    line-height: 1.5;
    letter-spacing: normal;
    &.default-text {
      color: $darkIndigo;
    }
    &.grey-text {
      color: $greyText;
    }
    &.blue-text {
      color: $primaryBlue;
    }
    &.dark-indigo-text {
      color: $darkIndigo;
    }
    &.white-text {
      color: $white;
      background-color: $primaryBlue;
    }
    &.error-text {
      color: $error;
    }
    &.MuiTypography-h5 {
      font-family: $fontSemiBold;
    }
    &.font-semibold {
      font-family: $fontSemiBold;
    }
    &.font-bold {
      font-family: $fontBold;
    }
    &.font-medium {
      font-family: $fontMedium;
    }
    &.font-8 {
      font-size: $font8;
      .MuiSvgIcon-root {
        font-size: $font8;
      }
    }
    &.font-9 {
      font-size: $font9;
      .MuiSvgIcon-root {
        font-size: $font9;
      }
    }
    &.font-10 {
      font-size: $font10;
      .MuiSvgIcon-root {
        font-size: $font10;
      }
    }
    &.font-11 {
      font-size: $font11;
      .MuiSvgIcon-root {
        font-size: $font11;
      }
    }
    &.font-12 {
      font-size: $font12;
      .MuiSvgIcon-root {
        font-size: $font12;
      }
    }
    &.font-14 {
      font-size: $font14;
      .MuiSvgIcon-root {
        font-size: $font14;
      }
    }
    &.font-15 {
      font-size: $font15;
      .MuiSvgIcon-root {
        font-size: $font15;
      }
    }
    &.font-16 {
      font-size: $font16;
      .MuiSvgIcon-root {
        font-size: $font16;
      }
    }
    &.font-18 {
      font-size: $font18;
      .MuiSvgIcon-root {
        font-size: $font18;
      }
    }
    &.font-20 {
      font-size: $font20;
      .MuiSvgIcon-root {
        font-size: $font20;
      }
    }
    &.font-22 {
      font-size: $font22;
      .MuiSvgIcon-root {
        font-size: $font22;
      }
    }
    &.font-24 {
      font-size: $font24;
      .MuiSvgIcon-root {
        font-size: $font24;
      }
    }

    &.font-28 {
      font-size: $font28;
      .MuiSvgIcon-root {
        font-size: $font28;
      }
    }
    &.font-30 {
      font-size: $font30;
      .MuiSvgIcon-root {
        font-size: $font30;
      }
    }
    &.font-36 {
      font-size: $font36;
      .MuiSvgIcon-root {
        font-size: $font36;
      }
    }
    &.font-48 {
      font-size: $font48;

      .MuiSvgIcon-root {
        font-size: $font48;
      }
    }
    &.font-42 {
      font-size: $font42;
      .MuiSvgIcon-root {
        font-size: $font42;
      }
    }
    &.font-44 {
      font-size: $font44;
      .MuiSvgIcon-root {
        font-size: $font44;
      }
    }

    &.font-56 {
      font-size: $font56;

      .MuiSvgIcon-root {
        font-size: $font56;
      }
    }

    &.font-58 {
      font-size: $font58;

      .MuiSvgIcon-root {
        font-size: $font58;
      }
    }

    &.flex-text {
      display: flex;
      align-items: center;
    }

    &.start {
      .MuiSvgIcon-root {
        margin: 0 5px;
      }
    }
    .MuiSvgIcon-root {
      margin-left: 10px;
      color: $greyText;
    }
  }
}

@media only screen and (max-device-width: 1024px) {
  .MuiTypography-root {
    &.text-wrapper {
      &.font-16 {
        font-size: $font14;
        .MuiSvgIcon-root {
          font-size: $font14;
        }
      }
      &.font-28 {
        font-size: $font22;
        .MuiSvgIcon-root {
          font-size: $font22;
        }
      }
    }
  }
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .MuiTypography-root {
    &.text-wrapper {
      &.font-16 {
        font-size: $font14;
        .MuiSvgIcon-root {
          font-size: $font14;
        }
      }
      &.font-18 {
        font-size: $font16;
        .MuiSvgIcon-root {
          font-size: $font16;
        }
      }
      &.font-20 {
        font-size: $font18;
        .MuiSvgIcon-root {
          font-size: $font18;
        }
      }
      &.font-24 {
        font-size: $font20;
        .MuiSvgIcon-root {
          font-size: $font20;
        }
      }
      &.font-28 {
        font-size: $font24;
        .MuiSvgIcon-root {
          font-size: $font24;
        }
      }
    }
  }
}
