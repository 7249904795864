@import '@speed/common/src/components/core.scss';

.dashboard-wrapper {
    .first-row-wrapper {
        display: flex;
        margin-bottom: 27px
    }

    .second-row-wrapper {
        display: flex;
    }

    .dashboard-card {
        @include boxShadow();

        .MuiCardHeader-root {
            padding: 18px 25px;
        }

        .MuiCardContent-root {
            &.dashboard-chart-content {

                // --------- this code will be removed ---------//
                svg .linedraw {
                    animation: animate 3s ease forwards;
                    stroke-dasharray: 1000;
                    stroke-dashoffset: 1000;
                }

                svg .dotteddraw {
                    animation: animate 3s ease forwards;
                    stroke-dasharray: 1500;
                    stroke-dashoffset: 1500;
                }

                @keyframes animate {
                    to {
                        stroke-dashoffset: 0;
                    }
                }

                // ------------------------------------------------//
                .no-chart-available {
                    height: 3px;
                    width: 27px;
                    background-color: $greyText;
                    margin-left: 15px;
                }
            }
        }

        .MuiBox-root {
            .balance-card-text {
                padding-bottom: 12px;
                color: $greyText;
                line-height: 20px;
            }
            .balance-card-change-text {
                color: $darkIndigo;
                line-height: 20px;
                display: contents;
                font-size: $font13;
            }
            .balance-card-change-days {
                line-height: 20px;
                color: $greyText;
                display: contents;
            }
            .change-value-icon {
                margin-right: 2px;
                object-fit: contain;
                height: 22px;
                width: 22px;
            }
            .pending-payout-card-text {
                padding-bottom: 18px;
                color: $greyText;
                line-height: 20px;
            }
            .chart-loader-div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
                color: $darkIndigo;
                .chart-loader-image {
                    height: 80px;
                    width: 80px;
                }
            }
            .chart-container {
                margin: auto;
                height: inherit;

                .transaction-chart-loader {
                    height: inherit;
                    width: 90%;
                    margin-left: 8%;
                }
            }
        }
    }

    .link-table {
        max-height: 520px;
    }

    .truncate-text-lineheight {
        line-height: 15px;
    }

    .dashboard-title {
        line-height: 20px;
    }

    .no-data-card {
        position: absolute;
        padding: 20px 30px;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: $white;
        text-align: center;
        box-shadow: 0 4px 4px -2px rgba(11, 37, 75, 0.12), 0 0 0 1px rgba(11, 37, 75, 0.08);
        
        .no-data-title {
            line-height: 20px;
            color: $darkIndigo;
            font-weight: 600;
            text-align: center;
            margin-top: 14px;
        }

        .no-data-subtitle {
            line-height: 20px;
            color: $darkIndigo;
            text-align: center;
            font-weight: normal;
            margin-top: 4px;
            width: max-content;
        }
    }

    .dashboard-card:has(.no-data-card) {
        position: relative;
    }
}