@import '@speed/common/src/assets/styles/common.scss';

@mixin accountNameList($width) {
  .account-list {
    .account-list-box {
      max-width: $width  !important;
    }
  }
}

.connect-main-view-box {
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    width: 100%;
    background-color: $paleGray;
    min-height: 100vh;

    .connect-overlay-container {
        display: flex;
        align-items: center;
        flex-direction: column;

        .connect-web-content {
            width: 950px;
            margin-top: 80px;
            margin-left: 30px;
            margin-right: 30px;

            .connect-business-name {
                font-weight: 600;
                font-size: $font20 !important;
            }
            .business-name-box {
              max-width: 330px;
            }
            .connect-icon-style {
                width: 44px !important;
                height: 44px !important;
            }
            .connect-logo {
                width: 44px;
                height: 44px;
                border-radius: 22px;
                margin-right: 14px;
                svg {
                    width: 26.4px;
                    height: 26.4px;
                }
            }

            .connect-account-subtext {
                font-size: $font48;
                margin-top: 48px;
            }

            @include wrapperContent(44px,130px,87px,40px,87px,$font24, $font18, 54px,38px,0);
        }
    }
    
    &.connect-mobile-view {
        .vector-diagonal-div {
          height: 477px !important;
         }
         .vector-diagonal-sub-div {
           height: 210px !important; 
           margin-top: 299px !important;
         }

        .connect-mobile-content {
          margin: 19px 14px;
          width: 347px !important;
          height: 100%;
          position: relative;

          .connect-business-name {
            font-size: $font16 !important;
            font-weight: 600;
          }
          .business-name-box {
            max-width: 250px;
          }
          .connect-account-subtext {
            margin-top: 20px;
            font-size: $font24 !important;
          }

          .connect-icon-style {
            width: 30px !important;
            height: 30px !important;
          }
          .connect-logo {
            width: 30px;
            height: 30px;
            border-radius: 16px;
            margin-right: 8px;
            svg {
                width: 18px;
                height: 18px;
            }
          }

          @include wrapperContent(33px,0px,54px,24px,91px,$font16, $font14, 23px,33px,4px);
          .mobile-view-footer {
            margin: 0 !important;
            position: absolute;
            bottom: 0;
          }
        }
    }
}

.connect-user-box {
  border-radius: 4px;
  border: 1px solid #B5B9C5;
  background: $lightAliceBlue;
  padding: 16px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .MuiAvatar-root {
    width: 48px !important;
    height: 48px !important;
  }

  .name-text {
    font-size: $font18 !important;
    color: $darkIndigo;
    font-family: $fontSemiBold;
  }

  .email-text {
    font-size: $font14 !important;
    color: $greyText;
    font-family: $fontRegular;
  }
}

@media only screen and (max-width: 359px)  {
  .connect-main-view-box {
    &.connect-mobile-view {
      .connect-mobile-content {
        width: 250px !important;
      }
      .business-name-box {
        max-width: 150px !important;
        &.selected-account {
          max-width: 60px !important;
        }
      }
    } 
    .connect-2fa-box {
      .otp-input {
        width: 25px !important;
        height: 25px !important;
        margin-right: 8px !important;
      }
    }
  }
  .connect-user-box {
    .name-text-box, .email-text-box  {
     max-width: 80px !important;
    }
  }
  @include accountNameList(85px);
  .create-account-country {
    .MuiAutocomplete-root {
      .MuiFormControl-root {
        .MuiOutlinedInput-root {
          .MuiAutocomplete-input {
            padding-right: 18px  !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 540px)  {
  .connect-main-view-box {
    &.connect-mobile-view {
      .connect-mobile-content {
        .connect-account-subtext {
          font-size: $font20 !important;
        }
      }
    } 
  }
}

.account-loader-header {
  &.partition {
    width: 350px !important;
  }
}

.account-loader-sub-line {
  &.partition {
    width: 300px !important;
  }
}
@media only screen and (min-width: 360px) and (max-width: 540px)  {
  .connect-user-box {
    .name-text-box, .email-text-box  {
     max-width: 145px !important;
    }
  }
  @include accountNameList(200px);
}

@media only screen and (max-width: 940px)  {
  .connect-main-view-box {
    &.connect-mobile-view {
      .vector-diagonal-sub-div {
        margin-top: 405px !important;
     }
    } 
  }
}

@media only screen and (min-width: 360px) and (max-width: 539px) {
  .connect-main-view-box {
    &.connect-mobile-view {
      .connect-mobile-content {
        width: 330px !important;
      }
     .business-name-box {
      &.selected-account {
        max-width: 60px !important;
      }
     }
    } 
    .connect-2fa-box {
      .otp-input {
        width: 30px !important;
        height: 30px !important;
        margin-right: 8px !important;
      }
    }
  }
}

@media only screen and (min-width: 539px) and (max-width: 1023px)  {
  .connect-main-view-box {
    &.connect-mobile-view {
      .connect-mobile-content {
        width: 500px !important;
      }
      .business-name-box {
        &.selected-account {
          max-width: 120px !important;
        }
       }
    } 
  }
  .connect-user-box {
    .name-text-box, .email-text-box  {
     max-width: 280px !important;
    }
  }
  @include accountNameList(310px);
}

@media only screen and (min-width: 1024px)  {
  .connect-login, .connect-2fa-box {
    width: 600px;
  }
}

@media only screen and (max-width: 1023px)  {
  .account-list {
    width: unset !important;
  }
  .wrapper-header {
    height: 80px !important;
  }
  .content-wrapper-box {
    margin-bottom: 130px !important;
  }
  .account-loader-header {
    width: 220px !important;
    &.partition {
      width: 100px !important; 
    }
  }
  .account-loader-sub-line {
    width: 180px !important;
    &.partition {
      width: 80px !important; 
    }
  }
}


