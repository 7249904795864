@import "@speed/common/src/components/core.scss";

@mixin activeIcon {
  color: $white;
  margin-left: 5px;
  margin-right: 0;
  cursor: initial;
  height: 12px;
  width: 12px;

  &:hover {
    color: $white;
  }
}

@mixin paymentPageSettingsLabelStyle {
  font-size: $font16;
  font-weight: 500;
  line-height: 140%;
}

.close-account-grid {
  padding: 20px 30px 100px 27px;
}

.section-wrapper {
  height: 100%;

  &.two-factor-container {
    background-color: $paleGray;
    padding-left: 40px;
    padding-top: 37px;
  }

  .section-header {
    margin-bottom: 20px;
    font-family: $fontBold;
    font-size: $font18;
  }

  .item-wrapper {
    padding-left: 0px;

    .item-header {
      font-size: $font16;
      font-family: $fontSemiBold;
    }

    .item-subHeader {
      color: $greyText;
    }
  }

  .setting-header-wrapper {
    padding: 20px 35px;
  }

  .account-status-wrapper {
    .MuiTabs-root {
      .MuiButtonBase-root {
        &.Mui-selected {
          bottom: -2px;
        }
      }
    }

    .MuiTabPanel-root {
      background-color: $white;
    }
  }

  .associated-account-icon {
    width: 24px;
    height: 24px;
    padding: 2px 5px;
    margin: 2.5px 0px;
    border-radius: 15px;
    background-color: $greyText;
    color: $white;

    svg {
      font-size: $font14;
    }
  }

  .branding-upload-section {
    padding: 0px 35px;
    display: flex;
    @include uploadBrandingSectionMixin();
  }

  .team-2fa-auth-section {
    padding: 15px 35px 25px 35px;
    display: flex;

    .MuiSwitch-root {
      margin: 5px 20px 0px 0px;
    }

    .switch-sub-text {
      margin-top: 10px;
    }
  }
}

#branding-font-select {
  .MuiPaper-root {
    .MuiList-root {
      .MuiMenuItem-root {
        margin-bottom: 5px;

        &.Mui-selected:not(:hover) {
          background-color: $lightBlue;
        }
      }
    }
  }
}

.box-content {
  &.MuiBox-root {
    box-shadow: none;
    padding: 5px;
  }

  .list-item {
    padding: 8px;

    &.MuiTypography-root {
      &:hover {
        background-color: $backgroundHover;
        border-radius: 4px;
      }
    }
  }
}

.activated-2fa-container {
  height: 82px;
  padding: 18px 20px 17px;
  border-radius: 5px;
  @include boxShadow();
  background-color: $white;
  align-items: center;
  margin-bottom: 30px;
}

.payment-checkout {
  @include scrollbar();

  ::-webkit-scrollbar-track {
    box-shadow: none;
  }
}

.branding-button-toggle {
  position: absolute;
  right: 25px;
  top: 234px;
  margin-top: 75px;
  z-index: 2;
}

.user-team-wrapper {
  @include scrollbar();

  .MuiDialog-scrollPaper {
    .MuiPaper-elevation {
      .MuiDialogContent-root {
        padding: 0 !important;
        max-height: 590px;
      }

      .main-content {
        padding: 24px 30px;
        @include applyBorder($lightCyanBlue);
      }
    }
  }
}

.role-member-table {
  .MuiBackdrop-root {
    background-color: $white !important;
  }

  .MuiTableRow-root {
    &:nth-of-type(even) {
      background: $white;
    }

    &:hover {
      background-color: $paleGray;
    }

    .MuiTableCell-root {
      padding: 15px 15px 15px 30px;
      color: #848b9e;
    }
  }
}

@mixin girdBoxCss() {
  margin-top: 20px;
  width: 666px;
  height: auto;
  border-radius: 10px;
  box-shadow: 1px 2px 10px 0 rgba(11, 37, 75, 0.12),
    0 0 0 1px rgba(11, 37, 75, 0.08);
  background-color: $white;
}

// Custom Domain SCSS
.custom-domain-wrapper {
  @include girdBoxCss();

  .MuiListItem-root {
    height: auto;
    max-height: 175px;
    padding: 25px;
    border-bottom: 1px solid $lightCyanBlue;
  }

  .input-element {
    margin-top: 0;
  }

  .custom-domain-radio-label {
    .MuiFormControlLabel-label {
      font-size: $font20;
      font-family: $fontSemiBold;
    }
  }

  .MuiAlert-root {
    margin-top: 0;
    margin-bottom: 15px;
  }

  .verify-btn-grid {
    margin-left: 16px;
    display: flex;
    align-items: flex-start;
    margin-top: 28px;
  }

  .custom-popper {
    .MuiBox-root {
      padding: 16px;
      margin-left: 133px;
    }
  }

  .popper-link-content {
    &.MuiLink-root {
      &.small {
        font-size: $font12;
      }
    }
  }

  .custom-domain-active-icon {
    @include activeIcon();
  }

  &.tolerence-section-wrapper {
    .MuiListItem-root {
      border: none;
    }

    .on-chain-section-img {
      position: relative;
      margin-bottom: auto;
      top: 20px;
    }
  }

  &.payout-schedule-wrapper {
    width: 700px !important;
    margin-top: 0px !important;
    margin-bottom: 20px;

    .MuiListItem-root {
      padding: 30px !important;
    }
  }
}

// Custom Domain DNS records Modal scss
.dns-records-modal {
  .MuiDialogContent-root {
    padding-top: 0 !important;
  }

  .step-number-box {
    width: 26px;
    height: 26px;
    background-color: $lightCyanBlue;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
  }

  .dns-records-box {
    height: auto;
    max-height: 251px;
    padding: 14px 24px 18px 20px;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(11, 37, 75, 0.08);
    background-color: $paleGray;
    margin-top: 20px;

    .custom-domain-active-icon {
      @include activeIcon();
    }
  }
}

.email-wrapper {
  .custom-popper .MuiList-root {
    padding: 0px;
  }

  .custom-popper .MuiList-root .MuiListItemButton-root {
    padding: 2px 35px 2px 16px;
  }

  .MuiListItemText-primary {
    font-size: $font14;
  }

  .action-remove {
    color: $paleRed;
  }

  .MuiTabPanel-root {
    padding: 0px;
  }

  .preference-main-card {
    width: 666px;
    height: auto;
    margin-bottom: 30px;
    padding: 13px 20px 19px 20px;
    border-radius: 5px;
    box-shadow: 0 4px 4px -2px rgba(11, 37, 75, 0.12),
      0 0 0 1px rgba(11, 37, 75, 0.08);
    background-color: $white;
  }

  .card-body {
    display: flex;
    align-items: center;
    padding: 0px;
  }

  .card-text {
    color: $darkIndigo;
  }

  .update-email-address {
    margin-top: 19px;
    width: 215px;
    padding: 12px 17px;
  }

  .tag-wrapper.MuiChip-root .MuiChip-label {
    padding: 1px;
    line-height: 1.5;
  }

  .email-preference-info {
    text-decoration: none;
    color: $primaryBlue;
    cursor: pointer;
  }
}

//custom email domain

.add-domain-modal-wrapper {
  .modal-divider {
    margin: 10px -29px;
  }

  .add-domain-label {
    margin-bottom: 0px !important;
    margin-right: 10px;
    font-family: $fontRegular !important;
  }

  .domain-type {
    text-transform: uppercase;
    margin-bottom: 0px !important;
    color: $darkIndigo !important;
    margin-left: 7px;
  }

  .domain-content {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .add-domain-value {
    font-family: $fontRegular !important;
  }

  .domain-txt {
    .MuiInputBase-root {
      width: 455px;
      font-family: $fontMedium !important;
    }

    .MuiInputBase-input {
      background-color: $paleGray !important;
    }
  }

  .error-border {
    .MuiInputBase-input {
      border-color: #ffb7b0 !important;
    }
  }
}

.add-domain {
  .fixed-content {
    background-color: white !important;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    display: block !important;
  }

  .default-text {
    font-family: $fontRegular;
  }

  .alert-wrapper.MuiAlert-root {
    align-items: flex-start;
  }

  .instruction-msg {
    text-decoration: none;
    color: $primaryBlue;
  }
}

.remove-domain {
  .MuiLoadingButton-root {
    background-color: $paleRed !important;
  }
}

.confirmation-modal {
  .confirmation-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 173px;
  }
}

.add-email-domain {
  .error-msg-add-domain li {
    margin: 5px 0px;
  }

  .alert-wrapper.MuiAlert-root {
    align-items: flex-start;
  }
}

.sending-domain {
  .email-preference-info {
    text-decoration: none;
    color: $primaryBlue;
    cursor: pointer;
  }

  p:has(> .header-style) {
    width: 90%;
    word-break: break-word;
  }
}

.email-receipt {
  width: 462px;
  height: 392px;
  background-color: $paleGray;
  box-shadow: 0 4px 4px -2px rgba(11, 37, 75, 0.12),
    0 0 0 1px rgba(11, 37, 75, 0.08);
  border-radius: 5px;

  .email-receipt-header {
    height: 159px;
    border-radius: 5px 5px 0 0;

    .email-receipt-header-info {
      width: 250px;
      padding: 26px 0 0 10px;

      .receipt {
        padding-top: 7px;
        font-size: $font12;
        opacity: 0.5;
      }
    }

    .email-receipt-header-button {
      background-color: $white;
      position: relative;
      right: 170px;
      width: 156px;
      height: 30px;
      font-size: $font12;
      font-family: $fontRegular;

      .MuiButton-startIcon {
        color: $primaryBlue;
      }
    }
  }

  .email-receipt-body {
    background-color: $white;
    height: 240px;
    width: 420px;
    border-radius: 10px;
    margin: 0 21px 0;
    position: relative;
    bottom: 30px;
    color: #848b9e;

    .email-receipt-body-content {
      display: inline-flex;
      font-size: $font12;
      padding-top: 22px;
    }

    .email-receipt-body-summary {
      font-size: $font12;
      font-weight: "bold";
      font-family: "Inter";
      padding: 21px 0 0 27px;
    }

    .email-receipt-body-summary-box {
      background-color: $paleGray;
      width: 380px;
      height: 106px;
      margin: 12px 20px 0;
      border-radius: 5px;
      padding: 0 20px 0 20px;

      .summary-row {
        height: 51px;
        display: inline-flex;
        font-size: $font12;
        padding-top: 19px;
      }
    }
  }
}

.multi-email-tags-label {
  color: #848b9c;
  letter-spacing: normal;
  line-height: 1;
  font-style: normal;
  font-stretch: normal;
  font-weight: 500;
  font-family: Inter;
  opacity: 0.8;
  padding-bottom: 8px;
}

.users-and-teams {
  .MuiTabPanel-root {
    padding: 0px !important;
    background-color: $white;
  }

  .main-content {
    .MuiTableCell-root {
      padding-left: 43px;
    }
  }
}

.loader-wrapper {
  border-bottom: none !important;
}

.auto-payout-modal-content {
  flex-direction: column;
  @include contentCenter();
}

.dropdown-input-wrapper {
  .MuiBox-root {
    .MuiOutlinedInput-root {
      padding-top: 6px;

      svg {
        padding-top: 2px !important;
      }
    }
  }
}

.auto-payout-amount-input {
  border-radius: 4px;
  position: relative;
  font-size: $font16;
  font-family: $fontMedium;
  padding: 3px 12px;
  color: $darkIndigo;
  width: 100%;
  border: none;
  margin-left: 3px;

  &:focus {
    @include applyFocusBorder();
  }

  &::placeholder {
    color: $greyText;
    opacity: 0.5;
    font-family: $fontRegular;
  }
}

.auto-complete-payout .MuiIconButton-root {
  top: 0px !important;
  right: 5px !important;
  position: relative;
}

.auto-payout-switch {
  .MuiSwitch-root .MuiSwitch-track {
    background-color: $primaryBlue !important;
  }
}

.mt-20 {
  margin-top: 20px;
}

.payment-page-wrapper {
  @include scrollbar();

  ::-webkit-scrollbar-track {
    box-shadow: none;
  }

  height: 100%;
  overflow: auto;
  @include twoSectionPreview();

  .customization-form-wrapper {
    .currency-dropdown {
      width: 80%;

      .input-with-dropdown-label {
        @include paymentPageSettingsLabelStyle();
      }
    }

    .wallet-dropdown {
      width: 80%;
      margin-left: 30px;

      .MuiInputBase-input {
        max-width: 197px;
      }
    }

    .add-wallet {
      display: flex;
      margin-left: 30px;
      padding-top: 10px;
      cursor: pointer;
      width: 80%;
      border-top: 1px solid $lightCyanBlue;
    }

    .wallet-remove-icon {
      &.MuiButtonBase-root {
        &.MuiIconButton-root:hover {
          background-color: transparent;
        }
      }
    }

    .check-box {
      .MuiFormControlLabel-label {
        font-family: $fontSemiBold;
      }
    }

    .terms-privacy-box {
      .label {
        @include paymentPageSettingsLabelStyle();
      }
    }
  }

  .customization-preview {
    .link-select-dropdown {
      width: 68%;
      margin-top: 15px;
    }
  }
}

#display_amount_in_currency {
  .MuiPaper-root {
    left: 108px !important;
  }
}

// notification css

.notifications-tab {
  .MuiTabPanel-root {
    background-color: $white;
    padding: 0px;
  }

  .settings-notification-wrapper {
    .notification-msg {
      background-color: #fffae8;
      padding: 10px 40px;
    }

    .module-selection-wrapper {
      padding: 36px 40px;

      .module-checkbox-section {

        .module-header-box,
        .sub-module-header,
        .sub-module-event-box {
          display: flex;
          width: 100%;
          padding: 16px;
          align-items: center;
        }

        .module-header-box {
          margin-top: 30px;
        }

        .module-name {
          width: 60%;

          &.header-text {
            position: relative;
            right: 16px;
          }
        }

        .method-checkbox-section {
          width: 20%;
          display: grid;
          justify-items: center;

          .checkbox-wrapper {
            margin: 0;

            .MuiCheckbox-root {
              padding: 5px;

              svg {
                height: 18px;
                width: 18px;
              }
            }

            &.indeterminate-checkbox {
              .MuiCheckbox-root {
                padding: 3px;
              }

              svg {
                height: 22px;
                width: 22px;
              }
            }
          }
        }

        .sub-module-box {
          margin-bottom: 82px;

          .module-main-container-box {
            margin-top: 24px;

            &:first-child {
              margin-top: 10px;
            }

            .sub-module-header,
            .sub-module-event-box {
              @include applyBorder($lightCyanBlue);
            }

            .sub-module-header {
              background-color: $paleGray;
            }

            .sub-module-event-box {
              .sub-module-event-text {
                .email-trigger-frequency-box {
                  text-align: left;
                  width: 360px;
                  position: relative;
                  margin-top: 10px;
                  margin-right: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}

//payment method
.payment-method-wrapper {
  @include girdBoxCss();

  .payment-method-item {
    padding: 20px 50px;
    text-transform: capitalize;
  }

  .MuiSwitch-root {
    .Mui-checked+.MuiSwitch-track {
      background-color: $primaryBlue !important;
    }
  }

  .save-tolerence-btn {
    margin-left: 20px;
    margin-top: auto;

    .MuiButtonBase-root {
      padding: 12px 30px;
    }
  }

  .tolerence-main-box {
    .form-section {
      display: flex;
      justify-content: space-between;

      .btc-tolerance-autocomplete {
        width: 100%;
      }
    }
  }
}

.instant-send-container {
  background-color: $white;

  .instant-send-limits-box {
    margin: 24px 0;

    .instant-send-select {
      &.auto-complete {
        width: 120px;

        .MuiAutocomplete-root {
          .MuiOutlinedInput-root {
            border: 3px solid transparent !important;
          }
        }

        .hide-dropdown-arrow-icon {
          .MuiInputBase-root {
            .MuiOutlinedInput-input {
              text-align: center;
              padding-right: 15px !important;
            }
          }
        }
      }
    }

    &:first-child {
      margin-top: 0;
    }

    .divider {
      border: 1px solid $lightCyanBlue;
      height: 22px;
    }

    .instant-send-info-icon {
      position: relative;
      bottom: 2px;
      width: 18px;
      height: 18px;
      margin-left: 8px;
      color: $lavenderGrey;
      cursor: pointer;
    }
  }
}