@import "@speed/common/src/components/core.scss";

.balance-wrapper {
  .balance-main-card {
    width: 400px;
    height: 215px;
    margin-bottom: 30px;
    padding: 20px 20px 19px 17px;
    border-radius: 5px;
    box-shadow: 0 4px 4px -2px rgba(11, 37, 75, 0.12),
      0 0 0 1px rgba(11, 37, 75, 0.08);
    background-color: $white;
  }

  .card-body {
    display: flex;
    align-items: center;
    padding: 0px;
  }

  .card-icon {
    width: 30px;
    height: 30px;
    margin: 0 16px 0 0;
    color: $greyText;
    object-fit: contain;
  }

  .info-icon {
    width: 17px;
    height: 17px;
    object-fit: contain;
    color: $primaryBlue;
  }

  .card-sub-text {
    height: 21px;
    margin: 11px 11px 1px 46px;
    line-height: 1.05;
    color: $darkIndigo;
  }

  .card-gbp-text {
    height: 24px;
    margin: 9px 0px 0px 0px;
    line-height: 1.5;
    letter-spacing: normal;
    color: $greyText;
    font-family: $fontRegular !important;
  }

  .fiat-balnce-text {
    margin: 5px 11px 1px 46px;
    color: $greyText;
    font-family: $fontRegular !important;
  }


  & .balance-action-button {
    font-size: $font14;
    padding: 12px 15px;
  }
}

.transfer-details-wrapper {
  & .MuiTableCell-head {
    width: 220px !important;
  }
}

.transaction-custom-currency-dropdown {
  position: relative;
  top: 1px;

  & .select.MuiOutlinedInput-root {
    background-color: transparent;
    box-shadow: none;
    outline: none;
    color: $primaryBlue;
    font-weight: 600;

    & .MuiSelect-outlined {
      padding: 4px 32px 5px 0;
    }
  }
}