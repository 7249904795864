@import "../core.scss";

.pointer-cursor {
    cursor: pointer !important;
}

.vertical-stepper-box {
    .dot-circle {
        width: 25px;
        height: 25px;
        border-radius: 15px;
        background: $white;
        border: 1px solid $greyText;
    }
    .step-label {
        font-family: $fontMedium;
    }
    .MuiStepIcon-root {
        color: $white;
        border: 2px solid $greyText;
        border-radius: 15px;
    }
    
    .MuiStepLabel-iconContainer {
        padding-left: 0 !important;
        padding-right: 0 !important;
        @include contentCenter();
        width: 40px;
        .MuiSvgIcon-root {
            width: 30px;
            height: 30px;
            &.tick-icon {
                border-radius: 24px;
                color: $greenishTeal !important;
            }
        }
        &.Mui-active {
            .MuiSvgIcon-root {  
                border: unset !important;
            }
        }
        background: $lightAliceBlue;
        margin-left: 12px;
    }

    .first-element { 
        .MuiStepLabel-iconContainer {
            padding-top: 9px;
            border-top-left-radius: 40px;
            border-top-right-radius: 40px;
        }
        .MuiStepLabel-labelContainer {
            margin-top: 12px;
        }
    }
    .last-element { 
        .MuiStepLabel-iconContainer {
            padding-bottom: 16px;
            border-bottom-left-radius: 40px;
            border-bottom-right-radius: 40px;
        }
        &.MuiStepContent-root::before {
            border-bottom-left-radius: 40px;
            border-bottom-right-radius: 40px;
        }
        .MuiStepLabel-labelContainer {
            margin-top: -12px;
        }
    }
   
    .MuiStepLabel-root {
        position: relative;
        padding: 0;
    }

    .MuiStepContent-root {
        padding-left: 55px;
        padding-right: 8px;
        border: none;
        position: relative; 
    }
    .MuiStepContent-root::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 40px; 
        height: 100%;
        background-color: $lightAliceBlue; 
    }
    
    .MuiStepConnector-line {
        border: unset;
        width: 40px;
        background: $lightAliceBlue;
    }
    .MuiStepLabel-label {
        font-size: $font18; 
        font-family: $fontSemiBold;
        color: $darkIndigo !important;
        margin-left: 14px;
    }
    .sub-label-box {
        display: flex;
        margin-top: 24px;
        position: relative;
        align-items: center;
        .sub-header-label-bullet {
            width: 8px;
            height: 8px;
            border-radius: 5px;
            position: absolute;
            left: -40px;
        }
        .sub-header-label {
            margin-left: 32px
        }
    }
}
