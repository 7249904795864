@import '../core.scss';

.modal {
    &.MuiDialog-root {
        border-radius: 5px; 
        .MuiBackdrop-root {
            background-color: #b0b3b666;
        }

        .MuiDialog-paper {
            @include boxShadow();
        }

        .MuiDialogActions-root {
            padding: 30px;
            display: unset;
        }
        .MuiDialogContent-root {
            padding: 24px 30px;
        }
    }
    .MuiDialogTitle-root {
        font-family: $fontSemiBold;
        font-size: $font18;
        background-color: $paleGray;
        padding: 20px 30px;
        display: flex;
        align-items: center;
    }
    .MuiIconButton-root {
        top: 15px;
        right: 30px;
        position: absolute;
        color: $greyText,
    }
}
