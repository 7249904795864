@import "@speed/common/src/components/core.scss";

.create-cashback-modal {
    height: 100%;
    overflow-y: auto;
    display: flex;
    justify-content: center;

    .cashback-description-section {
        width: 600px;
        display: inline-table;
        padding-bottom: 20px;

    }

    .cashback-date-picker {
        .custom-date-picker-textfield {
            width: 100%;

            .MuiOutlinedInput-root {
                height: 50px;
                margin: 0px;
            }
        }
    }
}