@import '../core.scss';

.hellobar-wrapper {
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 25px;

    .MuiTypography-root.desc-text {
        grid-column-start: 2;
        margin: 12px 25px 12px 0;
        line-height: 1;
        .MuiSvgIcon-root, img {
            color: inherit;
            width: 20px;
            height: 20px;
            margin-right: 8px;
        }
    }

    .hellobar-btn {
        border-radius: 3px;
        padding: 6px 10px 6px 9px;
        background-color: $white;
        color: $primaryBlue;
        font-size: $font12;
        line-height: 1.33;

        &:hover {
            background-color: $white;
        }
    }

    .close-icon {
        margin-left: auto;
        color: $white;
        cursor: pointer;
    }

    .beside-component {
        .MuiSvgIcon-root {
            width: 20px;
            height: 20px;
            color:inherit
        }
    }
}
