@import '../core.scss';

.MuiTabs-root {
    min-height: 40px;
    .MuiButtonBase-root {
        padding: 10px 20px;
        border: solid 1px $lightCyanBlue;
        background-color: $white;
        margin: 0 9px 0px 0px;
        font-family: $fontSemiBold;
        font-size: $font14;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $greyText;
        border-bottom: none;
        align-items: center;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-color: transparent;
        text-transform: none;
        min-width: auto;
        min-height: 40px;
        &.Mui-selected {
            color: $darkIndigo;
            bottom: -1px;
            position: relative;
            background-color: $paleGray;
        }
    }
    .MuiTabs-indicator {
        display: none;
    }
    .MuiTabs-flexContainer {
        border-bottom: 1px solid $lightCyanBlue;
        padding-left: 42px;
        opacity: 0.9;
    }
}

.MuiTabPanel-root {
    background-color: $paleGray;
    color: $darkIndigo;
}