@import "@speed/common/src/components/core.scss";
@import "@speed/common/src/assets/styles/common.scss";

html,
body,
#root {
  height: 100%;
}

@mixin commonAuthGrid {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@mixin onboardingBtn {
  .onboarding-skip-btn,
  .onboarding-next-btn {
    top: 25px;
  }
}

@mixin switchAccountIcon {
  .switch-account-icon {
    width: 30px;
    height: 30px;
    margin: 0 10px 0 0;
    padding: 6px;
    border-radius: 15px;
    background-color: $greyText;
    color: $white;

    svg {
      font-size: $font18;
    }
  }
}

.app-side-preview-web {
  min-height: 779px !important;
  height: auto !important;
}

// create payment link preview and branding
.payment-web-preview {
  position: relative;
  z-index: 0;
  width: 800px;
  border-radius: 10px;
  box-shadow: 3px 4px 14px 2px rgba(11, 37, 75, 0.12),
    0 0 0 1px rgba(11, 37, 75, 0.08);
  background-color: $paleGray;
}

.submit-signin-btn {
  div {
    margin: 10px 5px !important;
  }

  &.social-media-login {
    img,
    svg {
      font-size: $font20 !important;
    }
  }
}

.wallet-title {
  font-size: 48px;
  width: 575px;
  margin-top: 24px;
  line-height: 120% !important;

  &::first-line {
    color: $primaryBlue;
  }
}

.link-icon-box {
  margin-bottom: 34px;

  .MuiSvgIcon-root {
    color: $greyText;
  }

  &.mobile-icon {
    .MuiSvgIcon-root {
      height: 44px;
      width: 44px;
    }

    @include iconBox(#eef3f6, 90px, 90px);
  }

  &.web-icon {
    .MuiSvgIcon-root {
      height: 62px;
      width: 62px;
    }

    @include iconBox(#eef3f6, 130px, 130px);
  }
}

.browser-view-branding {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  background-color: $white;

  .grid-sections {
    flex-basis: 50%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 50%;
  }

  .left-section {
    max-height: 100%;
    border-bottom-left-radius: 5px;
    padding: 26px;
    padding-bottom: 0px;
    position: relative;
  }

  .account-logo {
    width: 22px;
    height: 22px;
    margin: 0 7px 0 0;
    padding: 1px 0px 0px 4px;
    background-color: $white;
    border-radius: 15px;

    svg {
      font-size: $font14;
    }
  }

  ::-webkit-scrollbar-thumb {
    border: 5px solid transparent !important;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent !important;
  }

  .add-scroll {
    overflow: auto;
    height: 150px;
    margin-top: 25px;
  }

  .view-more-text-icon {
    margin-left: 3px !important;
    height: 16px;
    width: 16px;
  }

  .web-view-footer {
    width: 86%;
    margin-top: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 20px;

    .MuiTypography-root {
      svg {
        &.MuiSvgIcon-root {
          margin-left: 0px;
        }
      }
    }
  }
}

.preview-header {
  height: 40px;
  padding: 9px 20px 9px 10px;
  background-color: $lightCyanBlue;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .dot {
    width: 10px;
    height: 10px;
    margin: 6px 9px 6px 0;
    border-radius: 10px;
  }

  .domain-input {
    align-items: center;
    display: flex;
    height: 22px;
    padding: 0 11px;
    border-radius: 11px;
    background-color: $white;
  }

  &.mobile-preview-header {
    padding: 9px 24px 9px 19px;

    .domain-input {
      margin: 0;
    }
  }
}

.expire-icon-box {
  margin: 59px 0px 30px 0;
  @include iconBox(#f7f7f7, 130px, 130px);
}

.loader {
  @include commonLoader();
}

.closure-container {
  .action-btn-wrapper {
    top: 147px !important;
  }
}

.wl-actions {
  &.action-btn-wrapper {
    display: flex;

    .custom-popper {
      margin-top: 0px !important;

      .MuiListItemButton-root {
        padding: 2px 10px !important;
      }

      .popper-arrow {
        display: none,
      }
    }

    .import-wl-btn {
      width: 125px;

      .MuiButton-icon {
        .MuiSvgIcon-root {
          font-size: 24px !important;
        }
      }

      .expand-icon {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 80px;
      }
    }
  }
}

// Header

.header {
  .test-mode-box {
    width: 86px;
    height: 24px;
    border-bottom-left-radius: 5px;
    background-color: $sunFlowerYellow;
    position: fixed;
    right: 0;
    padding: 3px 14px;
    z-index: 3;
    top: 63px;
    font-size: $font12;
    font-family: $fontBold;
  }

  .test-mode-closure-box {
    top: 102px;
  }

  .revoke-btn {
    width: 86px;
    height: 30px;
    background-color: $white !important;
    color: $primaryBlue;
    font-size: $font14 !important;

    div {
      margin: 0 !important;
    }
  }

  &.test-mode {
    border-bottom: 2px solid $sunFlowerYellow;
  }

  &.user-in {
    .MuiContainer-root {
      > .MuiPaper-root {
        .MuiToolbar-root {
          .switch-label {
            margin-right: 0;

            .MuiTypography-root {
              font-size: $font12;
              color: $darkIndigo;
              font-family: $fontMedium;
              margin-right: 9px;
            }
          }
        }

        .MuiBadge-root {
          margin: 4px 25px 3px 0px;

          &:focus-visible {
            @include applyFocusBorder();
          }
        }
      }

      .header-account-select {
        &::before,
        &::after {
          border-bottom: none;
        }

        .MuiSelect-select {
          &:focus {
            background: none;
          }

          .MuiMenuItem-root {
            padding-left: 0;
            @include switchAccountIcon();

            &:hover {
              background: none;
            }

            .MuiTypography-root {
              max-width: 160px;
            }
          }
        }

        > .MuiSvgIcon-root {
          position: absolute;
          right: 7px;
          pointer-events: none;
        }
      }
    }
  }

  .header-switch {
    .MuiSwitch-root {
      .Mui-checked {
        &.Mui-disabled {
          + .MuiSwitch-track {
            opacity: 1;
            background-color: $sunFlowerYellow;
          }
        }
      }

      .Mui-disabled {
        & + .MuiSwitch-track {
          background-color: $lightCyanBlue;
        }
      }
    }
  }
}

.onboarding-qna {
  padding: 0 260px;
}

.onboarding-qna-mobile {
  width: auto !important;
  padding: 0px 20px;
}

.onboarding-footer {
  padding: 20px 40px 24px 40px;
  display: flex;
  height: 89px;
}

.onbaording-mobile-stepper {
  display: flex;
  width: 90%;

  .mobile-stepper .MuiLinearProgress-root {
    background-color: $lightCyanBlue !important;
  }
}

.onboarding-footer-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 42px;
}

.onboarding-box-layout {
  padding: 24px 40px;
  border-bottom: 1px solid $lightCyanBlue;
}

.onboarding-header {
  color: $white;
  padding-top: 44px;
  font-size: 58px !important;
  line-height: 124% !important;
  font-weight: 700;
}

.log-search-by-resource-id {
  border-radius: 4px;
  border: 1px solid $lightCyanBlue;
  background: $white;
  height: 36px;
  width: 343px;

  svg {
    margin-left: 10px;
  }
}

.onboarding-questions {
  padding: 24px 120px;
  overflow: auto;
  height: 355px;
  border-bottom: 1px solid $lightCyanBlue;

  .onboarding-question-text {
    font-family: $fontRegular !important;
    font-style: normal;
    font-weight: 600;
    line-height: 140% !important;
  }

  .answer-skeleton-wrapper {
    .question-skeleton,
    .answer-skeleton {
      border-radius: 8px;
      height: 27px !important;
    }

    .question-skeleton {
      width: 700px !important;
      background-color: #c4ccd2 !important;
    }

    .answer-skeleton {
      margin: 16px 0px;
      width: 450px !important;
      background-color: #eaeef1 !important;
    }
  }
}

.logs-vertical-data {
  .MuiTableBody-root {
    padding: 24px 0 12px 0 !important;
  }

  .MuiTableCell-root {
    padding: 0 0 18px 0 !important;
  }
}

.onboarding-options {
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  .input-element {
    padding-left: 30px;
  }
}

.selected-onboarding-option {
  .MuiFormControlLabel-label {
    font-weight: 600 !important;
  }
}

.onboarding-skip-btn {
  color: $greyText !important;
  padding: 6px 16px;
}

.onboarding-arrow {
  color: $greyText !important;
  padding: 0 !important;
  min-width: 0;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  margin: 0px 5px;
  box-shadow: none !important;

  &:hover {
    background-color: $aliceBlue;
  }
}

.onboarding-next-btn {
  padding: 10px 14px 10px 24px !important;

  div {
    margin: 2px !important;
  }
}

.pagination-onboarding {
  color: $greyText;
  font-family: $fontRegular;
  font-size: $font16;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
}

#header-account-select-menu {
  .MuiPopover-paper {
    max-height: 534px;
    width: 238px;
    top: 10px !important;
    margin-left: 0;
    box-shadow: 0 4px 7px 3px rgba(11, 37, 75, 0.12);
    @include applyBorder($lightCyanBlue);

    .MuiList-root {
      padding: 0px !important;

      .MuiMenuItem-root {
        &.Mui-selected {
          background-color: $lightBlue !important;
        }

        &.Mui-focusVisible,
        &.Mui-focused {
          background-color: $backgroundHover;
        }

        @include switchAccountIcon();
        height: 40px;
        margin-bottom: 10px;

        &.Mui-selected:hover {
          .MuiBox-root {
            max-width: calc(100% - 65px) !important;
          }

          .edit-account-icon {
            display: block;
            position: absolute;
            right: 10px;

            &:focus-visible {
              @include applyFocusBorder();
            }

            &:hover {
              color: $darkIndigo;
            }
          }
        }

        .MuiTypography-root {
          font-family: $fontMedium;
        }

        .edit-account-icon {
          display: none;
          color: $greyText;

          svg {
            display: block;
            font-size: $font16;
          }
        }

        &:last-child {
          margin-bottom: 5px;
        }
      }

      .add-new-acccount-box-wrapper {
        cursor: pointer;
        height: 56px;
        background-color: $paleGray;
        padding: 0;
        border-top: solid 1px $lightCyanBlue;
        margin-bottom: 0;

        .add-new-acccount-box {
          display: flex;
          align-items: center;
          padding: 9.5px 16px;
          margin: 3px;

          &:focus-visible {
            @include applyFocusBorder();
          }

          .add-new-account-icon {
            width: 30px;
            height: 30px;
            margin: 0 10px 0 0;
            padding: 4px 7px;
            border-radius: 15px;
            @include applyBorder(rgba(132, 139, 158, 0.5));

            svg {
              color: $greyText;
              font-size: $font14;
            }
          }
        }
      }
    }
  }

  .auto-complete {
    padding: 14px 5px 0px 11px;

    .MuiAutocomplete-root {
      padding: 0px 9px 0px 3px;
      outline: none;

      .MuiInputBase-root {
        box-shadow: 0 0 0 1px rgba(11, 37, 75, 0.08);
        border: none;
        padding: 5px 15px;
        border-radius: 4px;
        font-size: $font14;
        height: 42px;

        input {
          position: relative;
          top: 1px;
        }

        &.Mui-focused {
          border: none;
        }
      }
    }

    .MuiAutocomplete-popper {
      max-height: 400px;
      border: none;
      box-shadow: none;
      padding: 0px;
      overflow: auto;
      position: unset !important;
      transform: translate(0px, 0px) !important;
      margin: 10px 0px !important;
      width: 215px !important;

      .MuiPaper-root {
        ::-webkit-scrollbar-track {
          box-shadow: none;
        }

        .MuiAutocomplete-listbox {
          padding: 6px 6px 0px 0px;
          max-height: 400px;
        }

        .MuiAutocomplete-loading {
          padding: 10px 20px;
        }
      }
    }
  }
}

.onboarding-container-layout {
  background-color: $white;
  margin: 46px 0 0px !important;
  padding: 0;
  height: 552px;
  width: 1020px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
}

.revoke-header {
  background-color: $primaryBlue;
  display: flex;
  justify-content: center;
  padding: 9px 0;

  .mode-text {
    color: $white;
    font-family: $fontMedium;
  }
}

.test-mode-header {
  background-color: #fffbd5;
  display: flex;
  justify-content: center;
  padding: 9px 0;

  .mode-badge {
    padding: 3px 7px;
    border-radius: 3px;
    background-color: $sunFlowerYellow;
    margin-right: 15px;
    line-height: 1.5;
    font-family: $fontSemiBold;
    font-size: $font12;
    color: $darkIndigo;
  }

  .mode-text {
    color: #675d08;
    font-family: $fontMedium;
  }
}

// Signup screen
.sign-up {
  .checkbox {
    margin-top: 15px;
    width: 10%;
  }
}

.sign-up-offer-img {
  margin-left: 4px;
  vertical-align: middle;
}

.sign-up-invite-box {
  &.MuiPaper-root {
    margin: 0 3px 30px 2px;
    height: auto;
    min-height: 97px;
    width: 100%;
    padding: 15px 13px 19px 21px;
    border-radius: 5px;
    border: 1px solid $lightCyanBlue;
    background-color: $paleGray;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.sign-up-logo {
  height: 469px;
  width: 527px;
  margin: 67px 0px;
  object-fit: contain;
}

.sign-up-welcome-card {
  &.MuiGrid-root {
    .welcome-box {
      width: 538px;
      height: 522px;
    }

    .welcome-text {
      color: $white !important;
      line-height: 124%;
    }

    .welcome-subtext {
      color: $white !important;
      padding-top: 24px;
    }
  }
}

.signup-form {
  &.MuiGrid-root {
    padding: 50px 50px 30px 50px;
    width: 510px;
    height: auto;
    background: $white;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
  }
}

.signup-mobile-form {
  background: $white;
  padding: 50px 40px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
}

.quick-question-header {
  font-family: $fontRegular !important;
  font-style: normal;
  font-weight: 700;
  line-height: 140% !important;
}

.info-popper-icon {
  &.MuiSvgIcon-root {
    height: 17px;
    width: 17px;
    margin-right: 10px;
    margin-top: 3px;
  }
}

.custom-divider {
  margin: 30px 0px;
  font-weight: 500;
}

.margin-top20 {
  margin-top: 20px;
}

.margin-top27 {
  margin-top: 27px;
}

.margin-right10 {
  margin-right: 10px;
}

.margin-bottom12 {
  margin-bottom: 12px;
}

.error {
  color: $paleRed;
}

.goback-btn {
  box-shadow: none !important;
  color: $primaryBlue;
}

.goback-btn:hover {
  background-color: transparent;
}

.notification-box {
  &.MuiSnackbar-root {
    .MuiSnackbarContent-root {
      background-color: $sunFlowerYellow;
      color: $darkIndigo;
    }
  }
}

// Sign In

.user-box {
  margin-top: 35px;
  @include boxShadow();
  height: 68px;
  padding: 14px 16px 16px 15px;
  border-radius: 4px;
  background: #f2f5f7;

  .user-profile-image {
    &.MuiAvatar-root {
      width: 35px;
      height: 35px;
    }
  }

  .edit-icon-box {
    display: flex;
    justify-content: flex-end;
    margin-left: 18px;
    cursor: pointer;

    .edit-icon {
      height: 16.69px;
      width: 16.69px;
      opacity: 0.25;
      color: $greyText;

      &:focus {
        @include applyFocusBorder();
      }
    }
  }
}

.signin-button-text {
  position: relative;
  right: 11px;
}

// Reset Password
.password-policy-block {
  margin: 30px 0;
  padding: 11px 52px 16px 19px;
  border-radius: 4px;

  &.reset {
    background-color: $paleGray;
  }

  &.change {
    background-color: $lightCyanBlue;
  }

  &.change-password {
    background-color: #eef1f5;
  }

  .header-text {
    padding: 5px 0px;
  }
}

.verify-email-box {
  background: $white;
  padding: 50px 40px;
  top: 100px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
}

.main-wrapper-container {
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
}

.check-icon {
  color: $greenishTeal !important;
  height: 60px;
  width: 60px;
  margin-left: 4px !important;
  margin-right: 0px !important;
  margin-bottom: 24px;
  margin-top: 12px;
}

.closure-modal {
  .MuiPaper-elevation {
    width: 363px;
  }
}

.check-email-box {
  text-align: center;
  flex-direction: column;
  @include contentCenter();

  .text {
    margin-top: 23px;
  }
}

.closure-box {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  .text {
    margin-top: 10px;
  }
}

.check-email-icon {
  object-fit: contain;
  width: 193px;
  height: 170px;
  margin-bottom: 45px;
}

.email-sent-icon {
  object-fit: contain;
  width: 193px;
  height: 170px;
  margin: 0;
}

//verify otp modal
.verify-otp-modal {
  .otp-input {
    &[aria-label="Digit 4"] {
      margin-left: 0px !important;
    }
  }
}

//Account Restricted modal
.account-restricted-modal {
  .MuiPaper-root {
    max-width: 485px;

    .error-icon {
      height: 60px;
      width: 60px;
      margin-bottom: 15px;
      color: $paleRed;
    }
  }
}

// Two Factor Authentication Form

.two-auth-grid {
  @include commonAuthGrid();
}

.two-fa-grid {
  @include commonAuthGrid();
}

.expired-2fa-otp,
.expired-email-otp {
  color: $paleRed !important;
}

.need-help-grid {
  background-color: $paleGray;
  @include contentCenter();
  padding: 14px;

  span {
    font-family: $fontRegular;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
  }
}

.two-auth-setup {
  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
    margin-right: 10px !important;
  }

  cursor: pointer;
}

.horizontal-dots-icon {
  color: $greyText;

  &:focus {
    @include applyFocusBorder();
  }
}

.margin-transfer-ownership {
  margin: 20px 33px;
}

// vertical table

.vertical-table-wrapper {
  .MuiTable-root {
    .MuiTableRow-root {
      word-break: break-word;
    }

    .MuiTableCell-head {
      width: auto;
      min-width: 185px;
      color: $greyText;
      padding-left: 0px;
    }

    .MuiTableCell-root {
      border: none;
      font-size: $font16;
      padding: 13px 14px 13px 0px;
    }
  }
}

.horizontal-table-wrapper {
  .MuiTableContainer-root {
    .MuiTable-root {
      .MuiTableCell-head {
        font-size: $font14;
        background-color: $white;
        font-family: $fontRegular;
        color: $greyText;
        padding: 6px 15px;

        &:nth-child(1) {
          padding-left: 0px;
          width: 15vh;
        }
      }

      .MuiTableBody-root {
        border-bottom: 1px solid $lightCyanBlue;

        .MuiTableRow-root {
          background-color: $white;

          .MuiTableCell-root {
            border: none;
            letter-spacing: normal;
            padding: 18px 15px 13px 15px;

            &:nth-child(1) {
              padding-left: 0px;
            }
          }
        }
      }
    }
  }

  .MuiTablePagination-root {
    display: contents;

    .MuiToolbar-root {
      padding-right: 15px;

      .MuiTablePagination-actions {
        .MuiButtonBase-root {
          width: 53px;
          height: 28px;
          padding: 8px 13px;
          border-radius: 3px;
          font-size: $font12;
          font-family: $fontMedium;
          @include boxShadow();

          &:first-child {
            margin: 0px 12px 0px 0px;

            &::after {
              content: "Prev";
            }
          }

          &:last-child {
            &::before {
              content: "Next";
            }
          }

          svg {
            display: none;
          }
        }
      }
    }
  }
}

.emil-password-screen {
  .MuiCollapse-root {
    width: 100% !important;
    min-width: 0px;
    transition-duration: 300ms;
  }
}

.signup-desc {
  line-height: 2 !important;
}

.lineHeight {
  line-height: 170% !important;
}

.email-verify-text {
  color: #667085 !important;
}

.limited-offer-box {
  display: inline;
  width: 132.5px;
  height: 25px;
  background: $sunFlowerYellow;
  border-radius: 4px;
  clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 10% 100%, 0% 50%, 10% 0);
  padding: 2px 8px 4px 2px;
}

.mobile-stepper {
  .MuiLinearProgress-root {
    border-radius: 80px;
    height: 8px;
    width: 100%;
    background-color: $paleGray;
  }

  .MuiLinearProgress-bar {
    background-color: $greenishTeal;
  }
}

// Global search detail page
.search-details-main-wrapper {
  .search-result-for-header {
    padding: 30px 30px 30px 35px;

    .search-result-for-txt {
      display: flex;

      .result-search-text {
        max-width: calc(100% - 235px);
      }
    }
  }

  .result-content {
    padding: 0px 31px 30px 35px;

    &.module-results {
      padding: 0;

      .MuiTable-root {
        .MuiTableRow-root {
          .MuiTableCell-root:nth-child(1) {
            padding-left: 38px;
          }
        }
      }
    }

    .web-page-result-wrapper {
      margin: 0px 0px 52px 5px;

      .web-page-result {
        padding: 0;

        .MuiListItemButton-root {
          padding: 9px 0px;

          &:hover {
            background-color: $aliceBlue;
          }

          .MuiListItemIcon-root {
            min-width: 35px;

            .MuiSvgIcon-root {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }

    .module-result-wrapper {
      margin: 0px 0px 52px 5px;

      .MuiTable-root {
        .MuiTableHead-root {
          .MuiTableCell-root {
            font-family: $fontSemiBold;
            font-weight: 600;
            color: $darkIndigo;
          }
        }

        .MuiTableBody-root {
          .MuiTableRow-root {
            border-bottom: 1px solid $lightCyanBlue;
            text-decoration: none;
            height: 50px;

            &:hover {
              background-color: $aliceBlue;
            }

            .MuiTableCell-root {
              padding: 10px 15px;

              &:nth-child(1) {
                padding-left: 0 !important;
              }

              .search-bar-link {
                .MuiInputBase-root {
                  width: 250px;
                }
              }
            }
          }
        }
      }
    }

    .custom-popper {
      .MuiList-root {
        padding: 0;

        .MuiListItemButton-root {
          padding: 2px 35px 2px 16px;

          .MuiListItemText-root {
            &.text-danger {
              color: $paleRed;
            }

            &.text-blue {
              color: $primaryBlue;
            }

            .MuiListItemText-root {
              .MuiTypography-root {
                font-family: $fontMedium;
                font-size: $font14;
              }
            }
          }
        }
      }
    }
  }

  &.no-result {
    height: calc(100% - 50px);

    .result-content {
      height: inherit;

      .no-result-found-wrapper {
        width: 100%;
        display: grid;
        height: 80%;
        justify-content: center;
        align-items: center;

        .not-found {
          padding: 0 !important;
        }
      }
    }
  }
}

.confirm-page-title {
  font-size: $font16 !important;
}

.confirm-page-subtitle,
.grand-payment-box-text {
  font-size: $font12 !important;
}

// Loader

.MuiSkeleton-root {
  background-color: #e8ecef;
}

// Connect Branding
.connect-wrapper {
  .MuiTabPanel-root {
    background-color: unset !important;
    padding: 0 !important;
  }

  .connect-branding-wrapper {
    @include scrollbar();

    ::-webkit-scrollbar-track {
      box-shadow: none;
    }

    overflow: auto;
    @include twoSectionPreview();

    .modal-container-grid {
      padding-left: 0 !important;
    }

    .connect-branding-box {
      @include twoSectionPreview();
      padding: 36px 24px 290px 35px;

      .custom-label-style {
        .MuiInputLabel-root {
          font-size: $font16;
        }
      }

      .label-formatting {
        .MuiTypography-root {
          font-size: $font16;
          font-family: $fontMedium;
        }
      }

      @include uploadBrandingSectionMixin();

      .upload-brand-details {
        margin-top: 30px;

        .box-icon {
          justify-content: unset !important;
        }
      }
    }

    .connect-branding-preview {
      padding-right: 15px !important;
      padding-bottom: 100px !important;

      .payment-web-preview {
        width: 788px !important;
      }

      .connect-main-view-box {
        min-height: 630px;
        height: auto;
        background-color: $white;
        border-radius: 4px;

        .vector-diagonal-div {
          height: 437px !important;
        }

        .vector-diagonal-sub-div {
          height: 190px !important;
          clip-path: polygon(0 85%, 100% 0, 100% 8%, 0 100%) !important;
          margin-top: 276px !important;
          opacity: 0.2 !important;
        }

        .connect-overlay-container {
          .connect-web-content {
            width: 677px !important;
            margin-top: 53px !important;

            .connect-account-subtext {
              font-size: $font28 !important;
              margin-top: 24px;
            }

            @include wrapperContent(
              30px,
              42px,
              46px,
              20px,
              72px,
              $font16,
              $font14,
              32px,
              38px,
              4px,
              457px
            );

            .connect-login {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
          }
        }
      }
    }
  }
}

// Connected Extensions
.connected-extensions-box {
  .custom-popper {
    .MuiList-root {
      padding: 0;

      .MuiListItemButton-root {
        padding: 4px 10px;

        .MuiListItemText-root {
          &.text-danger {
            color: $paleRed;
          }

          .MuiTypography-root {
            font-family: $fontMedium;
            font-size: $font14;
          }
        }
      }
    }
  }
}

.payout-setup-modal-wrapper {
  display: flex;
  height: 100%;

  .payout-left-section {
    width: 462px;
    padding: 36px 60px;
  }

  .payout-right-section {
    margin: 51px 40px;
    width: 700px;
  }
}

.auto-payout-wrapper {
  &.setup-verify-payout-wallet,
  &.auto-pay-schedule {
    .custom-popper {
      .MuiBox-root {
        padding: 0;
        min-width: 66px;
      }
    }
  }

  .custom-domain-wrapper {
    .MuiListItem-root {
      border-bottom: none !important;
    }
  }
}

@media only screen and (max-device-width: 1024px) {
  .info-popper-icon {
    &.MuiSvgIcon-root {
      height: 12px;
      width: 12px;
    }
  }

  .submit-signin-btn {
    div {
      margin: 4px 5px !important;
    }
  }
}

@media only screen and (max-device-width: 540px) {
  .onbaording-mobile-stepper {
    width: 100% !important;
    flex-direction: row-reverse;
  }

  .onboarding-footer-button {
    width: 100% !important;
    flex-direction: column-reverse;
  }

  .onboarding-footer {
    display: block !important;
    height: 175px !important;
    align-items: center;
  }

  @include onboardingBtn();
}

@media only screen and (min-device-width: 540px) {
  .sign-up {
    .checkbox {
      margin-top: 30px;
      width: 33px;
    }
  }
}

@media only screen and (min-device-width: 398px) and (max-device-width: 430px) {
  .limited-offer-box {
    display: block;
    width: 143.5px;
    height: 32px;
  }
}

@media only screen and (min-device-width: 650px) and (max-device-width: 703px) {
  .limited-offer-box {
    display: block;
    width: 143.5px;
    height: 32px;
  }
}

@media only screen and (min-device-width: 703px) and (max-device-width: 940px) {
  .onboarding-header {
    line-height: 140% !important;
  }

  .onboarding-qna-mobile {
    padding: 30px 50px 0 50px !important;
  }

  .onboarding-container-layout {
    width: auto !important;
    height: auto !important;
  }

  .onboarding-questions {
    height: 414px !important;
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 560px) {
  .sign-up {
    &.MuiGrid-root {
      padding: 0 20px 40px 20px;
    }
  }

  .onboarding-skip-btn {
    padding: 24px !important;
    font-weight: 600;
    line-height: 160% !important;
  }

  .quick-question-header {
    font-style: normal;
    font-weight: 700;
    line-height: 140% !important;
    font-size: 22px !important;
  }

  .common-mobile-grid {
    padding: 24px !important;
    display: block !important;
  }

  .verify-email-box {
    max-width: 190% !important;
  }

  .sign-up-logo {
    width: 300px;
    height: 267.4px;
    margin: 31px 37px 50.6px 38px;
  }

  .signup-form {
    &.MuiGrid-root {
      padding-left: 0;
      justify-content: flex-start;
    }
  }

  .sign-up-welcome-card {
    &.MuiGrid-root {
      border-right: unset;
      display: flex;
      align-items: center;
      padding-right: 0;
    }
  }

  .common-grid-wrapper {
    .email-password-grid {
      .custom-divider {
        margin: 54px 0px;
      }
    }
  }

  .onboarding-box-layout {
    padding: 24px !important;
    width: 100%;
  }

  .onboarding-questions {
    padding: 24px !important;
    max-height: 100% !important;
    border-bottom: none !important;
    height: 325px !important;

    .answer-skeleton-wrapper {
      .question-skeleton {
        width: 85% !important;
      }

      .answer-skeleton {
        width: 50% !important;
      }
    }
  }

  .onboarding-container-layout {
    margin: 30px 0 58px !important;
    height: 643px !important;
    width: auto !important;
  }

  .onboarding-header {
    font-size: 24px !important;
    padding-top: 30px !important;
    font-weight: 700 !important;
    line-height: 124% !important;
  }

  .onbaording-mobile-stepper {
    width: 100% !important;
    flex-direction: row-reverse;
  }

  .onboarding-footer-button {
    padding-top: 122px;
    width: 100% !important;
    flex-direction: column-reverse;
  }

  .onboarding-footer {
    display: block !important;
    height: 210px !important;
    padding: 24px !important;
  }

  @include onboardingBtn();
}

@media only screen and (min-device-width: 560px) and (max-device-width: 940px) and (-webkit-min-device-pixel-ratio: 1) {
  .sign-up {
    &.MuiGrid-root {
      padding: 5% 20% 20%;
    }
  }

  .onboarding-header {
    font-size: 40px !important;
    padding-top: 30px !important;
  }

  .onboarding-questions {
    padding: 20px 40px !important;

    .answer-skeleton-wrapper {
      .question-skeleton {
        width: 85% !important;
      }

      .answer-skeleton {
        width: 50% !important;
      }
    }
  }
}

@media only screen and (min-device-width: 940px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .sign-up-welcome-card {
    padding-left: 7% !important;
  }

  .signup-form {
    width: 400px !important;
  }

  .welcome-text {
    font-size: 50px;
  }
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1000px) and (-webkit-min-device-pixel-ratio: 1) {
  .header {
    .header-logo {
      width: 104px;
      height: 27px;
    }

    &.user-in {
      .MuiContainer-root {
        padding: 0;

        .MuiPaper-root .MuiBadge-root {
          margin: 4px 20px 3px 0px;
        }
      }
    }
  }

  .onboarding-footer-button {
    width: 86% !important;
  }
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  // Signup screen
  .sign-up-logo {
    object-fit: contain;
    height: 200px;
    width: 327px;
    margin: 47px 0px;
  }

  .sign-up-welcome-card {
    &.MuiGrid-root {
      padding: 0px 47px 0px 0px;
      max-width: 50%;
      justify-content: center;
    }

    .MuiTypography-root {
      &.text-wrapper {
        &.font-16 {
          text-align: center;
        }
      }
    }

    .welcome-box {
      width: 285px;
    }
  }

  .signup-form {
    &.MuiGrid-root {
      max-width: 50%;
      justify-content: flex-start;
      display: flex;
      padding-left: 49px;
    }

    .MuiTypography-root {
      &.privacy-policy-text {
        width: 87%;
      }
    }
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  // Signup screen
  .sign-up-welcome-card {
    &.MuiGrid-root {
      padding-right: 57px;
      justify-content: center;
    }

    .MuiTypography-root {
      &.text-wrapper {
        &.font-16 {
          text-align: center;
        }
      }
    }
  }

  .sign-up-logo {
    object-fit: contain;
    height: 279px;
    width: 327px;
    margin: 57px 0px;
  }

  .signup-form {
    &.MuiGrid-root {
      padding-left: 49px;
    }

    .MuiTypography-root {
      &.privacy-policy-text {
        width: 91%;
      }
    }
  }
}

// screen container
.box-container {
  background-color: $paleGray;
  padding: 30px 30px 120px 40px;
  min-height: 100vh;

  .user-image-box {
    display: flex;
    align-items: center;

    .user-image-desc {
      padding-left: 20px;

      .input-element {
        display: none;
      }
    }
  }
}

.mainMenuAccordion,
.subMenuAccordion {
  .MuiAccordion-region {
    .MuiButtonBase-root {
      padding-left: 9px !important;
    }

    .MuiTypography-root {
      padding-left: 36px;
    }
  }

  .MuiAccordionSummary-content {
    .MuiSvgIcon-root {
      margin: 0px 12px 0px 9px !important;
    }
  }
}

.mainMenuAccordion .MuiAccordion-region .MuiListItemButton-root {
  height: 34px !important;
}

.mainMenuAccordion .MuiCollapse-wrapperInner {
  padding-top: 8px;
}

.mainMenuAccordion .MuiListItem-root {
  padding-bottom: 2px !important;
}

.subMenuAccordion {
  padding-bottom: 4px;
}

.main-content {
  position: relative;
  background-color: $white;
}

.inside-loader {
  position: absolute;
  background-color: transparent;

  .MuiPaper-root {
    box-shadow: none;
  }
}

.height-100 {
  height: 100vh;
  padding-bottom: 18px;
}

.no-data-available-text {
  color: $greyText;
  padding-top: 13px;
}

.status-icon,
.status-icon:hover {
  color: $white !important;
  height: 12px;
  width: 12px;
  margin-left: 4px !important;
  margin-right: 0px !important;
}

.payments-status-tooltip {
  .MuiTooltip-tooltip {
    font-size: $font14 !important;
  }
}

.react-multi-email {
  box-shadow: 0 4px 4px -2px rgba(11, 37, 75, 0.12),
    0 0 0 1px rgba(11, 37, 75, 0.08);
  border: none !important;
  border-radius: 4px !important;
  min-height: 42px;

  span[data-placeholder] {
    padding: 5px 0 11px 10px;
    font-size: $font16 !important;
  }

  > input {
    font-size: $font16;
    margin: 0 0 0 5px;
  }
}

.email-multi-tags {
  padding: 5px 5px 5px 14px !important;
  height: 26px;
  border-radius: 13px !important;
  background-color: #eff2f5 !important;

  .email-multi-tag-data {
    letter-spacing: normal;
    color: $darkIndigo !important;
    font-family: $fontRegular;
    font-size: $font14 !important;
    font-weight: normal !important;
    height: 14px;
    line-height: 1;
    font-stretch: normal;
    font-style: normal;
    margin: 0 4px 2px 0;
  }
}

.invite-warn-icon {
  width: 60px;
  height: 60px;
  margin: 5px 155px 15px;
  color: $brinkPink;
}

.logo-with-footer {
  display: flex;
  margin-bottom: 16px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.account-divider {
  &.MuiDivider-root {
    padding: 12px 0px;
  }

  .MuiDivider-wrapper {
    font-size: $font14 !important;
    opacity: 0.6;
  }
}

.payments-listing-id-clipboard {
  .MuiInputBase-root {
    width: 170px;
  }
}

.instant-send-id-clipboard {
  .MuiInputBase-root {
    width: 180px;

    input {
      color: $greyText !important;
    }
  }
}

.instant-send-to-clipboard {
  .MuiInputBase-root {
    width: 287px;

    input {
      color: $greyText !important;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &.detail {
    .MuiInputBase-root {
      width: 225px;
    }
  }
}

.instant-send-detail-id {
  .MuiInputBase-root {
    width: 175px;
  }
}
