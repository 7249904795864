@import '@speed/common/src/assets/styles/common.scss';

.mask-input-wrapper {
  .mask-type-box,
  .mask-input {
    width: 100%;
    height: 40px;
    padding: 10px 12px;
    border-radius: 4px;
    @include boxShadow();
    border: solid 1px var(--clear-blue);
    background-color: $white;
    font-family: $fontMedium;
    font-size: $font16;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $darkIndigo;

    &::placeholder {
      opacity: 0.5;
      color: $greyText;
    }

    &.mask-input-error {
      @include applyBorder($paleRed);
      border-radius: 4px;
    }

    &:focus-visible {
      @include applyFocusBorder();
      box-shadow: none;
    }

    &.mask-input-disabled {
      @include disableMaskInput();
    }
  }

  &.type-box-available {
    display: flex;

    .mask-type-box {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      text-align: center;
    }

    .mask-input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}