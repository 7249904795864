@import '../../assets/styles/default.scss';

.accordion-wrapper {
    &.MuiAccordion-root {
        box-shadow: none;
        &:before {
            background-color: transparent;
        }
        .MuiAccordionSummary-root {
            padding: 0;    
            &.Mui-expanded {
                min-height: 35px;            
            }    
            .MuiAccordionSummary-content {
                margin: 0;
                .MuiTypography-root {
                    .MuiSvgIcon-root {
                        margin-right: 10px;
                        font-size: $font24;
                        top: 7px;
                        color: $greyText;
                    }                
                }
            }
    
            .MuiAccordionSummary-expandIconWrapper {
                .MuiSvgIcon-root {
                    color: $darkIndigo;
                }
            }
        }
    
        .MuiAccordionDetails-root {
            .MuiList-root {
                padding-bottom: 0;
            }
            .MuiListItem-root  {
                padding: 2px 0;
                .MuiListItemButton-root {
                    padding-left: 35px;
                    border-radius: 4px;
                    
                    &:hover {
                        background-color: #fef9cc;
                    }
                
                    &.Mui-selected {
                        background-color: $sunFlowerYellow;
                    }
                
                    .MuiListItemText-root  {
                        margin: 0;
                        .MuiTypography-root {
                            font-size: $font14;
                            font-family: $fontMedium;
                            line-height: 1.14;
                            color: $darkIndigo;
                            opacity: 0.9;
                        }
                    }
                }
            }
        }   
    
        &.Mui-expanded {
            margin: 0;
        }
    }
}


.MuiCollapse-wrapper {
    .MuiAccordionDetails-root {
        padding: 0;
    }
}