@import "@speed/common/src/components/core.scss";

.webhook-events-accordion {
  border: solid 1px $lightCyanBlue;
  border-radius: 5px;
  & .MuiAccordionSummary-root {
    padding: 16px 20px !important;
    min-height: auto;
    color: $darkIndigo;
    & svg {
      font-size: $font20;
      color: $greyText !important;
    }
  }
}
.webhook-id-copy {
  .MuiInputBase-root {
    cursor: pointer;
    width: auto;
  }
}
.events-attempts-table {
  background-color: $white;
  height: 100% !important;
  & table {
    & .MuiTableCell-root {
      white-space: nowrap;
    }
  }
}
.search-webhook-events-input {
  border-radius: 4px;
  background-color: $white;
  font-family: $fontMedium;
  height: 42px;
  padding: 10px 12px;
  color: $darkIndigo;
  @include boxShadow();
  border: 1px solid transparent;
  &.Mui-focused {
    @include applyFocusBorder();
    box-shadow: none;
  }
}

.webhook-events-tag {
  gap: 16px;
  padding: 14px !important;
  border-radius: 16px !important;
  & .MuiChip-label {
    font-size: $font14 !important;
  }
  & .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
    margin: 0;
  }
}

.webhook-endpoint-attempts-tab {
  min-height: unset;
  flex: 1 1 auto;
  overflow: hidden;
  & .MuiTabPanel-root {
    min-height: unset;
    height: calc(100% - 41px);
  }
  & .MuiTabs-flexContainer {
    padding-left: 30px !important;
  }
}
