@import '../core.scss';

.label {
    &.MuiInputLabel-root	 {
        margin-bottom: 14px;
        font-family: $fontMedium;
        font-size: $font14;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: $greyText;
        overflow: visible;
    }

    &.action-label {
        cursor: pointer;
        &.MuiInputLabel-root { 
            color: $primaryBlue;  
            &.instant-payout , &.instant-send-send-max {
                font-family: $fontSemiBold;            
            }   
        }
        &:focus-visible {
            @include applyFocusBorder()
        }
    }
}

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .label {
        &.MuiInputLabel-root {
            font-size: $font12;
        }
    }
}