$whiteSmoke: #f5f5f5;
$greyText: #848b9e;
$primaryBlue: #2a67ff;
$primaryBlueHover: #2758d4;
$lightCyanBlue: #e4e9ee;
$topicalBlue: #cbdafa;
$paleRed: #e74c3c;
$brinkPink: #f3a69e;
$lightRed: #ffe8e6;
$paleGray: #f7fafc;
$darkIndigo: #0a193e;
$white: #fff;
$lightGrey: #d8dce1;
$sunFlowerYellow: #f9df00;
$lightSunFlowerYellow: #fef9cc;
$lightYellow: #f5f3e3;
$goldenRod: #f1c40f;
$greenishTeal: #2ecc71;
$backgroundHover: #f1f4f6;
$lightBlue: #1976d214;
$aliceBlue: #f0f7ff;
$lightAliceBlue: #f2f5f7;
$black: #000000;
$darkBlue: #041f61;
$outlineBlue: #bfd1ff;
$orangeYellow: #fffae8;
$lightGrayishRed: #f6f3f3;
$lightGrayishOrange: #f7e7d4;
$lightGrayishBlue: #e2f0fd;
$veryDarkGrey: #0a0a0a;
$lavenderGrey: #b5b9c5;
$lightAliceBlue: #f4f6f7;
$lavenderGrey: #b5b9c5;
$error: #fd371e;
$lightOrange: #fef6eb;
$darkOrange: #f7931a;
$veryDarkBlue: #0003;
$veryLightGrey: #eaeef12b;
$lightShadeBlueGrey: #f5f7ff;
$warmShadePeach: #fde9ce;
$grayishBlue: #a4a9b7;
$lightPink: #fff6f5;
$mistyRose: #ffe4e1;
$greenyBlue: #53ae94;
$aquaSpring: #f1f8f6;
$paleBlueLily: #d5ebe5;
$gainsboro: #d9dcdd;
$grayblue: #f4f5fa;
$font8: 8px;
$font9: 9px;
$font10: 10px;
$font11: 11px;
$font12: 12px;
$font13: 13px;
$font14: 14px;
$font15: 15px;
$font16: 16px;
$font18: 18px;
$font20: 20px;
$font22: 22px;
$font24: 24px;
$font28: 28px;
$font30: 30px;
$font36: 36px;
$font42: 42px;
$font56: 56px;
$font45: 45px;
$font44: 44px;
$font48: 48px;
$font58: 58px;
$fontRegular: Inter-Regular;
$fontBold: Inter-Bold;
$fontSemiBold: Inter-SemiBold;
$fontMedium: Inter-Medium;
$fontLight: Inter-Light;

@font-face {
  font-family: Satoshi-Symbol;
  src: url(../fonts/Satoshi-Symbol.woff);
  font-display: swap;
}

@font-face {
  font-family: Inter-Black;
  src: url(../fonts/Inter-Black.woff);
  font-display: swap;
}

@font-face {
  font-family: Inter-Bold;
  src: url(../fonts/Inter-Bold.woff);
  font-display: swap;
}

@font-face {
  font-family: Inter-ExtraBold;
  src: url(../fonts/Inter-ExtraBold.woff);
  font-display: swap;
}

@font-face {
  font-family: Inter-ExtraLight;
  src: url(../fonts/Inter-ExtraLight.woff);
  font-display: swap;
}

@font-face {
  font-family: Inter-Light;
  src: url(../fonts/Inter-Light.woff);
  font-display: swap;
}

@font-face {
  font-family: Inter-Medium;
  src: url(../fonts/Inter-Medium.woff);
  font-display: swap;
}

@font-face {
  font-family: Inter-Regular;
  src: url(../fonts/Inter-Regular.woff);
  font-display: swap;
}

@font-face {
  font-family: Inter-SemiBold;
  src: url(../fonts/Inter-SemiBold.woff);
  font-display: swap;
}

/* Google fonts */

@font-face {
  font-family: Be Vietnam Pro;
  src: url(https://speed-production.s3.amazonaws.com/fonts/be-vietnam-pro-v8-latin-regular.woff);
  font-display: swap;
}

@font-face {
  font-family: Bitter;
  src: url(https://speed-production.s3.amazonaws.com/fonts/bitter-v25-latin-regular.woff);
  font-display: swap;
}

@font-face {
  font-family: Chakra Petch;
  src: url(https://speed-production.s3.amazonaws.com/fonts/chakra-petch-v8-latin-regular.woff);
  font-display: swap;
}

@font-face {
  font-family: Hahmlet;
  src: url(https://speed-production.s3.amazonaws.com/fonts/hahmlet-v7-latin-regular.woff);
  font-display: swap;
}

@font-face {
  font-family: Inconsolata;
  src: url(https://speed-production.s3.amazonaws.com/fonts/inconsolata-v21-latin-regular.woff);
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: url(https://speed-production.s3.amazonaws.com/fonts/inter-v8-latin-regular.woff);
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url(https://speed-production.s3.amazonaws.com/fonts/lato-v22-latin-regular.woff);
  font-display: swap;
}

@font-face {
  font-family: Lora;
  src: url(https://speed-production.s3.amazonaws.com/fonts/lora-v23-latin-regular.woff);
  font-display: swap;
}

@font-face {
  font-family: MPLUS1Code;
  src: url(https://speed-production.s3.amazonaws.com/fonts/m-plus-1-code-v5-latin-regular.woff);
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  src: url(https://speed-production.s3.amazonaws.com/fonts/montserrat-v23-latin-regular.woff);
  font-display: swap;
}

@font-face {
  font-family: Noto Sans;
  src: url(https://speed-production.s3.amazonaws.com/fonts/noto-sans-v26-latin-regular.woff);
  font-display: swap;
}

@font-face {
  font-family: Noto Serif;
  src: url(https://speed-production.s3.amazonaws.com/fonts/noto-serif-v20-latin-regular.woff);
  font-display: swap;
}

@font-face {
  font-family: Nunito;
  src: url(https://speed-production.s3.amazonaws.com/fonts/nunito-v23-latin-regular.woff);
  font-display: swap;
}

@font-face {
  font-family: Open Sans;
  src: url(https://speed-production.s3.amazonaws.com/fonts/open-sans-v28-latin-regular.woff);
  font-display: swap;
}

@font-face {
  font-family: Pridi;
  src: url(https://speed-production.s3.amazonaws.com/fonts/pridi-v9-latin-regular.woff);
  font-display: swap;
}

@font-face {
  font-family: PT Sans;
  src: url(https://speed-production.s3.amazonaws.com/fonts/pt-sans-v16-latin-regular.woff);
  font-display: swap;
}

@font-face {
  font-family: PT Serif;
  src: url(https://speed-production.s3.amazonaws.com/fonts/pt-serif-v16-latin-regular.woff);
  font-display: swap;
}

@font-face {
  font-family: Raleway;
  src: url(https://speed-production.s3.amazonaws.com/fonts/raleway-v26-latin-regular.woff);
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: url(https://speed-production.s3.amazonaws.com/fonts/roboto-v29-latin-regular.woff);
  font-display: swap;
}

@font-face {
  font-family: Roboto Slab;
  src: url(https://speed-production.s3.amazonaws.com/fonts/roboto-slab-v22-latin-regular.woff);
  font-display: swap;
}

@font-face {
  font-family: Source Sans Pro;
  src: url(https://speed-production.s3.amazonaws.com/fonts/source-sans-pro-v19-latin-regular.woff);
  font-display: swap;
}

@font-face {
  font-family: Titillium Web;
  src: url(https://speed-production.s3.amazonaws.com/fonts/titillium-web-v14-latin-regular.woff);
  font-display: swap;
}

@font-face {
  font-family: Ubuntu Mono;
  src: url(https://speed-production.s3.amazonaws.com/fonts/ubuntu-mono-v14-latin-regular.woff);
  font-display: swap;
}

@font-face {
  font-family: Zen Maru Gothic;
  src: url(https://speed-production.s3.amazonaws.com/fonts/zen-maru-gothic-v7-latin-regular.woff);
  font-display: swap;
}
