@import "../core.scss";

.custom-popper {
    z-index: 2;
    .popper-arrow {
        font-size: $font8;
        width: 3em;
        height: 3em;
        &::before {
            content: "";
            margin: auto;
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
        }
    }

    &[data-popper-placement*="bottom"] {
        margin-top: 3px !important;
        .popper-arrow {
            top: 0px;
            left: 0px;
            margin-top: -0.9em;
            width: 3em;
            height: 1em;
            &::before {
                border-width: 0px 1em 1em 1em;
                border-color: transparent transparent rgb(255, 255, 255) transparent;
            }
        }
    }

    &[data-popper-placement*="top"] {
        margin-bottom: 3px !important;
        .popper-arrow {
            bottom: 0px;
            left: 0px;
            margin-bottom: -0.9em;
            width: 3em;
            height: 1em;

            &::before {
                border-width: 1em 1em 0 1em;
                border-color: rgb(255, 255, 255) transparent transparent transparent;
            }
        }
    }

    &[data-popper-placement*="right"] {
        margin-left: 3px !important;
        .popper-arrow {
            left: 0px;
            margin-left: -0.9em;
            height: 3em;
            width: 1em;
            &::before {
                border-width: 1em 1em 1em 0px;
                border-color: transparent rgb(255, 255, 255) transparent transparent;
            }
        }
    }

    &[data-popper-placement*="left"] {
        margin-right: 3px !important;
        .popper-arrow {
            right: 0px;
            margin-right: -0.9em;
            height: 3em;
            width: 1em;
            &::before {
                border-width: 1em 0px 1em 1em;
                border-color: transparent transparent transparent rgb(255, 255, 255);
            }
        }
    }

    .MuiBox-root {
        color: $greyText;
        font-size: $font16;
        word-break: break-word;
        padding: 10px;
        max-width: 400px;
        min-width: 140px;
        border-radius: 4px;
        background-color: $white;
        box-shadow: 0 0 7px 3px rgba(11, 37, 75, 0.12), 0 0 0 1px rgba(11, 37, 75, 0.08);
    }
}