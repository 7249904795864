@import '../core.scss';

.otp-input {
    width: 42px !important;
    height: 42px;
    border-radius: 4px;
    @include boxShadow();
    background-color: $white;
    border: none;
    margin-right: 15px;
    font-size: $font16;
    color: $darkIndigo;
    font-family: $fontRegular;

    &[aria-label='Digit 4'] {
        margin-left: 15px;
    }

    &[aria-label='Digit 6'] {
        margin-right: 0px;
    }

    &:focus {
        outline: none;
        border: 1px solid $primaryBlue;

        &::placeholder {
            color: transparent;
        }
    }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .otp-input {
        width: 32px !important;
        height: 32px;
        margin-right: 12px;
        font-size: $font14;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {

    .otp-input {
        font-size: $font14;
    }
}