@import "../core.scss";

.breadcrumb-wrapper {
    .breadcrumb-link-txt {
        color: $primaryBlue;
        font-size: $font14;
        font-weight: 500;
        text-decoration: none;

        &:focus-visible {
            @include applyFocusBorder();
        }

        &:hover {
            color: $primaryBlueHover;
        }
    }
}
