@import "../core.scss";

@mixin applySize {
    &.small {
        width: 30px;
        height: 30px;
    }

    &.large {
        width: 120px;
        height: 120px;
    }

    box-shadow: 0 0 0 1px $lightCyanBlue;
}
.MuiAvatar-root {
    @include applySize();
}

.MuiSkeleton-root {
    @include applySize();
}
