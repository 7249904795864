@import "@speed/common/src/components/core.scss";

// payment link main page
.payment-links-wrapper {
  .custom-popper {
    z-index: 3;

    .MuiList-root {
      padding: 0;

      .MuiListItemButton-root {
        padding: 2px 35px 2px 16px;
      }

      .MuiListItem-root {
        &:focus,
        &:hover {
          background-color: $backgroundHover;
          border-radius: 4px;
        }
      }

      .MuiListItemText-root {
        .MuiTypography-root {
          font-family: $fontMedium;
          font-size: $font14;
        }

        &.text-danger {
          color: $paleRed;
        }

        &.text-blue {
          color: $primaryBlue;
        }
      }
    }
  }

  .MuiTabPanel-root {
    padding: 0px;
  }

  .link-table .MuiTable-root {
    background-color: $white;
  }

  .text-capitalize {
    text-transform: capitalize;
  }

  .pass-lock-icon {
    font-size: $font16;
    margin-left: 7px;
    color: $greyText;
  }
}

// create payment link
.fullscreen-modal {
  .fullscreen-modal-box {
    flex: 1 1 100%;
    overflow: hidden;
    @include twoSectionPreview();
  }

  .modal-app-bar {
    background-color: $white;
    border-bottom: 1px solid $lightCyanBlue;
    box-shadow: none;

    &.MuiPaper-root {
      background-color: $white;
      border-bottom: 1px solid $lightCyanBlue;
      box-shadow: none;
    }

    .MuiToolbar-root {
      height: 70px;
    }

    .MuiIconButton-root {
      color: $greyText;
      margin-right: 11px;
    }

    .divider {
      border-left: 1px solid $lightCyanBlue;
      height: 30px;
      padding-left: 19px;
    }

    .payment-link-btn {
      margin-right: 10px;
    }
  }

  .payment-link-label {
    margin-bottom: 0 !important;
  }

  .error-input {
    .MuiInputBase-root {
      border: 1px solid $paleRed;
      border-radius: 4px;
    }
  }

  .error-msg-box {
    border-radius: 4px;
    background-color: $lightRed;
    border: 1px solid $lightRed;
    margin-top: 15px;
    width: 100%;

    .MuiAlert-root {
      padding: 0px 12px;
    }
  }

  .confirmation-box {
    padding: 17px 30px 23px 20px;
    cursor: pointer;
    border-radius: 5px;
    border: 3px solid transparent;
    @include boxShadow();

    &:focus,
    &.active {
      @include applyFocusBorder();
    }

    .radio-wrapper {
      .MuiTypography-root {
        font-family: $fontSemiBold;
        font-size: $font16;
      }

      .MuiRadio-root {
        padding: 0;
        margin: 0px 11px 0px 9px;

        &:focus {
          @include applyFocusBorder();
          border-radius: 20px;
        }
      }
    }

    .checkbox-wrapper {
      .MuiTypography-root {
        font-family: $fontRegular;
        font-size: $font16;
        color: $greyText;
      }

      &.checkbox-label-default {
        .MuiTypography-root {
          color: $darkIndigo !important;
          font-family: $fontSemiBold;
        }
      }
    }

    .input-element {
      .MuiInputBase-root {
        &.Mui-focused {
          border: 1px solid $topicalBlue;

          &.Mui-error {
            border: 1px solid $paleRed;
          }
        }
      }
    }

    .text-area {
      &:focus {
        border: 1px solid $topicalBlue;

        &.text-area-error {
          border: 1px solid $paleRed;
        }
      }
    }
  }

  .auto-generate-pass {
    position: absolute;
    right: 0px;
    top: -5px;
    color: $primaryBlue;
    cursor: pointer;
  }

  .password-protected-checkbox {
    .MuiFormControlLabel-label {
      color: $darkIndigo;
      font-weight: 600;
    }
  }

  .remove-icon-pl {
    display: none;
    position: absolute;
    width: 24px;
    height: 24px;
    padding: 2px;
    background: $white;
    border-radius: 15px;
    cursor: pointer;
    bottom: 137px;
    left: 132px;
    @include applyBorder($lightCyanBlue);

    &:focus-visible {
      @include applyFocusBorder();
      border-radius: 25px;
    }

    svg {
      color: $greyText;
      font-size: $font18;
    }
  }

  .pl-image-upload-box {
    margin-top: 30px;
    margin-bottom: 30px;

    .input-element {
      display: none;
    }

    .custom-popper {
      .MuiBox-root {
        width: 212px;
        margin-left: 55px;
      }
    }

    .pl-preview-image-box {
      width: 148px;

      &:hover {
        position: relative;

        .remove-icon-pl {
          display: block;
        }
      }

      .pl-preview-image {
        width: 140px;
      }
    }
  }

  .options {
    &.MuiFormControlLabel-root {
      .MuiCheckbox-root {
        padding-left: 0;
      }
    }
  }
  .preset-type-box {
    margin-top: 25px;
    margin-left: 35px;

    .preset-amount-label {
      display: flex;
      align-items: flex-start;
      margin-top: 13px;

      .label {
        margin-bottom: 0;
      }
    }
    .MuiFormLabel-root {
      text-wrap: wrap;
    }
  }
}

//payment link: detail page
.payment-link-detail-wrapper {
  @include detailWrapper();
  .header-content {
    margin-top: 20px;

    .header-price-content {
      display: flex;
      margin-top: 5px;

      .fiat-price {
        .MuiTypography-root {
          color: $primaryBlue;
          margin-right: 13px;
        }
      }

      .crypto-converted-price {
        margin-right: 20px;
        align-self: center;
      }

      .price-disclaimer {
        align-self: center;
        display: flex;

        .asterisk-symbol {
          width: 10px;
          height: 14px;
          margin: 7px 7px 0px 2px;
          font-family: $fontMedium;
          font-size: $font18;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.78;
          letter-spacing: normal;
          color: $paleRed;
        }
      }
    }

    .input-selection-box {
      &.branding-detail {
        .MuiInputBase-input {
          cursor: pointer;
        }
      }

      .disabled {
        .MuiInputBase-input {
          cursor: default;
        }
      }
    }
  }

  .details-content {
    margin-top: 65px;
    @include addCustomScrollBar();
  }

  .product-content {
    margin-top: 44px;
  }

  .border-none {
    border: none !important;
  }

  .text-capitalize {
    text-transform: capitalize;
  }

  .password-protected {
    .MuiInputBase-input {
      height: 9px;
      box-shadow: none;
      width: 115px;
      @include applyBorder(#d7e1eb);
    }
  }

  .password-copy {
    .file-copy-icon {
      margin-left: 10px;
    }
  }

  .clipboard-text {
    background-color: #f7fafc;
    padding: 4px 14px;
    border-radius: 3px;
    color: #848b9e !important;
  }
}

.currency-divider {
  &.MuiDivider-root {
    padding: 0 15px;
  }

  .MuiDivider-wrapper {
    font-size: $font8 !important;
    opacity: 0.4;
  }
}

.payment-link-header-wrapper {
  padding: 20px 35px;
}

.latest-payment-content {
  .horizontal-table-wrapper {
    .MuiTableContainer-root {
      .MuiTableBody-root {
        border-bottom: none;
      }
    }
  }
}

.checkbox-label {
  margin-right: unset !important;
}

.dropdown-options-box {
  border-radius: 4px;
  @include applyBorder($lightCyanBlue);
  margin-top: 16px;
  padding: 10px 14px;
}
