:root {
    --ff-primary-color: #4c48ef !important;
    --ff-secondary-color: #616a7d !important;
    --ff-text-color: #090b2b !important;
    --ff-dialog-border-radius: 4px !important;
    --ff-border-radius: 5px !important;
    --ff-bg-fade: rgb(176 179 182 / 40%) !important;
}

/* Begin style overrides for when Flatfile is displayed as a modal */

/* This class gets appended to the flatfile_iframe-wrapper div */

.flatfile_iframe-wrapper {
    top: 0px;
    left: 0px;
}

.flatfile_displayAsModal {
    padding: 70px !important;
    width: 100% !important;
    height: 100% !important;
}

.flatfile_iframe-wrapper.flatfile_displayAsModal {
    background: var(--ff-bg-fade) !important;
}

/* The close button in top right to close modal */
.flatfile_displayAsModal .flatfile-close-button {
    top: 40px !important;
    right: 40px !important;
    display: block !important;
    margin: 20px !important;
    background: #ff0000 !important;
}

.flatfile_iFrameContainer {
    height: auto !important;
    border-radius: 20px;
    padding: 16px;
}

/* The icon for the close button in top right to close modal */
.flatfile_displayAsModal .flatfile-close-button svg {
    fill: var(--ff-secondary-color) !important;
}

/* The actual iframe that contains Flatfile */
.flatfile_displayAsModal #flatfile_iframe {
    border-radius: var(--ff-border-radius);
}

/* Begin style overrides for when you cancel out of the Flatfile modal */

/* The outer container of the modal that opens when you cancel out of Flatfile */
.flatfile_outer-shell {
    background-color: var(--ff-bg-fade) !important;
    border-radius: var(--ff-border-radius) !important;
}

/* The white box inside the modal that opens when you cancel out of Flatfile */
.flatfile_modal {
    border-radius: var(--ff-dialog-border-radius) !important;
}

/* The "yes, cancel" button you see in the close modal */
.flatfile_primary {
    border: 1px solid var(--ff-primary-color) !important;
    background-color: var(--ff-primary-color) !important;
    color: #fff;
}

/* The "no, stay" button you see in the close modal */
.flatfile_secondary {
    color: var(--ff-secondary-color) !important;
}

/* The heading text you see in the close modal */
.flatfile_modal-heading {
    color: var(--ff-text-color) !important;
}

/* The description text you see in the close modal */
.flatfile_modal-text {
    color: var(--ff-secondary-color) !important;
}

/* End style overrides for when you cancel out of the Flatfile modal */

/* End style overrides for when Flatfile is displayed as a modal */