@import "@speed/common/src/components/core.scss";

.manage-apps-wrapper {
    display: flex;
    flex-wrap: wrap;
    min-height: unset;
    .manage-app-main-card {
        min-width: 350px;
        height: auto;
        margin-right: 25px;
        border-radius: 5px;
        box-shadow: 0 4px 4px -2px rgba(11, 37, 75, 0.12),
            0 0 0 1px rgba(11, 37, 75, 0.08);
        background-color: $white;
    }

    .card-body {
        padding-bottom: 20px;
    }

    .card-icon {
        width: 20px;
        height: 20px;
        object-fit: contain;
    }

    .card-text {
        color: $darkIndigo;
        margin: 12px 0 12px 6px;
    }
    .app-name {
        .text-wrapper {
            font-size: $font22 !important;
            font-weight: bold !important;
        }

    }
    .contact-support {
        display: flex;
        align-items: center;
    }
    .manage-app-content {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 28px;
    }
}

.fullscreen-modal-box {
    .grid-box {
        padding: unset !important;
    }

    .inner-grid-width {
        max-width: 50%;
        overflow: auto;
        background-color: $paleGray
    }
    .grid-box-step {
        background-color: $paleGray;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        text-align: center;
        margin: 0 20px 0 40px;
    }
    @include addCustomScrollBar();

    .video-preview {
        width: 766px;
    }
    .custom-input-copy-text {
        div {
            width: 600px;
            height: 42px;
            box-shadow: 0 4px 4px -2px rgba(11, 37, 75, 0.12),
                    0 0 0 1px rgba(11, 37, 75, 0.08);
            border-radius: 4px;
            input {
                background-color: $lightAliceBlue !important;
                font-size: $font16 !important;
                font-family: $fontMedium;
            }
        }
    }
}

.shopify-disabled-input {
    input {
        -webkit-text-fill-color: unset !important;
    }
}

.manage-card-test-connection {
    width: 750px;
    height: 110px;
    border-radius: 10px;
    box-shadow: 0 4px 4px -2px rgba(11, 37, 75, 0.12),
        0 0 0 1px rgba(11, 37, 75, 0.08);
    background-color: $white;
    margin-bottom: 32px;

    & .css-46bh2p-MuiCardContent-root {
        padding: unset;
    }
}

.success-border {
    border: solid 1px $greenishTeal;
}

.error-border {
    border: solid 1px $paleRed;
}

.button-tooltip {
    background-color: $lightGrey !important;
    div {
        pointer-events: auto;
        color: white;
    }
}

.oauth-allow-app-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .text-wrapper {
        &.text-bold {
            font-family: $fontBold !important;
        }
    }
    .border-accordian {
        border-bottom: solid 1px #e4e9ee;
    }
    .rotate-icon {
        .css-yw020d-MuiAccordionSummary-expandIconWrapper{ 
            img {
                transform: rotate(-90deg);
            }
        }
    }
    .app-access-description {
        .support-center-accordian {    
                .text-wrapper {
                    font-size: $font18 !important;
                }
                ul > li {
                    font-size: $font16;
                    color: #848b9e;
                    width: 720px;
                    margin-left: 40px;
                    margin-bottom: 20px;
                }
        }
        .MuiBox-root:last-child {
            margin-bottom: 40px;
        }
    }
}
