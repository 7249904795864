@import "@speed/common/src/assets/styles/common.scss";
@mixin slickListMixin($width) {
  .speed-carousel {
    .slick-list {
      width: $width !important;
    }
  }
}
@mixin amountPreviewMixin($width, $height, $fontSize) {
  .amount-options-preview {
    &.MuiOutlinedInput-root {
      width: $width;
      height: $height;

      .MuiSelect-outlined {
        .MuiTypography-root {
          font-size: $fontSize;
        }
      }
    }
  }
}

@mixin presetLabelMixin($labelInputWidth, $labelLeft) {
  .label-type {
    width: $labelInputWidth !important;
    height: 44px;
  }

  .label-type::after {
    top: 21px;
    left: $labelLeft !important;
    font-size: $font16;
  }

  .preset-amount-preview {
    @include contentCenter();
    justify-content: unset !important;

    .mask-input-wrapper {
      .mask-input {
        &.input-adornment {
          width: $labelInputWidth !important;
          height: 44px;
          font-size: $font24;
          padding-right: 85px;
        }
      }
    }

    .MuiSvgIcon-root {
      margin-left: 8px;
    }
  }
}

.latest-web-design-wrapper {
  width: 100%;
  height: 100%;
  display: flex;

  .left-side-section,
  .right-side-section {
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  .left-side-section {
    .left-container {
      margin-top: 15px;
      @include invoiceInfoOnPaymentPageSectionCommonCss();

      .view-more-detail {
        margin: 30px 0px;
        padding: 30px 0px;
        border-top: 1px solid rgba(255, 255, 255, 0.15);
      }
    }
  }

  .right-container-box {
    margin-top: 15px;
  }

  .latest-header-section-wrapper {
    margin-top: 44px;
  }

  @include contactInfoWrapper();
  @include addCustomScrollBar();
}

@mixin assetTabCss() {
  .asset-tab-section-preview {
    height: 44px;
    border-color: $lightGrey !important;
    background-color: $white;
    // width: 49%;
    display: flex;
    align-items: center;
    justify-content: left;
    border-radius: 4px;
    border: 1px solid;
    padding: 0 10px;
    flex-grow: 1;
    min-width: calc(100% / 5);

    &.selected {
      border-color: $primaryBlue !important;
      background-color: $paleGray;
    }

    img {
      margin-right: 8px;
    }
  }
}

.account-asset-wrapper {
  @include assetTabCss();
}

.asset-payment-methods-wrapper {
  margin: 18px 0;
  @include assetTabCss();
}

.branding-header-section {
  @include presetLabelMixin(270px, 206px);
  @include amountPreviewMixin(315px, 50px, 28px);

  .pl-preview-image {
    min-width: 140px;
    max-width: 350px;
  }
}

.new-mobile-header-section {
  .new-page-tid-preview {
    @include contentCenter();
    flex-direction: column;

    .page-title {
      width: unset;
      text-align: center;
      margin-top: 30px;
    }

    .page-description-preview-box {
      .MuiTypography-root {
        text-align: center;
        width: unset;
      }
    }

    .checkout-acc-currency,
    .checkout-acc-amount {
      text-align: center;
    }

    .pl-preview-image,
    .image-upload-box {
      width: 80px;
      height: 80px;
      margin-top: 40px;
    }

    .pl-preview-image {
      width: auto;
      min-width: 80px;
      max-width: 330px;
    }
  }

  @include amountPreviewMixin(270px, 44px, 24px);
  @include presetLabelMixin(270px, 206px);

  .amount-currency-wrapper {
    @include contentCenter();
    flex-direction: column;
  }

  .checkout-acc-currency,
  .checkout-acc-amount {
    text-align: center;
  }

  @include addCustomScrollBar();
}

.new-mobile-qr-section {
  padding: 14px 13px;
  padding-bottom: 0;

  .payment-page-qr-section {
    .payment-method-text {
      margin-top: 0;
    }

    .qr-preview-section {
      margin-top: 20px;
    }

    .qr-footer-box {
      // width: 41.5% !important;
    }
  }

  @include contactInfoWrapper();

  .circle-icon {
    margin-top: 16px;
  }
}

.header-mobile-skeleton {
  background: $white;
  box-shadow: 10px 0px 15px 0px rgba(0, 0, 0, 0.08);
  padding: 24px 13px;
}

.scrollable-header {
  &.fixed {
    height: 35px;
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 14px;
  }

  &.slide-in {
    animation: slideIn 0.2s ease-in;
  }
}

.slide-out {
  animation: slideIn 0.5s ease-out;
}

.edit-icon-contact {
  height: 18px;
  width: 18px;
  color: $primaryBlue;
}

.border-error {
  border: 1px solid $paleRed !important;
}

.border-error-unset {
  border-top: unset !important;
}

.border-left-unset {
  border-left: unset !important;
}

.border-right-unset {
  border-right: unset !important;
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@media only screen and (width: 1024px) {
  .latest-web-design-wrapper {
    .left-container,
    .right-container-box,
    .bottom-footer,
    .tab-qr-wrapper {
      width: 412px !important;
    }

    @include amountPreviewMixin(315px, 50px, 28px);
    @include presetLabelMixin(270px, 206px);
  }
}

@media only screen and (max-width: 331px) {
  .new-mobile-header-section {
    @include presetLabelMixin(210px, 145px);
    @include amountPreviewMixin(240px, 44px, 24px);

    .new-page-tid-preview {
      .pl-preview-image {
        max-width: 240px !important;
      }
    }
  }

  .new-mobile-qr-section {
    .payment-page-qr-section {
      .qr-preview-section {
        padding: 22px 4px !important;
      }

      .qr-footer-box {
        padding: 9px 4px !important;
        // width: 45% !important;

        .MuiTypography-root {
          text-wrap: wrap;
          font-size: $font12 !important;

          .MuiSvgIcon-root {
            width: 16px !important;
            height: 16px !important;
          }
        }
      }
    }
  }
}
