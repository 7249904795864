@import "@speed/common/src/components/core.scss";

.payment-link-detail-wrapper {
  &.merchant-detail-wrapper {
    .section-wrapper {
      .action-btn-wrapper {
        top: 23px !important;
        right: 2px !important;
      }
    }
  }
}

.affiliate-partner-invitation-link-copy {
  width: 100%;

  & .MuiInputBase-readOnly {
    background-color: $paleGray !important;
    width: 100% !important;
    border-radius: 21.0084px !important;

    & input {
      box-shadow: 0px 0px 0px 1px rgba(11, 37, 75, 0.08);
      border-radius: 21.0084px !important;
      border: 0 !important;
      color: $greyText !important;
      padding: 9px 9px 11px 19px !important;
    }
  }
}