@import "../core.scss";

.radio-wrapper {
    .MuiRadio-root {
        padding: 0px 11px 0px 9px;
        color: $greyText;
        &:hover {
            background: none;
        }

        &.Mui-disabled {
            color: $greyText;
            opacity: 0.3;
        }

        &.Mui-checked {
            color: $primaryBlue;
        }
    }

    .MuiTypography-root {
        color: $darkIndigo;
        line-height: 1.63;
        letter-spacing: normal;
    }
}
