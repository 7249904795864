@import '@speed/common/src/components/core.scss';

.customer-wrapper {
    .custom-popper {
        .MuiList-root {
            padding: 0;

            .MuiListItemButton-root {
                padding: 2px 35px 2px 16px;
            }

            .MuiListItemText-root {
                &.text-danger {
                    color: $paleRed;
                }
            }
        }
    }
}

.customer-detail-wrapper {
    @include detailWrapper();

    .section-wrapper {
        position: relative;

        .action-btn-wrapper {
            top: 23px !important;
            right: 2px !important;
            display: flex;
            align-items: center;
        }

        .custom-popper {
            .MuiList-root {
                padding: 0;

                .MuiListItem-root {
                    .MuiButtonBase-root {
                        padding: 3px 10px;
                        border-radius: 4px;

                        .MuiListItemText-root {
                            .MuiTypography-root {
                                font-size: $font14;
                            }

                            &.text-danger {
                                color: $paleRed;
                            }
                        }
                    }
                }
            }
        }

        .header-content {
            margin-top: 20px;

            .header-price-content {
                display: flex;
                margin-top: 10px;
                justify-content: space-between;

                >.MuiSkeleton-root {
                    &:nth-child(1) {
                        width: 325px !important;
                        height: 25px !important;
                    }
                }

                >div {
                    .MuiSkeleton-root {
                        &:nth-child(1) {
                            position: relative;
                            bottom: 15px;
                        }
                    }
                }
            }

            .customer-name {
                font-size: $font24;
                font-family: $fontSemiBold;
                color: $darkIndigo;
            }

            .customer-description {
                margin-top: 10px;
                max-width: 750px;
                word-break: break-word;
            }

            .clipboard-element {
                margin-top: 35px;

                .clipboard-text {
                    background-color: $paleGray;
                    border-radius: 3px;
                    color: $greyText !important;
                    padding: 4px 14px;
                }
            }
        }

        .customer-detail-content {
            .MuiDivider-root {
                margin: 12px 0px;
            }

            .customer-details-box>.MuiGrid-root {
                flex-basis: 48%;
                max-width: 48%;

                .customer-info-text {
                    word-break: break-word;
                }
            }
        }
    }
}

.create-customer-modal {
    height: 100%;
    overflow-y: auto;
    display: flex;
    justify-content: center;

    .customer-form-wrapper {
        width: 600px;
        display: inline-table;
        padding-bottom: 40px;

        .customer-form-box {
            margin-top: 40px;

            .customer-form-info {
                padding-left: 20px;

                .label-with-icon {
                    height: 18px;
                    width: 18px;
                    position: relative;
                    bottom: 1px;
                    margin-left: 5px;
                }

                .MuiAutocomplete-root {
                    .MuiFormControl-root {
                        height: 40px;

                        .MuiInputBase-root {
                            padding-left: 5px;
                        }
                    }
                }

                .customer-billing-email-section {
                    .MuiTypography-root {
                        font-family: $fontMedium;
                    }

                    .checkbox-wrapper {
                        margin-top: 10px;
                    }

                    .add-more-recipients {
                        margin-top: 20px;

                        .add-btn {
                            align-items: center;
                            padding: 10px 10px 5px 0px;
                            display: inline-flex;
                            color: $primaryBlue;

                            &:hover {
                                color: $primaryBlueHover;
                            }

                            svg {
                                width: 20px;
                                height: 20px;
                            }

                            .MuiTypography-root {
                                color: inherit;
                            }
                        }
                    }
                }
            }

            .checkbox-wrapper {
                margin: 30px 0px 0px 0px;
            }
        }
    }
}

.select-popover {
    .create-customer-modal {
        overflow-y: hidden;
    }
}

.delete-customer-modal {
    .MuiDialog-container {
        .MuiPaper-root {
            max-width: 442px;
        }
    }
}

.error-warning-modal {
    .MuiDialog-container {
        .MuiPaper-root {
            max-width: 461px;

            .MuiDialogContent-root {
                padding: 47px 20px;
            }
        }
    }
}

#cusomer-select-calling-code {
    .MuiPaper-root {
        width: 580px;
        position: sticky;
        left: calc(50% + 10px) !important;
        transform: translate(-50%, 0%) !important;
    }
}