@import '../core.scss';

.auto-complete {
    .MuiAutocomplete-root {
        &.MuiAutocomplete-hasClearIcon {
            .MuiOutlinedInput-input {
                &.MuiInputBase-input {
                    padding-right: 45px;
                }
            }
        }

        .MuiFormControl-root {
            .MuiOutlinedInput-root {
                border: 3px solid transparent;
                border-radius: 4px;
                position: relative;
                background-color: $white;
                font-size: $font16;
                font-family: $fontMedium;
                width: unset;
                height: 41px;
                padding: 0px 12px;
                box-sizing: initial;
                color: $darkIndigo;
                @include boxShadow();

                &.Mui-focused {
                    &.Mui-error {
                        @include applyBorder($paleRed);
                        box-shadow: none;
                    }

                    border-radius: 4px;
                    border: 3px solid $outlineBlue;
                    box-shadow: none;
                }

                &.Mui-error {
                    box-shadow: none;
                }
            }

            .MuiOutlinedInput-notchedOutline {
                border: none;
            }
        }

        .MuiAutocomplete-hasPopupIcon {
            &.MuiAutocomplete-hasClearIcon {
                .MuiOutlinedInput-root {
                    padding-right: 65px;
                }
            }
        }
    }
}


.MuiAutocomplete-popper {
    padding: 0 1px 1px;
    border-radius: 4px;
    box-shadow: 0 4px 7px 3px rgba(11, 37, 75, 0.12);
    border: solid 1px $lightCyanBlue;
    background-color: $white;

    .MuiPaper-root {
        box-shadow: none;

        .MuiAutocomplete-listbox {
            padding: 10px;

            .MuiAutocomplete-option {
                margin: 3px 0;
                padding: 8px 15px;
                border-radius: 5px;
                font-size: $font16;
                color: $darkIndigo;

                &.MuiAutocomplete-option[aria-selected="true"] {
                    background-color: $lightBlue;

                    &.Mui-focused {
                        background-color: $backgroundHover;
                    }
                }

                &.Mui-focused {
                    background-color: $backgroundHover;
                }
            }
        }

        .MuiAutocomplete-noOptions {
            font-size: $font16;
            color: $darkIndigo;
        }

        @include scrollbar()
    }
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .MuiAutocomplete-root {
        .MuiFormControl-root {
            .MuiOutlinedInput-root {
                font-size: $font14;
            }
        }
    }

    .MuiAutocomplete-popper {
        .MuiPaper-root {
            .MuiAutocomplete-listbox {
                .MuiAutocomplete-option {
                    font-size: $font14;
                }
            }

            .MuiAutocomplete-noOptions {
                font-size: $font14;
            }
        }
    }
}