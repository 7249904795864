@import "../core.scss";

.color-picker-wrapper {
    width: 120px;
    height: 30px;
    display: flex;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
    background-color: $paleGray;
    @include boxShadow();

    &.focused {
        box-shadow: 0 4px 4px -2px rgba(42, 103, 255, 0.5), 0 0 0 1px rgba(42, 103, 255, 0.5);
    }

    &.input-error {
        box-shadow: 0 4px 4px -2px rgba(223, 27, 65, 0.5), 0 0 0 1px rgba(223, 27, 65, 0.5);
    }

    .color-selector {
        .rectangle-color-preview {
            display: inline-block;
            width: 30px;
            height: 30px;
            margin-right: 12px;
            background: $darkIndigo;
            @include boxShadow();
        }

        .hidden {
            position: absolute;
            left: 0;
            opacity: 0;
            width: 30px;
            height: 30px;
            cursor: pointer;
        }
    }

    .hash-element {
        display: inline-flex;
        align-items: center;
        font-size: $font14;
    }

    .MuiInputBase-root {
        .MuiInputBase-input {
            padding: 5px 5px 5px 0px;
        }
    }
}