@import "@speed/common/src/components/core.scss";

.payments-wrapper {
  .custom-popper {
    z-index: 3;

    .MuiList-root {
      padding: 0;

      .MuiListItemButton-root {
        padding: 2px 35px 2px 16px;
        border-radius: 4px;
        &:focus,
        &:hover {
          background-color: $backgroundHover;
          border-radius: 4px;
        }
      }

      .MuiListItemText-root {
        .MuiTypography-root {
          font-family: $fontMedium;
          font-size: $font14;
        }
      }
    }
  }

  .MuiTabPanel-root {
    padding: 0px;
  }

  .payment-table .MuiTable-root {
    background-color: $white;
  }
}

//payments detail wrapper
.payments-detail-wrapper {
  @include detailWrapper();

  .header-content {
    margin-top: 20px;

    .header-price-content {
      display: flex;
      margin-top: 9px;
      justify-content: space-between;

      .crypto-converted-price {
        margin-right: 20px;
        align-self: center;
      }

      .price-disclaimer {
        align-self: center;
        display: flex;

        .asterisk-symbol {
          width: 10px;
          height: 14px;
          margin: 7px 7px 0px 2px;
          font-family: $fontMedium;
          font-size: $font18;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.78;
          letter-spacing: normal;
          color: $paleRed;
        }
      }
    }

    .input-selection-box {
      &.branding-detail {
        .MuiInputBase-input {
          cursor: pointer;
        }
      }

      .disabled {
        .MuiInputBase-input {
          cursor: default;
        }
      }
    }
  }

  .details-content {
    margin-top: 43px;
  }

  .checkout-summary {
    .vertical-table-wrapper {
      .MuiTableCell-root {
        vertical-align: initial !important;
      }
    }
  }

  .product-content {
    margin-top: 44px;
    &.meta-data-content {
      & .align-left-right-content {
        min-height: 36px;
      }
    }
  }

  .border-none {
    border: none !important;
  }

  .text-capitalize {
    text-transform: capitalize;
  }

  .payments-detail {
    background-color: $paleGray;
    padding: 4px 14px;
    border-radius: 3px;
    color: $greyText !important;
  }
}

.add-metadata-container {
  padding-left: 15px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  color: $primaryBlue;
  &:hover {
    color: $primaryBlueHover !important;
  }
}
