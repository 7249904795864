@import "../../assets/styles/default.scss";
@import "../core.scss";

@mixin loadingButton {
    &.MuiLoadingButton-loading {
        color: transparent;
        background-color: $lightGrey !important;
    }  
}

@mixin disableButton {
  &:disabled {
    background-color: $lightGrey;
    color: $white;
  }
}

.button-wrapper {
  &.MuiButton-root {
    font-size: $font16;
    font-family: $fontSemiBold;
    text-transform: none;
    padding: 12px 22px;
    line-height: 1;
    border: 1px solid transparent;
    @include boxShadow();

    &.button-with-icon {
      padding: 8px 10px;

      .MuiButton-startIcon {
        margin-left: -2px;
        margin-right: 4px;

        .MuiSvgIcon-root {
          font-size: $font18;
        }
      }

      &.add-icon {
        .MuiSvgIcon-root {
          width: 18px;
          height: 18px;
        }
      }
    }

    &.MuiButton-contained {
      &.MuiButton-containedPrimary {
        background-color: $primaryBlue;
        &:hover {
          background-color: $primaryBlueHover;
        }
      }
      &.MuiButton-containedError {
        background-color: $paleRed;
        &:hover {
          background-color: #ce3b2c;
        }
      }
      @include disableButton();
      @include loadingButton();
    }

    &.MuiButton-outlined {
      &.MuiButton-outlinedPrimary {
        @include applyBorder($primaryBlue);
        color: $primaryBlue;
        &:hover {
          background-color: $white;
          @include applyBorder($primaryBlueHover);
          color: $primaryBlueHover;
        }
        &:disabled {
          @include applyBorder($lightCyanBlue);
          color: $lightCyanBlue;
        }
        &.MuiLoadingButton-loading {
          color: transparent;
        }
      }
      &.MuiButton-outlinedError {
        @include applyBorder($paleRed);
        &:disabled {
          @include applyBorder($lightCyanBlue);
          color: $lightCyanBlue;
        }
      }
    }

    &.MuiButton-text {
      color: $darkIndigo;
      &:hover {
        background-color: #f1f1f1;
      }
      @include disableButton();
      @include loadingButton();
    }
  }
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .button-wrapper {
    &.MuiButton-root {
      font-size: $font16;
    }
  }
}
