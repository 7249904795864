@import '@speed/common/src/components/core.scss';

// Connect Accounts
.connect-account-create-modal {
  .static-text-box {
    height: 41px;
    display: flex;
    border: 1px solid $lightCyanBlue;
    background: $paleGray;
    border-radius: 4px;
  }

  .connect-link-box-wrapper {
    display: flex;
    align-items: center;

    .connect-link-box {
      width: 95%;
      border: 1px solid $lightCyanBlue;
      background: $paleGray;
      padding: 10px 14px;
      border-radius: 4px;

      .connect-link {
        max-width: 470px;
        word-wrap: break-word;
        text-decoration: underline;
        color: $darkIndigo;
      }
    }

    .MuiSvgIcon-root {
      margin-left: 10px;
    }
  }
}

.connect-detail-wrapper {
  @include detailWrapper();

  .section-wrapper {
    position: relative;

    .action-btn-wrapper {
      top: 0 !important;
      right: 0 !important;
      display: flex;
      align-items: center;
    }

    .header-content {
      margin-top: 26px;
    }

    .details-content {
      margin-top: 25px;
    }

    .product-content {
      margin-top: 44px;
    }

    .border-none {
      border: none !important;
    }
  }
}