@import "../../assets/styles/default.scss";

@mixin commonRootText {
  height: 16px;
  font-weight: 500;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
}

@mixin commonInfoText {
  height: 16px;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
}

.timeline-wrapper {
  .timeline-root-text-right {
    @include commonRootText();
    margin: 12px 0 9px 15px;
  }

  .timeline-root-text-left {
    @include commonRootText();
    margin: 12px 15px 9px 0;
  }

  .timeline-text-info-right {
    @include commonInfoText();
    margin: 5px 42px 0 15px;
  }

  .timeline-text-info-left {
    @include commonInfoText();
    margin: 5px 15px 0 42px;
  }

  &.MuiTimelineSeparator-root {
    svg {
      width: 24px;
      height: 24px;
      margin: 0 15px 5px 0;
      object-fit: contain;
    }
  }

  .MuiTimelineConnector-root {
    width: 1px;
    height: 49px;
    border: solid 1px $lightCyanBlue;
  }

  .MuiTimelineDot-root {
    border: none;
    margin: 3px 0px;
  }

  .MuiSvgIcon-root {
    color: $greyText;
  }
}
