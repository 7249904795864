@import '../../assets/styles/default.scss';

.get-started-section {
    display: flex;
    justify-content: center;
    padding-top: 46px;
    background: $paleGray;
    width: 100%;
    
    .get-started-header {
        max-width: 1200px;
        background: $white;
        box-shadow: 0px 4px 8px rgba(11, 37, 75, 0.06);
        border-radius: 5px;
        
        .get-started-header-text {
            margin-top: 50px;
            font-weight: 600;
            font-size: 36px !important;
            line-height: 140%;
        }
    }

    .get-started-use-cases {
        background: $darkBlue;
        margin: 40px 50px 50px 50px;
        padding-left: 65px;
        border-radius: 10px;

        .use-case-text {
            color: $sunFlowerYellow;
            padding-top: 50px;
            padding-bottom: 40px;
        }

        .use-case-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            max-width: 1100px;
        }

        .use-case-list-heading {
            display: flex;
            color: $white;
            align-items: flex-start;
            font-size: $font22;
        }

        .use-case-list-desc {
            color: $white;
            padding-top: 10px;
            padding-left: 38px;
            line-height: 140%,
        }
    }
}

@media only screen and (max-device-width: 1440px) {
    .main-content-initial-setup {
        .get-started-header {
            width: 1000px;
        }
    }
}
