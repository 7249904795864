@import "../core.scss";

.global-search-wrapper {
    position: relative;
    width: 560px;
    height: 30px;
    border-radius: 17px;
    @include applyBorder($lightCyanBlue);

    .global-search-input {
        width: 100%;
        font-size: $font12;

        .global-search-icon {
            top: 3px;
            color: $greyText;
            padding: 0px 12px;
            height: 100%;
            position: absolute;
            pointer-events: none;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                font-size: 15px;
            }

            .icon-spin {
                width: 12px !important;
                height: 12px !important;
                color: $greyText;
            }
        }

        .clear-search-icon {
            position: absolute;
            right: 10px;
            font-size: $font14;
            color: $greyText;
            cursor: pointer;
        }

        .MuiInputBase-input {
            padding: 6px 30px 8px 0px;
            padding-left: calc(1em + 23px);
            width: 100%;
        }
    }
}

.search-result-wrapper {
    &.MuiPaper-root {
        z-index: 1300;
        position: absolute;
        min-width: 560px;
        width: auto;
        max-width: 800px;
        top: 27px;
        left: 50%;
        transform: translate(-50%, 0%);
        box-sizing: border-box;
        box-shadow: 0 4px 7px 3px rgba(11, 37, 75, 0.12);
        @include applyBorder($lightCyanBlue);

        .MuiCardHeader-root {
            padding: 10px 10px 0px;

            &.active {
                .MuiCardHeader-content {
                    .MuiTypography-root.text-wrapper {
                        color: $white;
                    }

                    background-color: $primaryBlue;
                }
            }

            .MuiCardHeader-content {
                border-radius: 5px;
                padding: 9px 10px;
                height: 32px;
                box-sizing: border-box;

                .MuiTypography-root.text-wrapper {
                    display: flex;
                    line-height: 1.2;

                    &.input-string {
                        max-width: 250px;
                        display: inline;
                    }
                }
            }
        }

        .show-all-results {
            padding: 10px 10px 0px 10px;

            .MuiListItemButton-root {
                padding: 9px 10px;
                height: 32px;
                border-radius: 4px;

                .MuiTypography-root.text-wrapper {
                    display: flex;
                    line-height: 1.2;

                    &.input-string {
                        max-width: 380px;
                        display: inline;
                    }
                }

                &.active,
                &:hover {
                    .MuiTypography-root {
                        color: $white;
                    }

                    background-color: $primaryBlue;
                }
            }
        }

        .result-item {
            padding: 0px 10px 10px;

            .MuiList-root {
                padding: 0;

                .MuiListItem-root {
                    padding: 5px 0px;

                    .MuiListItemButton-root {
                        padding: 9px 10px;
                        height: 32px;
                        border-radius: 4px;

                        &.active {
                            background-color: $primaryBlue;

                            .MuiListItemIcon-root,
                            .MuiTypography-root {
                                color: $white;
                            }

                            .MuiListItemIcon-root {
                                img {
                                    filter: invert(100%) !important;
                                }
                            }
                        }

                        .MuiListItemIcon-root {
                            min-width: 30px;

                            svg {
                                font-size: $font20;
                            }
                        }

                        .MuiListItemText-root {
                            margin: 0;

                            .MuiTypography-root {
                                font-size: $font14;
                                line-height: 1.4;
                            }
                        }
                    }
                }
            }
        }

        &.no-result {
            height: 282px;

            .result-item {
                line-height: 1;
                padding: 49px 10px 73px 10px;
                text-align: center;

                .no-result-search-icon {
                    font-size: 60px;
                    color: $lightCyanBlue;
                }

                .MuiTypography-root.text-wrapper.font-regular {
                    margin-top: 11px;
                }
            }
        }
    }
}

.suggested-filters-wrapper {
    border: 1px solid $lightCyanBlue;
    box-shadow: 0 4px 7px 3px rgba(11, 37, 75, 0.12);
    position: absolute;
    top: 27px;
    min-width: 560px;
    width: auto;
    max-width: 800px;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 1300;

    .MuiCardHeader-root {
        background-color: $paleGray;
        padding: 14px 20px 15px 19px;

        .MuiCardHeader-content {
            display: flex;
            justify-content: space-between;
            padding: 0;
            align-items: center;

            .search-tips-link {
                color: $greyText;
            }
        }
    }

    .MuiCardContent-root {
        padding: 10px 0px !important;

        .MuiList-root {
            padding: 0px 10px;

            .MuiListItem-root {
                padding: 4px 0px;

                .MuiListItemButton-root {
                    padding: 9px 6px;
                    height: 32px;
                    border-radius: 4px;

                    .MuiListItemIcon-root {
                        min-width: 30px;

                        svg {
                            font-size: $font20;
                        }
                    }

                    .search-suggested-filter {
                        &.MuiChip-root {
                            border-radius: 5px;
                            cursor: pointer;

                            .MuiChip-label {
                                color: $greyText;
                                font-family: $fontMedium;
                                line-height: 1.5;
                            }
                        }
                    }


                    &.active,
                    &:hover {
                        background-color: $primaryBlue;

                        .MuiListItemIcon-root,
                        .MuiTypography-root {
                            color: $white;
                        }

                        .MuiListItemIcon-root {
                            img {
                                filter: invert(100%) !important;
                            }
                        }
                    }
                }

                .category-description {
                    margin: 0px 0px 0px 10px;
                }
            }
        }

        .result-header {
            height: 49px;
            background-color: $paleGray;
            padding: 14px 20px 15px 19px;
            margin: 10px 0px;
            box-sizing: border-box;
        }
    }
}