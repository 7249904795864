@import '../../assets/styles/default.scss';

.MuiSnackbar-root {
    .MuiAlert-root {
        width: 400px;
    }
}
.alert-wrapper {
    &.MuiAlert-root {
        align-items: center;
        font-family: $fontMedium;
        padding: 2px 12px;
        &.MuiAlert-standardSuccess {
            background-color: #e2f3e9;
        }
    
        &.MuiAlert-standardError {
            background-color: $lightRed;
        }
    
        &.MuiAlert-standardWarning {
            background-color: #f8f3df;
        }
    
        .MuiAlert-message {
            color: $darkIndigo;
            opacity: 0.8;
            font-size: $font12;
            letter-spacing: normal;
        }
    
        .MuiAlert-action {
            padding-top: 0;
            .MuiSvgIcon-root {
                color: $greyText;
            }
        }
    
        .error-icon {
            color: $paleRed;
        }
    
        .success-icon {
            color: $greenishTeal;
        }
    
        .warning-icon {
            color: $goldenRod;
        }
    
        .MuiSvgIcon-root {
            font-size: $font18;
        }
    }
}
